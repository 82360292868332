import React, { FC } from 'react';
import { PersonFill } from 'react-bootstrap-icons';

import { Box } from '@resiliantinc/design-system';

import { UserStatusSidebarStyles } from 'components/molecules/UserStatusSidebar/UserStatusSidebar.styles';

import { IUserStatusSidebar } from './UserStatusSidebar.interface';

const UserStatusSidebar: FC<IUserStatusSidebar> = () => {
  return (
    <UserStatusSidebarStyles.Wrapper>
      <UserStatusSidebarStyles.Card active>
        <Box>
          <PersonFill size="2.4rem" />
        </Box>
        <UserStatusSidebarStyles.CardBody>
          <UserStatusSidebarStyles.CardTitle>
            User status
          </UserStatusSidebarStyles.CardTitle>
          <UserStatusSidebarStyles.CardText>
            See the users that have been invited to join your organization.
          </UserStatusSidebarStyles.CardText>
        </UserStatusSidebarStyles.CardBody>
      </UserStatusSidebarStyles.Card>
    </UserStatusSidebarStyles.Wrapper>
  );
};

export { UserStatusSidebar };
