import { styled } from '@resiliantinc/design-system';

export const CreateGroupFormsStyles = {
  SectionHalfWidth: styled('div', {
    maxWidth: '60rem',
  }),
  SectionFullWidth: styled('div', {
    maxWidth: '100%',
    borderTop: '1px solid $border',
    mt: '$4',
    pt: '$8',
  }),
  SectionConfirmation: styled('div', {
    borderTop: '1px solid $border',
    borderBottom: '1px solid $border',
    py: '4.8rem',
    mt: '4.8rem',
  }),
  SectionTitle: styled('h4', {
    fontSize: '$5',
    mb: '$6',
  }),
  SwitchesSectionWrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '$8 $4',
  }),
  SwitchesSummary: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$4',
  }),
  NextButton: styled('div', {
    mt: '$8',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '$4',
  }),
  GridWrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: '$3 $8',
  }),
};
