import { useTranslation } from 'react-i18next';

import { Box, Title } from '@resiliantinc/design-system';

import { UserAvatarStyles } from 'components/organisms/UserAvatar/UserAvatar.styles';

import { firstLetterOf } from 'lib/util';

import { useUser } from 'providers/UserProvider';

const UserAvatar = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  return (
    <UserAvatarStyles.Container>
      <UserAvatarStyles.Image>
        {firstLetterOf(user?.name.split(' ')[0] || ' ')}
        {firstLetterOf(user?.name.split(' ')[1] || ' ')}
      </UserAvatarStyles.Image>

      <Box>
        <Title>{user?.name}</Title>
        <UserAvatarStyles.SlateText>
          {t('Account:Administrator')}
        </UserAvatarStyles.SlateText>

        <UserAvatarStyles.SlateText>
          {t('Account:User ID:')} {user?.id}
        </UserAvatarStyles.SlateText>
      </Box>
    </UserAvatarStyles.Container>
  );
};

export { UserAvatar };
