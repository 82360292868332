import { Button, styled } from '@resiliantinc/design-system';

import { MENUS_COLLAPSED_SIZE } from '../MainMenu/Nav.styled';

export const TopMenuWrapper = styled('div', {
  height: MENUS_COLLAPSED_SIZE,
  bc: '$slate900',
});

export const TopMenuLeft = styled('div', {
  height: '100%',
  display: 'flex',
  ai: 'center',
  pl: '3rem',
});

export const TopMenuRight = styled('div', {
  display: 'flex',
  ai: 'center',
  px: '2rem',
  gap: '0.8rem',
});

export const TopNavButton = styled(Button, {
  bc: 'transparent !important',
  color: '$white !important',
  transition: 'all 0.3s ease-in-out',
  [`&:hover`]: {
    color: '$primary !important',
  },
});
