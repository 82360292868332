import { styled } from '@resiliantinc/design-system';

export const Menu = styled('ul', {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  '@bp2': {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > li:last-child': {
      paddingBottom: '10rem',
    },
  },
});
