import { motion } from 'framer-motion';

import { styled } from '@resiliantinc/design-system';

export const ChipWrapper = styled('div', {
  all: 'unset',
  bc: '$sky200',
  color: '$sky700',
  p: '$1 $4',
  br: '$xl',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: 'fit-content',
  position: 'relative',
});

export const DeleteIcon = styled('div', {
  display: 'flex',
  ml: '$2',
  mr: '-$2',
  cursor: 'pointer',
  br: '50%',
  transition: 'background-color 150ms ease-in-out',
  '&:hover': {
    bc: '$sky300',
  },
});

export const Tooltip = styled(motion.div, {
  position: 'absolute',
  top: '110%',
  left: '50%',
  transform: 'translateX(-50%)',
  bc: '$white',
  br: '$sm',
  boxShadow: '$md',
  zIndex: 100,
  minWidth: '25rem',
  maxWidth: '25rem',
  p: '$3 $4',
});
