import { AnimatePresence } from 'framer-motion';
import React, {
  cloneElement,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { XLg } from 'react-bootstrap-icons';

import { Button } from '@resiliantinc/design-system';

import { IScrollStickyHeader } from './ScrollStickyHeader.interface';
import {
  CloseWrapper,
  ExtraContentWrapper,
  Header,
  HeaderLiteral,
  stickyHeaderAnimationProps,
} from './ScrollStickyHeader.styles';
import { shouldHeaderStick } from './ScrollStickyHeader.utils';

export const ScrollStickyHeader = ({
  onClose,
  title,
  headerExtraContent,
  css,
  cssCloseWrapper,
  cssStickyHeader,
  closeButton,
  closeButtonProps,
  allowClose = true,
}: IScrollStickyHeader) => {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const ButtonClose = closeButton || (
    <Button
      onClick={onClose}
      variant="nude"
      rounded
      leftIcon={<XLg />}
      type="button"
      {...(closeButtonProps || {})}
    />
  );

  const DefaultHeader =
    !!title || allowClose || !!headerExtraContent ? (
      <Header ref={headerRef} css={css}>
        {!!headerExtraContent && (
          <ExtraContentWrapper>{headerExtraContent}</ExtraContentWrapper>
        )}
        {!!title && <HeaderLiteral>{title}</HeaderLiteral>}
        {allowClose && (
          <CloseWrapper css={cssCloseWrapper}>{ButtonClose}</CloseWrapper>
        )}
      </Header>
    ) : (
      <></>
    );

  const StickyHeader = cloneElement(DefaultHeader, {
    ...DefaultHeader.props,
    sticky: true,
    ref: null,
    css: cssStickyHeader,
    ...stickyHeaderAnimationProps,
  });

  const handleScroll = useCallback(() => {
    const headerElement = headerRef?.current;
    const scrollElement = headerElement?.parentElement;

    const shouldBeSticky = shouldHeaderStick(scrollElement, headerElement);

    setIsSticky(shouldBeSticky);
  }, []);

  useLayoutEffect(() => {
    const parent = headerRef?.current?.parentElement;

    if (!parent) return;

    parent.addEventListener('scroll', handleScroll);

    return () => {
      parent.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <AnimatePresence>{isSticky && StickyHeader}</AnimatePresence>
      {DefaultHeader}
    </>
  );
};
