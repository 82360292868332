import { styled } from '@resiliantinc/design-system';

export const AddGroupsModalStyles = {
  Wrapper: styled('div', {
    p: '$4',
    display: 'flex',
    flexDirection: 'column',
  }),
  AvailableRole: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '$4',
    '&:not(:last-child)': {
      borderBottom: '1px solid $border',
    },
  }),
  ChipsWithAddButton: styled('div', {
    display: 'flex',
    alignItems: 'center',
    mb: '$6',
    mt: '$2',
    gap: '$2',
  }),
  AddButton: styled('div', {
    all: 'unset',
    bc: '$general400',
    color: '$white',
    p: '$1 $4',
    br: '$xl',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'fit-content',
    whiteSpace: 'nowrap',
  }),
};
