import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routePaths } from 'router/routePaths';

export const useAuth = () => {
  const navigate = useNavigate();

  const getAccessToken = useCallback(async () => {
    return Cookies.get('access-token');
  }, []);

  const logout = useCallback(async () => {
    Cookies.remove('access-token');
    navigate(routePaths.login);
  }, [navigate]);

  const isAuthenticated = !!Cookies.get('access-token');

  return {
    isAuthenticated,
    getAccessToken,
    logout,
  };
};
