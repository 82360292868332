import { Text, styled } from '@resiliantinc/design-system';

export const AdministratorDetailsStyles = {
  TopLineWrapper: styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '$3',
    bc: '$sky50',
    borderRadius: '$md',
    p: '$6',
  }),
  TopLineItem: styled('div'),
  TopLineItemLabel: styled(Text, {
    fontSize: '$2',
    color: '$general500',
    display: 'flex',
    gap: '$2',
    variants: {
      withTopMargin: {
        true: {
          mt: '$2',
        },
      },
    },
  }),
};
