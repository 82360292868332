import { styled } from '@resiliantinc/design-system';

export const MenuItem = styled('li', {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  flex: 1,
  textAlign: 'center',
  '@bp2': {
    flex: 0,
    width: '100%',
  },
});
