import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { CreateAdministratorForm } from 'components/organisms/CreateAdministratorForm';

export const CreateAdministrator = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper
      title={t('Administrators:Create Administrator')}
      includeBreadcrumb
    >
      <CreateAdministratorForm />
    </ScreenWrapper>
  );
};
