import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '@resiliantinc/design-system';

import { BlueCard, BlueText, SmallCardText } from 'components/atoms/BlueCard';

import { IPlanCard } from './PlanCard.interface';

const PlanCard: FC<IPlanCard> = ({
  plan_name,
  plan_price_per_user,
  plan_max_users,
}) => {
  const { t } = useTranslation();
  return (
    <BlueCard>
      <BlueText>{plan_name}</BlueText>
      <Title>
        {plan_price_per_user}/{t('Account:per user')}
      </Title>
      <SmallCardText>
        {t('Account:Includes up to')} {plan_max_users} {t('Account:users')}
      </SmallCardText>
    </BlueCard>
  );
};

export { PlanCard };
