import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { fromNow } from 'lib/dates';
import { IAdministratorParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IAdministratorParsed>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('lastSeen', {
    header: 'Last Seen',
    cell: (value) => {
      const lastSeen = value.getValue();
      return fromNow(lastSeen) || 'Never';
    },
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.administratorDetails, {
          id: value.getValue(),
        })}
      >
        View
      </Button>
    ),
  }),
];
