import { PublicLayout } from 'components/Layout';
import { SignupForm } from 'components/organisms/SignupForm';

export const Signup = () => {
  return (
    <PublicLayout>
      <SignupForm />
    </PublicLayout>
  );
};
