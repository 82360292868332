import { atom } from 'jotai';

import { ICreateGroupForm } from 'lib/types';

export const defaultFormState = {
  name: '',
  description: '',
  properties: {},
  users: [],
  administrators: [],
};
export const createGroupFormsState = atom<ICreateGroupForm>(defaultFormState);
