import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { SubAdminsTable } from 'components/organisms/SubAdminsTable';

export const SubAdmins = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper
      title={t('Administrators:Sub-Administrators')}
      includeBreadcrumb
    >
      <SubAdminsTable />
    </ScreenWrapper>
  );
};
