import dayjs, { ManipulateType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(calendar);

type ExtendedDate = Date | string;

export function getPreviousMonth() {
  const date = dayjs().subtract(1, 'month');

  return date.format('YYYY/MM');
}

export const formatDate = (
  date: ExtendedDate,
  format: string = 'MM-DD-YYYY'
): string => {
  return dayjs(date).format(format);
};

export const mathWithDate = (
  date: ExtendedDate,
  operator: 'add' | 'subtract',
  amount: number,
  unit: ManipulateType,
  format: string
) => {
  return dayjs(date)[operator](amount, unit).format(format);
};

export const differenceBetweenDates = (
  date1: ExtendedDate,
  unit: ManipulateType,
  date2?: ExtendedDate
) => {
  return dayjs(date1).diff(date2 || dayjs(), unit);
};

export const getDaysInGivenMonth = (date: ExtendedDate) => {
  return dayjs(date).daysInMonth();
};

export const getMonth = (date: ExtendedDate) => {
  return dayjs(date).month();
};
export const getYear = (date: ExtendedDate) => {
  return dayjs(date).year();
};

export const formatDateByCalendar = (date: ExtendedDate, formats: object) => {
  return dayjs(date).calendar(null, formats);
};

export const fromNow = (date: ExtendedDate) => {
  return dayjs(date).fromNow();
};

export const formatDateWithTodayAndYesterday = (
  date: ExtendedDate,
  defaultFormat = 'Do MMM'
) => {
  return formatDateByCalendar(date, {
    sameDay: '[Today]',
    nextDay: defaultFormat,
    nextWeek: defaultFormat,
    lastDay: '[Yesterday]',
    lastWeek: defaultFormat,
    sameElse: defaultFormat,
  });
};
