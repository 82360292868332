import { styled } from '@resiliantinc/design-system';

export const ActivityDetailsStyles = {
  Table: styled('div', {
    border: '1px solid $border',
    borderRadius: '$sm',
  }),
  TableRow: styled('div', {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    '&:not(:last-child)': {
      borderBottom: '1px solid $border',
    },
  }),
  TableCell: styled('div', {
    p: '$4',
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderRight: '1px solid $border',
    },
  }),
  Ul: styled('ul', {
    ml: '$5',
  }),
  Li: styled('li', {}),
};
