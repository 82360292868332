import React, { FC, useCallback, useMemo, useState } from 'react';
import { Dash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { Button, Text } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { Modal } from 'components/atoms/Modal';

import Api from 'lib/api';
import { IGroupShort, IGroupsResponse } from 'lib/types';

import { IAddGroupsModal } from './AddGroupsModal.interface';
import './AddGroupsModal.styles';
import { AddGroupsModalStyles } from './AddGroupsModal.styles';

const AddGroupsModal: FC<IAddGroupsModal> = ({ setGroups, groups }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: groupsData } = useSwr<IGroupsResponse>(Api.getGroups);
  const { t } = useTranslation();

  const addGroup = useCallback(
    (group: IGroupShort) => () => {
      setGroups((past) => [...past, group]);
    },
    [setGroups]
  );

  //useCallback
  const removeGroup = useCallback(
    (group: IGroupShort) => () => {
      setGroups((past) => past.filter((g) => g.id !== group.id));
    },
    [setGroups]
  );

  const renderSelectedGroups = useMemo(
    () =>
      groups?.map((group) => (
        <Chip title={group.name} onDelete={removeGroup(group)} />
      )),
    [removeGroup, groups]
  );

  const isGroupSelected = useCallback(
    (group: IGroupShort) => {
      return groups.some((g) => g.id === group.id);
    },
    [groups]
  );

  const renderAvailableGroups = useMemo(
    () =>
      groupsData?.groups.map((group) => (
        <AddGroupsModalStyles.AvailableRole>
          <Text>{group.name}</Text>
          <Button
            onClick={
              !isGroupSelected(group) ? addGroup(group) : removeGroup(group)
            }
            type="button"
            rightIcon={isGroupSelected(group) ? <Dash /> : <Plus />}
            variant={isGroupSelected(group) ? 'secondary' : 'dark'}
          >
            {isGroupSelected(group) ? t('Roles:Remove') : t('Roles:Add')}
          </Button>
        </AddGroupsModalStyles.AvailableRole>
      )),
    [groupsData, isGroupSelected, addGroup, removeGroup, t]
  );
  const openModal = () => setModalOpen(true);

  return (
    <>
      <AddGroupsModalStyles.ChipsWithAddButton>
        {renderSelectedGroups}
        <AddGroupsModalStyles.AddButton onClick={openModal}>
          {t('Groups:Add Groups')}
        </AddGroupsModalStyles.AddButton>
      </AddGroupsModalStyles.ChipsWithAddButton>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        position="bottomSheetCentered"
        maxWidth={555}
        title={t('Groups:Add Groups')}
      >
        <AddGroupsModalStyles.Wrapper>
          {renderAvailableGroups}
        </AddGroupsModalStyles.Wrapper>
      </Modal>
    </>
  );
};

export { AddGroupsModal };
