import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { GroupsTable } from 'components/organisms/GroupsTable';

import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

export const Groups = () => {
  const { t } = useTranslation();
  const { additionalDetails } = useUser();

  const { organization_name } = additionalDetails || {};

  return (
    <ScreenWrapper
      title={t('Groups:Groups')}
      subtitle={t('Groups:Here are all the Groups within {{organization}}', {
        organization: organization_name,
      })}
      action={
        <Button to={routePaths.createGroup} as={Link}>
          {t('Groups:Create Group')}
        </Button>
      }
    >
      <GroupsTable />
    </ScreenWrapper>
  );
};
