import { createColumnHelper } from '@tanstack/react-table';
import { Trash } from 'react-bootstrap-icons';

import { Button } from '@resiliantinc/design-system';

import { IUserShort } from 'lib/types';

const columnHelper = createColumnHelper<IUserShort>();

export const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => {
      // @ts-ignore
      const rowAction = (id: number) => () => value.rowAction(id);
      return (
        <Button
          variant="outlined"
          leftIcon={<Trash size="1.6rem" />}
          onClick={rowAction(value.getValue())}
        >
          Remove
        </Button>
      );
    },
  }),
];
