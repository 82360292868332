import { styled } from '@resiliantinc/design-system';

export const DropzoneStyled = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
  minHeight: '22rem',
  border: '2px dashed $border',
  borderRadius: '.8rem',
  padding: '$9',
  position: 'relative',
  transition: 'all .2s ease-out',

  variants: {
    active: {
      true: {
        border: '2px dashed $sky900',
        backgroundColor: '$slate50',
      },
    },
    activeError: {
      true: {
        border: '2px dashed $slate900',
        backgroundColor: '$white',
      },
    },
    externalError: {
      true: {
        border: '2px dashed $red500',
        backgroundColor: '$red100',
      },
    },
  },
});
