import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { Input } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table, TableLoading } from 'components/organisms/Table';

import Api from 'lib/api';
import { useSearchHelper } from 'lib/hooks';
import { usersTableParser } from 'lib/parsers';
import { IUsersResponse } from 'lib/types';

import { columns, simpleColumns } from './UsersTable.coldef';

const UsersTable = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { searchTerm, handleSearch } = useSearchHelper();

  const { data: users, error } = useSwr<IUsersResponse>(
    searchTerm ? Api.searchUsers(searchTerm) : Api.getUsers(page)
  );

  const getTable = useMemo(() => {
    if (error) return <LoadFailed />;
    if (!users) return <TableLoading />;
    if (!users.users.length) return <TableNoData />;
    return searchTerm ? (
      <Table columnDefinition={simpleColumns} data={users.users} />
    ) : (
      <Table
        columnDefinition={columns}
        data={usersTableParser(users)}
        paginator={{ ...users.paginator, setPage }}
      />
    );
  }, [users, searchTerm, error]);

  return (
    <>
      <Input
        type="search"
        placeholder={t('Users:Search users...')}
        name="user-search"
        onChange={handleSearch}
        wrapperCss={{
          maxWidth: '400px',
        }}
      />
      {getTable}
    </>
  );
};

export { UsersTable };
