import { IGroupShort, IUsersResponse, userStatusEnum } from 'lib/types';

export interface IUserParsed {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  lastSeen: string;
  status: userStatusEnum[];
  groups: IGroupShort[];
  email: string;
}

export const usersTableParser = (data: IUsersResponse): IUserParsed[] => {
  const { users, usersStats } = data;
  return users.map((user) => {
    const { id, firstName, lastName, email } = user;
    const { groups, status } = usersStats[id];
    return {
      id,
      firstName,
      lastName,
      lastSeen: 'N/A',
      groups,
      email,
      status,
      name: `${firstName} ${lastName}`,
    };
  });
};
