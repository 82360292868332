import React, { FC, useMemo } from 'react';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';

import { Button, Text } from '@resiliantinc/design-system';

import { MiniSelect } from 'components/atoms/MiniSelect';

import { ITablePagination } from './TablePagination.interface';
import './TablePagination.styles';
import { Wrapper } from './TablePagination.styles';

const TablePagination: FC<ITablePagination> = ({
  currentPage,
  hasMorePages,
  lastPage,
  setPage,
}) => {
  const getSelectOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= lastPage; i++) {
      options.push(i);
    }
    return options;
  }, [lastPage]);

  const prevPage = () => setPage(currentPage - 1);
  const nextPage = () => setPage(currentPage + 1);

  return (
    <Wrapper>
      <MiniSelect
        options={getSelectOptions}
        defaultOption={currentPage}
        onChange={setPage}
      />
      <Text
        css={{
          mr: '$2',
        }}
      >
        of {lastPage} pages
      </Text>
      <Button
        variant="secondary"
        disabled={currentPage === 1}
        onClick={prevPage}
      >
        <CaretLeftFill />
      </Button>
      <Button variant="secondary" disabled={!hasMorePages} onClick={nextPage}>
        <CaretRightFill />
      </Button>
    </Wrapper>
  );
};

export { TablePagination };
