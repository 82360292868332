import { styled } from '@resiliantinc/design-system';

export const BlueCard = styled('div', {
  p: '4.8rem',
  borderRadius: '$xl',
  bc: '$sky50',
  border: '.2rem solid $general100',
});

export const BlueText = styled('p', {
  color: '$general100',
  fontSize: '$4',
  fontWeight: '$extraBold',
  mb: '$3',
});

export const SmallCardText = styled('p', {
  color: '$general500',
  fontSize: '$3',
  mt: '$6',
});
