import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { createFeedback } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { columns } from 'components/organisms/RolesTable/RolesTable.coldef';
import { Table, TableLoading } from 'components/organisms/Table';

import Api, { processApiError } from 'lib/api';
import { useHasRequiredPermission } from 'lib/hooks/useHasRequiredPermission';
import { rolesTableParser } from 'lib/parsers';
import { IRolesResponse } from 'lib/types';

const RolesTable = () => {
  const [page, setPage] = useState(1);
  const {
    data: roles,
    error,
    mutate,
  } = useSwr<IRolesResponse>(Api.getRoles(page));
  const canDeleteRole = useHasRequiredPermission('deleteRole');
  const { t } = useTranslation();

  const rowAction = useCallback(
    async (roleId: number) => {
      try {
        await Api.deleteRole(+roleId);
        await mutate();
        createFeedback({
          variant: 'success',
          heading: t('Roles:Role deleted successfully'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      }
    },
    [mutate, t]
  );

  if (error) return <LoadFailed />;
  if (!roles) return <TableLoading />;
  if (!roles.roles.length) return <TableNoData />;

  return (
    <Table
      columnDefinition={columns}
      data={rolesTableParser(roles)}
      paginator={{ ...roles.paginator, setPage }}
      rowAction={canDeleteRole ? rowAction : undefined}
    />
  );
};

export { RolesTable };
