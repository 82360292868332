import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, createFeedback } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { BlueCard, BlueText } from 'components/atoms/BlueCard';

import { useUser } from 'providers/UserProvider';

import { ApiKeysStyles } from 'screens/ApiKeys/ApiKeys.styles';

export const ApiKeys = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { api_token } = user || {};

  const onCopyClick = useCallback(async () => {
    await navigator.clipboard.writeText(api_token || '');
    createFeedback({
      variant: 'success',
      heading: t('Developer:Copied to clipboard'),
    });
  }, [api_token, t]);

  return (
    <ScreenWrapper title={t('Developer:API Keys')}>
      <BlueCard>
        <BlueText>{t('Developer:Your Api Key')}</BlueText>
        <ApiKeysStyles.InputWithCopy>
          <Input
            name="api_token"
            value={api_token}
            readOnly={true}
            wrapperCss={{ m: 0, p: 0, width: '100%' }}
          />
          <Button
            onClick={onCopyClick}
            css={{
              whiteSpace: 'nowrap',
            }}
          >
            {t('Developer:Copy to Clipboard')}
          </Button>
        </ApiKeysStyles.InputWithCopy>
      </BlueCard>
    </ScreenWrapper>
  );
};
