import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { Stepper } from 'components/molecules/Stepper';

import { routePaths } from 'router/routePaths';

export const CreateGroup = () => {
  const { t } = useTranslation();

  const stepperSteps = [
    {
      label: t('Groups:General Properties'),
      path: routePaths.createGroupGeneral,
    },
    {
      label: t('Groups:Add Users'),
      path: routePaths.createGroupUsers,
    },
    {
      label: t('Groups:Add Administrators'),
      path: routePaths.createGroupAdmins,
    },
    {
      label: t('Groups:Confirmation'),
      path: routePaths.createGroupConfirmation,
    },
  ];

  return (
    <ScreenWrapper
      title={t('Groups:Create Group')}
      includeBreadcrumb
      action={<Stepper steps={stepperSteps} />}
    >
      <Outlet />
    </ScreenWrapper>
  );
};
