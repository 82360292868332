import React, { FC, ReactElement, useEffect, useRef } from 'react';

interface IClickAwayListener {
  onClickAway: () => void;
  children: ReactElement;
  eventType?: string;
}

const getOwnerDocument = (node: Node | null | undefined): Document => {
  return (node && node.ownerDocument) || document;
};

export const ClickAwayListener: FC<IClickAwayListener> = ({
  onClickAway,
  children,
  eventType = 'click',
}) => {
  const childrenRef = useRef<any>(null);

  useEffect(() => {
    const ignoreElement = childrenRef.current;

    const ownerDocument = getOwnerDocument(ignoreElement);

    ownerDocument.addEventListener(eventType, (e) => {
      const clickOutside = !ignoreElement?.contains(e.target);

      if (clickOutside) {
        onClickAway();
      }
    });
  }, [eventType, onClickAway]);

  return (
    <React.Fragment>
      {React.cloneElement(children, { ref: childrenRef })}
    </React.Fragment>
  );
};
