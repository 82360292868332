import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { Button, InlineFeedback } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { AdministratorsTable } from 'components/organisms/AdministratorsTable';
import { createAdminSuccessAtom } from 'components/organisms/CreateAdministratorForm/CreateAdministratorForm.atoms';

import { useHasRequiredPermission } from 'lib/hooks/useHasRequiredPermission';

import { routePaths } from 'router/routePaths';

export const Administrators = () => {
  const { t } = useTranslation();
  const canCreateAdmins = useHasRequiredPermission('createAdmin');
  const [globalSuccess, setGlobalSuccess] = useAtom(createAdminSuccessAtom);

  useEffectOnce(() => {
    setTimeout(() => setGlobalSuccess(false), 15_000);
  });

  return (
    <ScreenWrapper
      title={t('Administrators:Administrators')}
      action={
        canCreateAdmins ? (
          <Button as={Link} to={routePaths.createAdministrator}>
            {t('Administrators:Create Administrator')}
          </Button>
        ) : undefined
      }
    >
      {globalSuccess && (
        <InlineFeedback
          css={{ mb: '$4' }}
          heading={t(
            'Administrators:Administrator account created but will not be usable until the invited administrator confirms their account by clicking the link sent to the email address of their IdNFT user account'
          )}
          variant="success"
        />
      )}
      <AdministratorsTable />
    </ScreenWrapper>
  );
};
