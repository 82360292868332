import { styled } from '@resiliantinc/design-system';

export const WithConfirmationStyles = {
  Content: styled('div', {
    px: '$5',
    pt: '$2',
    pb: '$5',
  }),
  ButtonWrapper: styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '$4',
    mt: '$5',
  }),
};
