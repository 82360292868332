import { IRolesResponse } from 'lib/types';

export interface IRoleParsed {
  id: number;
  name: string;
  permissions_num: number;
}

export const rolesTableParser = (data: IRolesResponse): IRoleParsed[] => {
  const { roles, rolesPermissions } = data;
  return roles.map((role) => {
    const { id, name } = role;
    const permissions = rolesPermissions[id].permissions;
    return {
      id,
      name,
      permissions_num: permissions.length,
    };
  });
};
