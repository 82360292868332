import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ButtonFeedback,
  Input,
  createFeedback,
} from '@resiliantinc/design-system';

import { AddGroupsModal } from 'components/molecules/AddGroupsModal';

import Api, { processApiError } from 'lib/api';
import { IGroupShort } from 'lib/types';

import { QuickInviteUserFormStyles } from './QuickInviteUserForm.styles';

interface IQuickInviteUserForm {
  email: string;
}
const QuickInviteUserForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch, reset } =
    useForm<IQuickInviteUserForm>();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<IGroupShort[]>([]);
  const email = watch('email');

  const isFormValid = useMemo(() => email, [email]);
  const onSubmit = useCallback(
    async (data: IQuickInviteUserForm) => {
      const { email } = data;
      try {
        setLoading(true);
        await Api.quickInviteUser({
          email,
          groups: groups.map((group) => group.id),
        });
        reset();
        setGroups([]);
        createFeedback({
          variant: 'success',
          heading: t('Users:User Invited'),
        });
      } catch (e: any) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [groups, reset, t]
  );

  return (
    <QuickInviteUserFormStyles.Form onSubmit={handleSubmit(onSubmit)}>
      <Input {...register('email')} label={t('Email')} />
      <AddGroupsModal setGroups={setGroups} groups={groups} />

      <ButtonFeedback isLoading={loading} disabled={!isFormValid}>
        {t('Users:Invite User')}
      </ButtonFeedback>
    </QuickInviteUserFormStyles.Form>
  );
};

export { QuickInviteUserForm };
