import { styled } from '@resiliantinc/design-system';

export const Wrapper = styled('div', {
  display: 'flex',
  gap: '$2',
  ai: 'center',
  borderLeft: '2px solid $slate50',
  height: '100%',
  pl: '$3',
});
