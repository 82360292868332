import React, { useState } from 'react';
import useSwr from 'swr';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table } from 'components/organisms/Table';
import { TableLoading } from 'components/organisms/Table';

import Api from 'lib/api';
import { organizationsTableParser } from 'lib/parsers';
import { IOrganizationsResponse } from 'lib/types';

import { columns } from './OrganizationsTable.coldef';

const OrganizationsTable = () => {
  const [page, setPage] = useState(1);

  const { data: Organizations, error } = useSwr<IOrganizationsResponse>(
    Api.getOrganizations(page)
  );

  if (error) return <LoadFailed />;
  if (!Organizations) return <TableLoading />;
  if (!Organizations.organizations.length) return <TableNoData />;

  return (
    <Table
      columnDefinition={columns}
      data={organizationsTableParser(Organizations)}
      paginator={{ ...Organizations.paginator, setPage }}
    />
  );
};

export { OrganizationsTable };
