import { ReactNode } from 'react';

import { AccessTokenProvider } from 'providers/AccessTokenProvider';
import { UserProvider } from 'providers/UserProvider';

export const AuthenticatedRoutesProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <AccessTokenProvider>
    <UserProvider>{children}</UserProvider>
  </AccessTokenProvider>
);
