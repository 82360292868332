import { Eye } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import useSwr from 'swr';

import { Button, Text } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';

import Api from 'lib/api';
import { formatDate } from 'lib/dates';
import { subjectLinkLiteral, subjectTypeLabelLiteralNoView } from 'lib/parsers';
import { IActivityDetailApiResponse, subjectTypeEnum } from 'lib/types';

import { ActivityDetailsStyles } from 'screens/ActivityDetails/ActivityDetails.styles';

export const ActivityDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, error } = useSwr<IActivityDetailApiResponse>(
    Api.getActivityDetails(+id!)
  );

  if (error) return <LoadFailed />;
  if (!data) return <ScreenLoader />;

  const {
    created_at,
    event,
    properties: { success, detailMessages },
    causer_id,
    causer_type,
    subject_id,
    subject_type,
    description,
  } = data.activity;

  const { subject, causer } = data.additional_details;

  const subjectTypeClean = subject_type
    ?.replace('App\\', '')
    ?.replace('Models\\', '') as subjectTypeEnum;

  const causerTypeClean = causer_type
    ?.replace('App\\', '')
    ?.replace('Models\\', '') as subjectTypeEnum;

  return (
    <ScreenWrapper title={t('Activity:Activity Detail')}>
      <ActivityDetailsStyles.Table>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Date/Time')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {formatDate(created_at, 'MMMM d YYYY hh:mm a')}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell />
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Event')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {event}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell />
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Success')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {success.toString()}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell />
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Causer')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {causer ||
              (!!causer_id
                ? `${subjectTypeLabelLiteralNoView[causerTypeClean]} ${causer_id}`
                : 'N/A')}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {!!causer_id && (
              <Button
                variant="outlined"
                leftIcon={<Eye size="1.6rem" />}
                as={Link}
                fullWidth
                to={generatePath(subjectLinkLiteral[causerTypeClean], {
                  id: causer_id,
                })}
              >
                {t('Activity:View')}
              </Button>
            )}
          </ActivityDetailsStyles.TableCell>
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Subject')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {subject ||
              (!!subject_id
                ? `${subjectTypeLabelLiteralNoView[subjectTypeClean]} ${subject_id}`
                : 'N/A')}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {!!subject_id && (
              <Button
                variant="outlined"
                leftIcon={<Eye size="1.6rem" />}
                as={Link}
                fullWidth
                to={generatePath(subjectLinkLiteral[subjectTypeClean], {
                  id: subject_id,
                })}
              >
                {t('Activity:View')}
              </Button>
            )}
          </ActivityDetailsStyles.TableCell>
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Description')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            {description}
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell />
        </ActivityDetailsStyles.TableRow>
        <ActivityDetailsStyles.TableRow>
          <ActivityDetailsStyles.TableCell>
            <Text bold>{t('Activity:Details')}</Text>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell>
            <ActivityDetailsStyles.Ul>
              {detailMessages?.map((message: string, index: number) => (
                <ActivityDetailsStyles.Li key={index}>
                  {message}
                </ActivityDetailsStyles.Li>
              ))}
            </ActivityDetailsStyles.Ul>
          </ActivityDetailsStyles.TableCell>
          <ActivityDetailsStyles.TableCell />
        </ActivityDetailsStyles.TableRow>
      </ActivityDetailsStyles.Table>
    </ScreenWrapper>
  );
};
