import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { Text, Title, styled } from '@resiliantinc/design-system';

import { AnimatedScreenOpacityWrapper } from 'components/Layout/AnimatedScreenOpacityWrapper';
import {
  FullWidthHeightScroll,
  screenMaxWidthCentered,
} from 'components/Layout/ScreenLayout';
import { BreadcrumbNavigation } from 'components/molecules/BreadcrumbNavigation';

type IScreenWrapper = {
  title?: ReactNode;
  subtitle?: string;
  overTitle?: string;
  action?: ReactElement;
  includeBreadcrumb?: boolean;
  titleComponent?: any;
};

const WrapperOuter = styled('div', screenMaxWidthCentered, {
  py: '4rem',
  px: '4rem',
});

const WrapperInner = styled('div', {
  width: '100%',
});

const TextWrapper = styled('div', {
  mb: '$6',
});

const TitleWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Subtitle = styled(Text, {
  fontSize: '$4',
  fontWeight: '$bold',
  color: '$general500',
  variants: {
    mt: {
      true: {
        mt: '$2',
      },
    },
    mb: {
      true: {
        mb: '$2',
      },
    },
  },
});

export const ScreenWrapper: FC<PropsWithChildren<IScreenWrapper>> = ({
  title,
  subtitle,
  children,
  action,
  includeBreadcrumb,
  titleComponent = 'h2',
  overTitle,
}) => {
  return (
    <AnimatedScreenOpacityWrapper>
      <FullWidthHeightScroll>
        <WrapperOuter>
          <TextWrapper>
            {includeBreadcrumb ? <BreadcrumbNavigation /> : null}
            {overTitle && <Subtitle mb>{overTitle}</Subtitle>}
            <TitleWrapper>
              <Title as={titleComponent} css={{ fontSize: '4.1rem' }}>
                {title}
              </Title>
              {action ? action : null}
            </TitleWrapper>
            {subtitle && <Subtitle mt>{subtitle}</Subtitle>}
          </TextWrapper>
          <WrapperInner>{children}</WrapperInner>
        </WrapperOuter>
      </FullWidthHeightScroll>
    </AnimatedScreenOpacityWrapper>
  );
};
