import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import useSwr from 'swr';

import { Box, Button, Link, Text, Title } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { Chip } from 'components/atoms/Chip';
import { ChipHolder } from 'components/atoms/ChipHolder';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { ActivityTable } from 'components/organisms/ActivityTable';
import { CheckIdModal } from 'components/organisms/CheckIdModal';
import { GatherInfoModal } from 'components/organisms/GatherInfoModal';
import { statusTooltipLiteral } from 'components/organisms/UsersTable/UsersTable.coldef';

import Api from 'lib/api';
import { IUserDetailsResponse, userStatusEnum } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { UserDetailStyles } from 'screens/UserDetails/UserDetail.styles';

export const UserDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [checkIdOpen, setCheckIdOpen] = useState(false);
  const [gatherInfoOpen, setGatherInfoOpen] = useState(false);
  const { data, error } = useSwr<IUserDetailsResponse>(
    id && Api.getUserDetails(+id)
  );

  // const onSaveChanges = useCallback(
  //   async (data: any) => {
  //     await Api.updateUser(+id!, data);
  //     await mutate();
  //     createFeedback({
  //       variant: 'success',
  //       heading: t('Users:User updated successfully'),
  //     });
  //   },
  //   [id, mutate, t]
  // );

  // const onDeleteUser = useCallback(async () => {
  //   await Api.deleteUser(+id!);
  //   navigate(routePaths.users);
  //   createFeedback({
  //     variant: 'success',
  //     heading: t('Users:User deleted successfully'),
  //   });
  // }, [id, navigate, t]);

  // <WithConfirmation
  //     onConfirm={onDeleteUser}
  //     modalTitle={t('Users:Are you sure you want to delete this user?')}
  //     modalDescription={t(
  //         'Users:You will be able to add them back in later'
  //     )}
  // >
  //   <Button variant="nude" danger leftIcon={<Trash />}>
  //     {t('Users:Delete User')}
  //   </Button>
  // </WithConfirmation>

  // <InlineEdit
  //     initialValue={{
  //       firstName,
  //       lastName,
  //     }}
  //     onSaveChanges={onSaveChanges}
  //     InitialComponent={Title}
  //     initialComponentProps={{ as: 'h3' }}
  // />

  const onCheckId = useCallback(() => {
    setCheckIdOpen(true);
  }, [setCheckIdOpen]);

  const onGatherInfo = useCallback(() => {
    setGatherInfoOpen(true);
  }, [setGatherInfoOpen]);

  if (error) return <LoadFailed />;
  if (!data) return <ScreenLoader />;

  const { firstName, lastName, id: userId, email } = data.user;
  const { groups, status } = data.userStats;

  return (
    <ScreenWrapper
      title={
        <Title as="h3">
          {firstName} {lastName}
        </Title>
      }
      includeBreadcrumb
      titleComponent="div"
      action={
        <Box
          css={{
            display: 'flex',
            ai: 'center',
            gap: '$2',
          }}
        >
          <Button onClick={onGatherInfo} variant="secondary">
            {t('Users:Collect User Info')}
          </Button>
          {status.includes(userStatusEnum.id_onboarded) && (
            <Button onClick={onCheckId}>{t('Users:Check ID')}</Button>
          )}
        </Box>
      }
    >
      <UserDetailStyles.TopLineWrapper>
        <UserDetailStyles.TopLineItem>
          <UserDetailStyles.TopLineItemLabel>
            {t('Users:User ID:')}
          </UserDetailStyles.TopLineItemLabel>
          <Text bold>{userId}</Text>
        </UserDetailStyles.TopLineItem>
        <UserDetailStyles.TopLineItem>
          <UserDetailStyles.TopLineItemLabel>
            {t('Users:User Status:')}
          </UserDetailStyles.TopLineItemLabel>
          <ChipHolder>
            {status?.map((status) => (
              <Chip title={status} tooltip={statusTooltipLiteral[status]} />
            ))}
          </ChipHolder>
        </UserDetailStyles.TopLineItem>
        <UserDetailStyles.TopLineItem>
          <UserDetailStyles.TopLineItemLabel>
            {t('Users:Contact:')}
          </UserDetailStyles.TopLineItemLabel>
          <Link href={`mailto:${email}`}>{email}</Link>
        </UserDetailStyles.TopLineItem>
        <UserDetailStyles.TopLineItem>
          <UserDetailStyles.TopLineItemLabel>
            {t('Users:Groups:')}
          </UserDetailStyles.TopLineItemLabel>
          <ChipHolder>
            {groups?.map((group: any) => (
              <Chip
                title={group.name}
                link={generatePath(routePaths.groupDetails, { id: group.id })}
              />
            ))}
          </ChipHolder>
        </UserDetailStyles.TopLineItem>
      </UserDetailStyles.TopLineWrapper>

      <ActivityTable apiCall={Api.getUserActivity(+id!, 1)} />

      <CheckIdModal
        opened={checkIdOpen}
        onClose={() => setCheckIdOpen(false)}
        userId={userId}
        email={email}
      />
      <GatherInfoModal
        opened={gatherInfoOpen}
        onClose={() => setGatherInfoOpen(false)}
        userId={userId}
        email={email}
      />
    </ScreenWrapper>
  );
};
