import { PropsWithChildren, ReactNode } from 'react';

import { LayoutContent } from './LayoutContent.styled';
import { LayoutWrapper, LayoutWrapperInner } from './ScreenLayout';

const Layout = ({
  children,
  layoutBanner,
}: PropsWithChildren<{ layoutBanner?: ReactNode }>) => {
  return (
    <LayoutWrapper>
      {layoutBanner}
      <LayoutWrapperInner>
        <LayoutContent>{children}</LayoutContent>
      </LayoutWrapperInner>
    </LayoutWrapper>
  );
};

export { Layout };
