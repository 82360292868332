import { AnimatePresence } from 'framer-motion';
import { useOutlet } from 'react-router-dom';

export const AnimatedOutletScreen = () => {
  const outlet = useOutlet();

  return (
    <AnimatePresence initial={false} mode="wait">
      {outlet}
    </AnimatePresence>
  );
};
