import { Accept, ErrorCode } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export const defaultMaxSizeMb = 50;

export const useUnknownErrorLiteral = () => {
  const { t } = useTranslation();
  return t('Dropzone:Something went wrong');
};

export const useGetRejectedErrorLiterals = ({
  accept,
  maxSizeMb,
}: {
  accept: Accept;
  maxSizeMb?: number;
}) => {
  const { t } = useTranslation();

  const errors: { [key: string]: any } = {
    [ErrorCode.FileTooSmall]: t('Dropzone:The file is too small'),
    [ErrorCode.TooManyFiles]: t('Dropzone:You have selected too many files'),
  };

  if (accept) {
    errors[ErrorCode.FileInvalidType] = t(
      'Dropzone:The file type is not allowed, only the following types are allowed: {{accepted}}',
      {
        accepted: Object.values(accept).join(', '),
      }
    );
  }

  if (maxSizeMb) {
    errors[ErrorCode.FileTooLarge] = t(
      'Dropzone:The file is too big, max size is {{max}}',
      {
        max: maxSizeMb,
      }
    );
  }

  return errors;
};

export const getBytesMaxSize = (maxSizeMb: number) =>
  maxSizeMb * Math.pow(1024, 2);
