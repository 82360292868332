import { styled } from '@resiliantinc/design-system';

export const ApiKeysStyles = {
  InputWithCopy: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    width: '100%',
  }),
};
