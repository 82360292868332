import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Box, Button } from '@resiliantinc/design-system';

import { formatDate } from 'lib/dates';
import { IActivityItemParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IActivityItemParsed>();

export const columns = [
  columnHelper.accessor('date', {
    header: 'Date',
    cell: (value) => {
      const date = value.getValue();
      return formatDate(date, 'MMMM d YYYY hh:mm a');
    },
  }),
  columnHelper.accessor('summary', {
    header: 'Summary',
  }),
  // columnHelper.accessor('subject', {
  //   header: 'Subject',
  //   cell: (value) => {
  //     const subject = value.getValue();
  //     if (subject === null) return 'N/A';
  //     const { openUrl, label } = subject;
  //     return <Link to={openUrl}>{label}</Link>;
  //   },
  // }),
  // columnHelper.accessor('causer', {
  //   header: 'Causer',
  //   cell: (value) => {
  //     const causer = value.getValue();
  //     if (causer === null) return 'N/A';
  //     const { openUrl, label } = causer;
  //     return <Link to={openUrl}>{label}</Link>;
  //   },
  // }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (value) => {
      return <Box css={{ textAlign: 'left' }}>{value.getValue()}</Box>;
    },
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.activityDetails, { id: value.getValue() })}
      >
        View
      </Button>
    ),
  }),
];
