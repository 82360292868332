import React, { FC, PropsWithChildren, cloneElement, useState } from 'react';

import { ClickAwayListener } from 'components/atoms/ClickAwayListener';

import { IDropDownMenu } from './DropDownMenu.interface';
import { DropDownMenuStyles } from './DropDownMenu.styles';

const DropDownMenu: FC<PropsWithChildren<IDropDownMenu>> = ({
  button,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <DropDownMenuStyles.Wrapper>
        {cloneElement(button, { onClick: handleOpen })}
        {open ? (
          <DropDownMenuStyles.Menu>{children}</DropDownMenuStyles.Menu>
        ) : null}
      </DropDownMenuStyles.Wrapper>
    </ClickAwayListener>
  );
};

export { DropDownMenu };
