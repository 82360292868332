import { styled } from '@resiliantinc/design-system';

export const DropDownMenuStyles = {
  Wrapper: styled('div', {
    position: 'relative',
  }),
  Menu: styled('div', {
    position: 'absolute',
    top: '100%',
    right: 0,
    bc: '$white',
    boxShadow: '$lg',
    borderRadius: '$sm',
    zIndex: 100,
    overflow: 'hidden',
  }),
};

export const DropDownItem = styled('a', {
  display: 'flex',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  color: '$black',
  textDecoration: 'none',
  p: '$3 $4',
  transition: 'background-color 150ms ease-in-out',
  '&:hover': {
    bc: '$sky100',
  },
});
