import { css, styled } from '@resiliantinc/design-system';

export const ColorIndicator = styled('span', {
  width: '1.2rem',
  height: '1.2rem',
  borderRadius: '50%',
});

export const LegendWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.6rem',
});

export const labelClassName = css({
  tspan: {
    fontSize: '1.4rem',
    position: 'absolute',
  },
});
export const labelNumberClassName = css({
  tspan: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
  },
});
