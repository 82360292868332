import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { SidebarLayout } from 'components/Layout/SidebarLayout';

import { AuthenticatedRoutesProvider } from 'providers/AuthenticatedRoutesProvider';

export const BaseRouterProvider = () => (
  <AuthenticatedRoutesProvider>
    <SidebarLayout>
      <Layout>
        <AnimatePresence>
          <Outlet />
        </AnimatePresence>
      </Layout>
    </SidebarLayout>
  </AuthenticatedRoutesProvider>
);
