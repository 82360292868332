import { Box, Title, styled } from '@resiliantinc/design-system';

export const FlexBox = styled(Box, {
  mt: '$2',
  display: 'flex',
  alignItems: 'center',
});

export const WrapperBox = styled(Box, {
  p: '$6',
  variants: {
    color: {
      yellow: {
        bc: '$yellow50',
      },
      sky: {
        bc: '$sky50',
      },
      lime: {
        bc: '$lime50',
      },
      purple: {
        bc: '$purple50',
      },
    },
  },
});

export const BoxTitle = styled(Title, {
  mb: '$6',
  fontSize: '$4',
});

export const InlineFeedbackHeading = styled('div', {
  fontWeight: '$bolder',
});

export const InlineFeedbackIconContainer = styled('div', {
  color: '$slate400',
  pr: '$3',
  pl: '$1',
  lineHeight: 0,
  pt: '$1',
});

export const InlineFeedbackHeaderContainer = styled('div', {
  width: '100%',
  display: 'flex',
  jc: 'space-between',
  ai: 'center',
});

export const InlineFeedbackIconWithTitle = styled('div', {
  display: 'flex',
});

export const ApproveHeadline = styled('div', {
  borderRadius: '$sm',
  p: '$2',
  background: '$yellow50',
  [`${InlineFeedbackIconContainer}`]: {
    color: '$yellow600',
  },
  [`${InlineFeedbackHeading}`]: {
    color: '$yellow700',
  },
});
