import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { CreateRoleForm } from 'components/organisms/CreateRoleForm';

export const CreateRole = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper title={t('ManageRoles:Create Role')} includeBreadcrumb>
      <CreateRoleForm />
    </ScreenWrapper>
  );
};
