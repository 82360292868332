import { uniqBy } from 'lodash';
import { nanoid } from 'nanoid';
import { parse } from 'papaparse';
import { useCallback, useMemo, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonFeedback,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { Dropzone } from 'components/molecules/Dropzone';

import Api from 'lib/api';

import { BulkInviteUsersFormStyles } from './BulkInviteUsersForm.styles';

interface IInviteUser {
  email: string;
  groups: number[];
}
const BulkInviteUsersForm = () => {
  const { t } = useTranslation();
  const [parsed, setParsed] = useState<IInviteUser[]>([]);
  const [loading, setLoading] = useState(false);

  const parsedLength = useMemo(() => parsed.length, [parsed]);
  const processFile = (files: any) => {
    const file = files[0];
    parse<{ email: string; groups: string }>(file, {
      complete: (results) => {
        if (
          !results.meta.fields?.includes('email') ||
          !results.meta.fields?.includes('groups')
        )
          return createFeedback({
            variant: 'error',
            heading: t(
              "Users:Invalid CSV file. Please include 'email' and 'groups' columns. To attach multiple groups separate them by | character. For example: '1|2|3'"
            ),
          });

        const data = results.data.map((row) => {
          const { email, groups } = row;
          if (!email || !groups) return null;
          return {
            email,
            groups: groups.split('|').map((id) => parseInt(id)),
          };
        });

        const filtered = uniqBy(
          data.filter((row) => row),
          'email'
        ) as IInviteUser[];

        setParsed(filtered);
      },
      header: true,
    });
  };

  const removeFromParsed = (index: number) => () => {
    setParsed((past) => {
      const newParsed = [...past];
      newParsed.splice(index, 1);
      return newParsed;
    });
  };

  const renderParsed = useMemo(() => {
    return parsed.map((row, index) => {
      return (
        <BulkInviteUsersFormStyles.User key={row.email}>
          <Text>{row.email}</Text>
          <BulkInviteUsersFormStyles.Chips>
            {row.groups.map((id) => (
              <Chip title={id.toString()} key={nanoid(9)} />
            ))}
          </BulkInviteUsersFormStyles.Chips>
          <BulkInviteUsersFormStyles.Button>
            <Button
              onClick={removeFromParsed(index)}
              leftIcon={<Trash />}
              variant="outlined"
            >
              Remove
            </Button>
          </BulkInviteUsersFormStyles.Button>
        </BulkInviteUsersFormStyles.User>
      );
    });
  }, [parsed]);

  const inviteUsers = useCallback(async () => {
    setLoading(true);
    try {
      await Api.bulkInviteUsers(parsed);
      createFeedback({
        variant: 'success',
        heading: t('Users:Users Invited'),
      });
      setParsed([]);
    } catch (e: any) {
      createFeedback({
        variant: 'error',
        heading:
          e?.response?.data?.Information?.detailMessages?.[0] ||
          t('Users:Could not invite users'),
      });
    } finally {
      setLoading(false);
    }
  }, [t, parsed]);

  return (
    <>
      <Dropzone
        accept={{
          'text/csv': ['.csv'],
        }}
        onDropAccepted={processFile}
      />
      <BulkInviteUsersFormStyles.Users>
        {renderParsed}
      </BulkInviteUsersFormStyles.Users>

      <ButtonFeedback
        disabled={!parsedLength}
        isLoading={loading}
        onClick={inviteUsers}
      >
        {t('Users:Invite {{number}} Users', {
          number: parsedLength,
        })}
      </ButtonFeedback>
    </>
  );
};

export { BulkInviteUsersForm };
