import useSwr from 'swr';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table, TableLoading } from 'components/organisms/Table';
import { columns } from 'components/organisms/UsersStatusTable/UsersStatusTable.coldef';

import Api from 'lib/api';
import { IInvitesApi } from 'lib/types';

const UsersStatusTable = () => {
  const { data, error } = useSwr<IInvitesApi>(Api.getInvites);
  if (error) return <LoadFailed />;
  if (!data) return <TableLoading />;
  if (!data.invites.length) return <TableNoData />;
  return <Table columnDefinition={columns} data={data.invites} />;
};

export { UsersStatusTable };
