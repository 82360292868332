import { Title, styled } from '@resiliantinc/design-system';

import LogoLight from 'assets/logo_light2.png';

import {
  FlexSpaceBetween,
  HeaderFooterWrapperInner,
  HeaderFooterWrapperOuter,
  Logo,
} from 'components/Layout/ScreenLayout';

const UnstyledLink = styled('a', {
  all: 'unset',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const PublicFooter = () => {
  return (
    <HeaderFooterWrapperOuter noBorder>
      <HeaderFooterWrapperInner>
        <FlexSpaceBetween>
          <UnstyledLink href="https://resiliant.com/">
            <Title as="h4" css={{ fontWeight: 400 }}>
              ResiliantID
            </Title>
          </UnstyledLink>
          <UnstyledLink href="https://resiliant.com/">
            <Logo src={LogoLight} />
          </UnstyledLink>
        </FlexSpaceBetween>
      </HeaderFooterWrapperInner>
    </HeaderFooterWrapperOuter>
  );
};

export { PublicFooter };
