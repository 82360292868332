import { IBaseApiResponse } from './base';
import { IGroupShort } from './group';

export interface IUser {
  id: number;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUserShort {
  id: number;
  name: string;
  email: string;
}
export interface IUserExtended extends IUser {
  phoneId: string;
  ip: string;
  MCS_personID: string;
  MCS_persistedFaceId: string;
  MCS_name: string;
  MCS_userdata: string;
  MCS_SpeechId: string;
  registration_doc_id: string;
  registration_doc_id_back: string;
  registration_face_id: string;
  enrolled: number;
  device: string;
  push_token: string;
  email_verified_at?: Date;
  created_at?: Date;
  updated_at?: Date;
}

export enum userStatusEnum {
  id_onboarded = 'IDNFT Onboarded',
  linked = 'Linked',
  registered = 'Registered',
  email_confirmed = 'Email Confirmed',
}

export interface IUserStatObject {
  groups: IGroupShort[];
  status: userStatusEnum[];
}

export interface IUsersStats {
  [key: number]: IUserStatObject;
}

export interface IUsersResponse extends IBaseApiResponse {
  users: IUser[];
  usersStats: IUsersStats;
}

export interface IUserShortResponse extends IBaseApiResponse {
  users: IUserShort[];
}

export interface IUserDetailsResponse
  extends Omit<IBaseApiResponse, 'paginator'> {
  user: IUserExtended;
  userStats: IUserStatObject;
}
