import { styled } from '@resiliantinc/design-system';

export const GatherInfoModalStyles = {
  Wrapper: styled('div', {
    p: '$4',
    display: 'flex',
    flexDirection: 'column',
  }),
  Radios: styled('div', {
    display: 'flex',
    gap: '$4',
    mb: '$4',
    mt: '$2',
  }),
};
