import { styled } from '@resiliantinc/design-system';

export const UserSearchInputStyles = {
  Wrapper: styled('div'),
  Results: styled('div', {
    position: 'absolute',
    top: '4.5rem',
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '$white',
    border: '1px solid $border',
    borderRadius: '$sm',
    boxShadow: '$sm',
    overflow: 'hidden',
    maxHeight: '20rem',
    overflowY: 'auto',
  }),
  Result: styled('div', {
    p: '$2 $3',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '$sky50',
    },
  }),
  Selection: styled('div', {
    mb: '$6',
  }),
  InputWrapper: styled('div', {
    position: 'relative',
  }),
};
