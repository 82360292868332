import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { IStepper } from './Stepper.interface';
import { StepperStyles } from './Stepper.styles';

const Stepper: FC<IStepper> = ({ steps }) => {
  const renderSteps = useMemo(() => {
    return steps.map((step, index) => {
      return (
        <StepperStyles.Item
          key={index}
          as={NavLink}
          to={step.path}
          isLast={index === steps.length - 1}
        >
          {step.label}
        </StepperStyles.Item>
      );
    });
  }, [steps]);

  return (
    <StepperStyles.Wrapper
      css={{
        gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
      }}
    >
      {renderSteps}
    </StepperStyles.Wrapper>
  );
};

export { Stepper };
