import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { EditRoleForm } from 'components/organisms/EditRoleForm';

export const EditRole = () => {
  return (
    <ScreenWrapper includeBreadcrumb>
      <EditRoleForm />
    </ScreenWrapper>
  );
};
