import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Input, Switch } from '@resiliantinc/design-system';

import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';
import {
  propertiesEnum,
  propertiesLiteral,
} from 'components/organisms/CreateGroupForms/CreateGroupForms.utils';

import { routePaths } from 'router/routePaths';

import { createGroupFormsState } from '../state.atom';

const General = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useAtom(createGroupFormsState);

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    setFormState((prev: any) => ({
      ...prev,
      ...data,
    }));
    navigate(routePaths.createGroupUsers);
  };

  const renderSwitches = useMemo(() => {
    return Object.keys(propertiesEnum).map((key) => {
      return (
        <Switch
          key={key}
          label={t(`Groups:${propertiesLiteral[key]}`)}
          {...register(`properties.${key}`)}
          defaultChecked={formState.properties[key]}
          id={key}
        />
      );
    });
  }, [register, t, formState.properties]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CreateGroupFormsStyles.SectionHalfWidth>
        <Input
          {...register('name')}
          label={t('Groups:Group Name')}
          placeholder={t('Groups:Texas')}
          required
          defaultValue={formState.name}
        />
        <Input
          {...register('description')}
          as="textarea"
          rows={10}
          css={{ resize: 'vertical' }}
          label={t('Groups:Description')}
          placeholder={t('Groups:The group with users from TX')}
          required
          defaultValue={formState.description}
        />
      </CreateGroupFormsStyles.SectionHalfWidth>
      <CreateGroupFormsStyles.SectionFullWidth>
        <CreateGroupFormsStyles.SectionTitle>
          {t('Groups:Properties')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.SwitchesSectionWrapper>
          {renderSwitches}
        </CreateGroupFormsStyles.SwitchesSectionWrapper>
      </CreateGroupFormsStyles.SectionFullWidth>
      <CreateGroupFormsStyles.NextButton>
        <Button>{t('Groups:Add Users')}</Button>
      </CreateGroupFormsStyles.NextButton>
    </form>
  );
};

export { General };
