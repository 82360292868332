import { Pencil } from 'react-bootstrap-icons';

import { styled } from '@resiliantinc/design-system';

export const InlineEditStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '$3',
  }),
  InitialWrapper: styled('div', {
    position: 'relative',
    cursor: 'pointer',
    variants: {
      disableEdit: {
        true: {
          cursor: 'default',
        },
      },
    },
  }),
  EditIndicator: styled(Pencil, {
    position: 'absolute',
    right: '-2rem',
    top: '-.5rem',
  }),
};
