import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Gear } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import useSwr from 'swr';

import {
  Button,
  Text,
  Title,
  createFeedback,
} from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { InlineEdit } from 'components/molecules/InlineEdit';
import { ActivityTable } from 'components/organisms/ActivityTable';
import { GroupPropertiesModal } from 'components/organisms/GroupPropertiesModal';

import Api from 'lib/api';
import { IGroupDetailsResponse } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import { groupNameAtom } from 'router/dynamicBreadcrumbs/BreadcrumbDynamicGroupName';
import { routePaths } from 'router/routePaths';

import { GroupDetailsStyles } from 'screens/GroupDetails/GroupDetails.styles';

export const GroupDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useUser();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const { data, error, mutate } = useSwr<IGroupDetailsResponse>(
    id && Api.getGroupDetails(+id)
  );
  const onSaveChanges = useCallback(
    async (data: any) => {
      await Api.updateGroup(+id!, data);
      await mutate();
      createFeedback({
        variant: 'success',
        heading: t('Groups:Group updated'),
      });
    },
    [id, mutate, t]
  );

  const [, setGroupName] = useAtom(groupNameAtom);
  useEffect(() => {
    if (data) {
      setGroupName(data.group.name);
    }
  }, [data, setGroupName]);

  if (error) return <LoadFailed />;
  if (!data) return <ScreenLoader />;

  const { name, description, owner_administrator_id } = data.group;
  //@ts-ignore
  const { ownerName, userCount, administratorCount } = data.groupsStats[id];

  return (
    <ScreenWrapper includeBreadcrumb>
      <GroupDetailsStyles.TopLineWrapper>
        <GroupDetailsStyles.EditName>
          <GroupDetailsStyles.TopLineItemLabel>
            {t('Groups:Group')}
          </GroupDetailsStyles.TopLineItemLabel>
          <InlineEdit
            initialValue={{ name }}
            onSaveChanges={onSaveChanges}
            InitialComponent={Title}
            initialComponentProps={{ as: 'h4' }}
            disableEdit={user?.id !== owner_administrator_id}
          />
          {user?.id === owner_administrator_id && (
            <Button
              variant="nude"
              leftIcon={<Gear />}
              css={{ mt: '$3' }}
              onClick={() => setSettingsModalOpen(true)}
            >
              {t('Groups:Settings')}
            </Button>
          )}
        </GroupDetailsStyles.EditName>

        <GroupDetailsStyles.GeneralStats>
          <GroupDetailsStyles.GeneralStatsItem>
            <GroupDetailsStyles.Item>
              <GroupDetailsStyles.TopLineItemLabel>
                {t('Groups:Owner:')}
              </GroupDetailsStyles.TopLineItemLabel>
              <Text bold>{ownerName}</Text>
            </GroupDetailsStyles.Item>
            <GroupDetailsStyles.Item>
              <GroupDetailsStyles.TopLineItemLabel>
                {t('Groups:Group ID:')}
              </GroupDetailsStyles.TopLineItemLabel>
              <Text bold>{id}</Text>
            </GroupDetailsStyles.Item>
            <GroupDetailsStyles.Item>
              <GroupDetailsStyles.TopLineItemLabel>
                {t('Groups:Contact:')}
              </GroupDetailsStyles.TopLineItemLabel>
              <Text bold>TODO</Text>
            </GroupDetailsStyles.Item>
            <GroupDetailsStyles.Item>
              <GroupDetailsStyles.TopLineItemLabel>
                {t('Groups:Total users:')}
              </GroupDetailsStyles.TopLineItemLabel>
              <Link
                to={generatePath(routePaths.groupUsers, {
                  id,
                })}
              >
                {userCount}
              </Link>
            </GroupDetailsStyles.Item>
            <GroupDetailsStyles.Item>
              <GroupDetailsStyles.TopLineItemLabel>
                {t('Groups:Total Administrators:')}
              </GroupDetailsStyles.TopLineItemLabel>
              <Link
                to={generatePath(routePaths.groupAdmins, {
                  id,
                })}
              >
                {administratorCount}
              </Link>
            </GroupDetailsStyles.Item>
          </GroupDetailsStyles.GeneralStatsItem>
          <GroupDetailsStyles.DescriptionWrapper>
            <GroupDetailsStyles.TopLineItemLabel>
              {t('Groups:Group Description:')}
            </GroupDetailsStyles.TopLineItemLabel>
            <InlineEdit
              initialValue={{ description }}
              onSaveChanges={onSaveChanges}
              InitialComponent={Text}
              initialComponentProps={{}}
              disableEdit={user?.id !== owner_administrator_id}
            />
          </GroupDetailsStyles.DescriptionWrapper>
        </GroupDetailsStyles.GeneralStats>
      </GroupDetailsStyles.TopLineWrapper>

      <ActivityTable
        apiCall={Api.getGroupActivity(+id!, +data?.group.organization_id)}
      />

      <GroupPropertiesModal
        opened={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
        group={data.group}
        onGroupUpdate={onSaveChanges}
      />
    </ScreenWrapper>
  );
};
