import { Admins } from './Admins';
import { Confirmation } from './Confirmation';
import { General } from './General';
import { Users } from './Users';

export const CreateGroupForms = {
  General,
  Users,
  Admins,
  Confirmation,
};
