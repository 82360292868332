import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { columns } from 'components/organisms/AdministratorsTable/AdministratorsTable.coldef';
import { Table, TableLoading } from 'components/organisms/Table';

import Api from 'lib/api/api';
import { administratorsTableParser } from 'lib/parsers';
import { IAdministratorsResponse } from 'lib/types';

const SubAdminsTable = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();

  const { data: administrators, error } = useSwr<IAdministratorsResponse>(
    Api.getSubAdmins(+id!, page)
  );

  if (error) return <LoadFailed />;
  if (!administrators) return <TableLoading />;
  if (!administrators.administrators.length) return <TableNoData />;

  return (
    <Table
      columnDefinition={columns}
      data={administratorsTableParser(administrators)}
      paginator={{ ...administrators.paginator, setPage }}
    />
  );
};

export { SubAdminsTable };
