import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { Box, Title } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { columns } from 'components/organisms/ActivityTable/ActivityTable.coldef';
import { Table, TableLoading } from 'components/organisms/Table';

import { activityParser } from 'lib/parsers';

import { IActivityTable } from './ActivityTable.interface';

const ActivityTable: FC<IActivityTable> = ({ apiCall }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const { data, error } = useSwr(apiCall + `?page=${page}`);

  if (!data) return <TableLoading />;
  if (error) return <LoadFailed />;

  const hasActivity = data.activity?.length > 0;

  return (
    <Box
      css={{
        border: '1px solid $border',
        p: '$6',
        mt: '$9',
      }}
    >
      <Title as="h4">
        {hasActivity
          ? t('Organizations:Activity')
          : t('Organizations:No Activity Yet')}
      </Title>
      {hasActivity ? (
        <Box css={{ mt: '$2' }}>
          <Table
            columnDefinition={columns}
            data={activityParser(data)}
            paginator={{ ...data.paginator, setPage }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export { ActivityTable };
