import { styled } from '@resiliantinc/design-system';

export const UserStatusSidebarStyles = {
  Wrapper: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$4',
    mr: '$6',
  }),
  Card: styled('div', {
    p: '$4',
    display: 'flex',
    gap: '$2',
    borderRadius: '$sm',
    color: '$general500',
    cursor: 'pointer',
    transition: 'background-color 200ms ease-in-out',
    '&:hover': {
      backgroundColor: '$sky50',
    },
    variants: {
      active: {
        true: {
          backgroundColor: '$primary',
          color: '$white',
          '&:hover': {
            backgroundColor: '$primary',
          },
        },
      },
    },
  }),
  CardTitle: styled('div', {
    fontWeight: '$bold',
    fontSize: '$4',
    mb: '$2',
  }),
  CardBody: styled('div', {}),
  CardText: styled('div', {
    fontSize: '$3',
  }),
};
