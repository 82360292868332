import { ReactNode } from 'react';

import {
  BreadcrumbItem,
  BreadcrumbItemLink,
} from './BreadcrumbNavigation.styles';

export interface INavigationItem {
  key: string;
  path?: string;
  label: string | ReactNode;
}

export const BreadcrumbNavigationItem = ({
  navigation,
}: {
  navigation: INavigationItem;
}) => {
  const child = !!navigation.path ? (
    <BreadcrumbItemLink to={navigation.path} data-testid="breadcrumb-lik">
      {navigation.label}
    </BreadcrumbItemLink>
  ) : (
    navigation.label
  );

  return (
    <BreadcrumbItem data-test-id="breadcrumb-item">{child}</BreadcrumbItem>
  );
};
