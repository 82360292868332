import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { IOrganizationParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IOrganizationParsed>();

export const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('name', {
    header: 'Name of Organization',
  }),
  columnHelper.accessor('userCount', {
    header: 'Users',
  }),
  columnHelper.accessor('administratorCount', {
    header: 'Administrators',
  }),
  columnHelper.accessor('status', {
    header: 'Status',
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.organizationDetails, {
          id: value.getValue(),
        })}
      >
        View
      </Button>
    ),
  }),
];
