import { styled } from '@resiliantinc/design-system';

export const AddToGroupModalStyles = {
  Content: styled('div', {
    px: '$6',
    pt: '$2',
    pb: '$6',
  }),
  User: styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid $border',
    py: '$2',
    pl: '$3',
  }),
};
