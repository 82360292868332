import { snakeCase } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Switch, createFeedback } from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';
import { WithConfirmation } from 'components/molecules/WithConfirmation';
import {
  propertiesEnum,
  propertiesLiteral,
} from 'components/organisms/CreateGroupForms/CreateGroupForms.utils';
import { GroupPropertiesModalStyles } from 'components/organisms/GroupPropertiesModal/GroupPropertiesModal.styles';

import Api, { processApiError } from 'lib/api';

import { routePaths } from 'router/routePaths';

import { IGroupPropertiesModal } from './GroupPropertiesModal.interface';
import './GroupPropertiesModal.styles';

const GroupPropertiesModal: FC<IGroupPropertiesModal> = ({
  opened,
  onClose,
  group,
  onGroupUpdate,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderSwitches = useMemo(() => {
    return Object.keys(propertiesEnum).map((key) => {
      return (
        <GroupPropertiesModalStyles.SwitchWrapper key={key}>
          <Switch
            label={t(`Groups:${propertiesLiteral[key]}`)}
            //@ts-ignore
            defaultChecked={!!group[snakeCase(key)]}
            id={key}
            onChange={(e) => {
              onGroupUpdate({
                [e.target.name]: e.target.checked,
              });
            }}
            name={key}
          />
        </GroupPropertiesModalStyles.SwitchWrapper>
      );
    });
  }, [t, group, onGroupUpdate]);

  const handleDeleteGroup = useCallback(async () => {
    try {
      await Api.deleteGroup(group.id);
      createFeedback({
        variant: 'success',
        heading: t('Groups:Group deleted'),
      });
      navigate(routePaths.groups);
    } catch (e) {
      const [message] = processApiError(e);
      createFeedback({
        variant: 'error',
        heading: 'Error: ' + message,
      });
    }
  }, [group.id, navigate, t]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('Groups:Group Settings')}
      maxWidth={440}
      position="rightDrawer"
    >
      <GroupPropertiesModalStyles.Content>
        {renderSwitches}
        <GroupPropertiesModalStyles.DeleteButton>
          <WithConfirmation
            onConfirm={handleDeleteGroup}
            modalTitle={t('Groups:Are you sure you want to delete this group?')}
            modalDescription={t("Groups:This action can't be undone.")}
          >
            <Button variant="nude" danger leftIcon={<Trash />}>
              {t('Groups:Delete Group')}
            </Button>
          </WithConfirmation>
        </GroupPropertiesModalStyles.DeleteButton>
      </GroupPropertiesModalStyles.Content>
    </Modal>
  );
};

export { GroupPropertiesModal };
