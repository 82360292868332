import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { AddUserToGroupModal } from 'components/organisms/AddToGroupModal';
import { GroupUsersTable } from 'components/organisms/GroupUsersTable';

export const GroupUsers = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [userModalOpen, setUserModalOpen] = useState(false);

  return (
    <ScreenWrapper
      title={t('Users:Group Users')}
      includeBreadcrumb
      action={
        <Button onClick={() => setUserModalOpen(true)}>
          {t('Users:Add User')}
        </Button>
      }
    >
      <GroupUsersTable />
      <AddUserToGroupModal
        groupId={id!}
        opened={userModalOpen}
        onClose={() => setUserModalOpen(false)}
      />
    </ScreenWrapper>
  );
};
