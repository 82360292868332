import { styled } from '@resiliantinc/design-system';

export const TableWrapper = styled('div', {
  width: '100%',
});

export const TableStyled = styled('table', {
  width: '100%',
  borderSpacing: 0,
});

export const TableHeader = styled('thead');

export const TableRow = styled('tr', {
  '&:nth-child(2n)': {
    bc: '$sky50',
  },
});

export const TableBody = styled('tbody');

export const HeaderCell = styled('th', {
  textAlign: 'left',
  p: '$2 $4',
  borderBottom: '2px solid $slate50',
  whiteSpace: 'nowrap',
});

export const DataCell = styled('td', {
  p: '$2 $4',
  border: 'none',
  variants: {
    alignRight: {
      true: {
        textAlign: 'right',
      },
    },
  },
});

export const TableFooter = styled('div', {
  height: '4.8rem',
  width: '100%',
  mt: '$6',
  bc: '#F4F4F4',
  borderTop: '2px solid $slate50',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  px: '$4',
});
