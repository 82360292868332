import { Text, styled } from '@resiliantinc/design-system';

export const GroupDetailsStyles = {
  TopLineWrapper: styled('div', {
    display: 'flex',
  }),
  EditName: styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: '1px solid $border',
    width: 'fit-content',
    pr: '$8',
    py: '$5',
  }),
  TopLineItemLabel: styled(Text, {
    fontSize: '$2',
    color: '$general500',
  }),
  GeneralStats: styled('div', {
    pl: '$8',
  }),
  GeneralStatsItem: styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '$9',
  }),
  Item: styled('div', {}),
  DescriptionWrapper: styled('div', {
    mt: '$8',
  }),
};
