import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonFeedback,
  Input,
  createFeedback,
} from '@resiliantinc/design-system';

import { processApiError } from 'lib/api';

import { IInitialValue, IInlineEdit } from './InlineEdit.interface';
import { InlineEditStyles } from './InlineEdit.styles';

const valueToString = (value: IInitialValue) => {
  return Object.values(value).join(' ');
};
const InlineEdit: FC<PropsWithChildren<IInlineEdit>> = ({
  initialValue,
  onSaveChanges,
  InitialComponent,
  initialComponentProps,
  wrapperStyles,
  disableEdit,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await onSaveChanges(data);
    } catch (e) {
      const messages = processApiError(e);
      createFeedback({
        variant: 'error',
        heading: 'Error: ' + messages.join(', '),
      });
    } finally {
      setLoading(false);
      setEditMode(false);
    }
  };

  const getInputs = useMemo(() => {
    return Object.entries(initialValue).map(([key, value]) => (
      <Input
        key={key}
        defaultValue={value}
        {...register(key)}
        wrapperCss={{
          m: 0,
          p: 0,
        }}
      />
    ));
  }, [initialValue, register]);

  if (disableEdit || !editMode)
    return (
      <InlineEditStyles.InitialWrapper
        disableEdit={disableEdit}
        onClick={!disableEdit ? setEditMode.bind(null, true) : undefined}
      >
        <InitialComponent {...initialComponentProps}>
          {valueToString(initialValue)}
        </InitialComponent>
        {!disableEdit && <InlineEditStyles.EditIndicator />}
      </InlineEditStyles.InitialWrapper>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InlineEditStyles.Wrapper {...wrapperStyles}>
        {getInputs}
        <ButtonFeedback isLoading={loading}>{t('Save')}</ButtonFeedback>
        <Button
          variant="outlined"
          onClick={setEditMode.bind(null, false)}
          type="button"
        >
          Cancel
        </Button>
      </InlineEditStyles.Wrapper>
    </form>
  );
};

export { InlineEdit };
