export const BASE_APP_PATH = '/';

const route = (routePath?: string) => `${BASE_APP_PATH}${routePath}`;

export const routePaths = {
  base: route(''),
  dashboard: route('dashboard'),
  login: route('login'),
  signup: route('signup'),
  signupSuccess: route('signup/success'),
  optout: route('optout'),
  verifyEmailChange: route('verifyemailchange'),
  confirmAdminAccount: route('confirmadminaccount'),
  userAcceptInvite: route('userAcceptInvite'),
  groups: route('groups'),
  createGroup: route('groups/create'),
  createGroupGeneral: route('groups/create/general'),
  createGroupUsers: route('groups/create/users'),
  createGroupAdmins: route('groups/create/admins'),
  createGroupConfirmation: route('groups/create/confirmation'),
  groupUsers: route('groups/:id/users'),
  groupAdmins: route('groups/:id/admins'),
  administrators: route('administrators'),
  administratorDetails: route('administrators/:id'),
  subAdministrators: route('administrators/:id/sub-administrators'),
  subUsers: route('administrators/:id/sub-users'),
  createAdministrator: route('administrators/create'),
  users: route('users'),
  userDetails: route('users/:id'),
  organizations: route('organizations'),
  account: route('account'),
  logout: route('account/logout'),
  viewPlan: route('account/plan'),
  orgBranding: route('account/branding'),
  myOrganization: route('account/organization'),
  groupDetails: route('groups/:id'),
  organizationDetails: route('organizations/:id'),
  orgUsers: route('organizations/:id/users'),
  quickInviteUser: route('users/quick-invite'),
  bulkInviteUsers: route('users/bulk-invite'),
  usersStatus: route('users/status'),
  manageRoles: route('roles'),
  viewRole: route('roles/:id/view'),
  editRole: route('roles/:id/edit'),
  createRole: route('roles/create'),
  activityDetails: route('activity/:id'),
  developer: route('developer'),
  developerApiKeys: route('developer/api-keys'),
  webhooks: route('developer/webhooks'),
};
