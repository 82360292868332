import { FC, cloneElement, isValidElement, useCallback, useState } from 'react';

import {
  Button,
  ButtonFeedback,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';
import { WithConfirmationStyles } from 'components/molecules/WithConfirmation/WithConfirmation.styles';

import { processApiError } from 'lib/api';

import { IWithConfirmation } from './WithConfirmation.interface';

export const WithConfirmation: FC<IWithConfirmation> = ({
  children,
  modalTitle,
  modalDescription,
  onConfirm,
  primaryActionText,
  secondaryActionText,
}) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await onConfirm();
    } catch (e) {
      const messages = processApiError(e);
      createFeedback({
        variant: 'error',
        heading: 'Error: ' + messages.join(', '),
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  }, [onConfirm, handleClose]);

  return (
    <>
      {isValidElement(children) &&
        cloneElement<any>(children, {
          onClick: handleClick,
        })}
      <Modal
        opened={opened}
        onClose={handleClose}
        maxWidth={500}
        position="centered"
        title={modalTitle}
      >
        <WithConfirmationStyles.Content>
          <Text>{modalDescription}</Text>
          <WithConfirmationStyles.ButtonWrapper>
            <Button onClick={handleClose} variant="secondary">
              {secondaryActionText || 'Cancel'}
            </Button>
            <ButtonFeedback onClick={handleConfirm} isLoading={loading}>
              {primaryActionText || 'Delete'}
            </ButtonFeedback>
          </WithConfirmationStyles.ButtonWrapper>
        </WithConfirmationStyles.Content>
      </Modal>
    </>
  );
};
