import { PropsWithChildren } from 'react';

import {
  PublicLayoutWrapper,
  PublicLayoutWrapperInner,
} from 'components/Layout/ScreenLayout';
import { PublicFooter } from 'components/molecules/PublicFooter';
import { TopMenu } from 'components/organisms/TopMenu';

export const PublicLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <PublicLayoutWrapper>
      <TopMenu hideButtons />
      <PublicLayoutWrapperInner>{children}</PublicLayoutWrapperInner>
      <PublicFooter />
    </PublicLayoutWrapper>
  );
};
