import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';

import { UploadButton } from 'components/molecules/Dropzone/UploadButton';
import { useDropzoneUtils } from 'components/molecules/Dropzone/useDropzoneUtil';

import { IDropzone } from './Dropzone.interface';
import { DropzoneStyled } from './Dropzone.styles';

const Dropzone: FC<IDropzone> = ({
  maxSizeMb = 1000000,
  accept = {
    'text/csv': ['.csv'],
  },
  maxFiles = 1,
  multiple,
  onDropRejected,
  onDropAccepted,
}) => {
  const {
    onDropFilesAccepted,
    onDropFilesRejected,
    errors,
    handleRemoveError,
    maxSizeBytes,
    onFileError,
  } = useDropzoneUtils({
    maxSizeMb,
    onDropRejected,
    onDropAccepted,
    accept,
  });

  const hasErrors = !!errors.length;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDropFilesAccepted,
    onDropRejected: onDropFilesRejected,
    onFileDialogOpen: handleRemoveError,
    onError: onFileError,
    multiple,
    maxFiles,
    accept,
    maxSize: maxSizeBytes,
    noClick: hasErrors,
  });

  return (
    <DropzoneStyled
      active={isDragActive}
      activeError={isDragActive && hasErrors}
      {...getRootProps()}
    >
      <input data-testid="drop-input" {...getInputProps()} />
      <UploadButton />
    </DropzoneStyled>
  );
};

export { Dropzone };
