import React, { FC, useCallback, useMemo, useState } from 'react';
import { Dash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { Button, Text, createFeedback } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { Modal } from 'components/atoms/Modal';

import Api from 'lib/api';
import { IRoleShort } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import { IAddRolesModal } from './AddRolesModal.interface';
import { AddRolesModalStyles } from './AddRolesModal.styles';
import './AddRolesModal.styles';

const AddRolesModal: FC<IAddRolesModal> = ({ setRoles, roles, api }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { stats } = useUser();
  const { roles: currentAdminRoles } = stats || {};
  const { data: roleData } = useSwr<{ roles: IRoleShort[] }>(Api.getRoles);
  const { t } = useTranslation();
  const addRole = useCallback(
    (role: IRoleShort) => async () => {
      if (api) {
        try {
          const { mutate, adminId } = api;
          await Api.addRoleToAdministrator(adminId, role.id);
          await mutate();
          createFeedback({
            variant: 'success',
            heading: t('Roles:Role added successfully'),
          });
        } catch (e) {
          createFeedback({
            variant: 'error',
            heading: t('Roles:Role could not be added'),
          });
          return;
        }
      }
      setRoles((past) => [...past, role]);
    },
    [setRoles, api, t]
  );

  const removeRole = useCallback(
    (role: IRoleShort) => async () => {
      if (api) {
        try {
          const { mutate, adminId } = api;
          await Api.removeRoleFromAdministrator(adminId, role.id);
          await mutate();
          createFeedback({
            variant: 'success',
            heading: t('Roles:Role removed successfully'),
          });
        } catch (e) {
          createFeedback({
            variant: 'error',
            heading: t('Roles:Role could not be removed'),
          });
          return;
        }
      }
      setRoles((past) => past.filter((r) => r.id !== role.id));
    },
    [setRoles, api, t]
  );

  const renderSelectedRoles = useMemo(
    () =>
      roles?.map((role) => (
        <Chip title={role.name} onDelete={removeRole(role)} key={role.id} />
      )),
    [roles, removeRole]
  );

  const isRoleSelected = useCallback(
    (role: IRoleShort) => {
      return roles.some((r) => r.id === role.id);
    },
    [roles]
  );

  const renderAvailableRoles = useMemo(
    () =>
      roleData?.roles
        .filter((role) =>
          currentAdminRoles?.some((adminRole) => adminRole.id === role.id)
        )
        .map((role) => (
          <AddRolesModalStyles.AvailableRole key={role.id}>
            <Text bold>{role.name}</Text>
            <Button
              onClick={!isRoleSelected(role) ? addRole(role) : removeRole(role)}
              type="button"
              rightIcon={isRoleSelected(role) ? <Dash /> : <Plus />}
              variant={isRoleSelected(role) ? 'secondary' : 'dark'}
            >
              {isRoleSelected(role) ? t('Roles:Remove') : t('Roles:Add')}
            </Button>
          </AddRolesModalStyles.AvailableRole>
        )),
    [roleData, isRoleSelected, addRole, removeRole, t, currentAdminRoles]
  );

  const openModal = () => setModalOpen(true);

  return (
    <>
      <AddRolesModalStyles.ChipsWithAddButton>
        {renderSelectedRoles}
        <AddRolesModalStyles.AddButton onClick={openModal}>
          {t('Roles:Add Roles')}
        </AddRolesModalStyles.AddButton>
      </AddRolesModalStyles.ChipsWithAddButton>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        position="bottomSheetCentered"
        maxWidth={555}
        title={t('Roles:Add Roles')}
      >
        <AddRolesModalStyles.Wrapper>
          {renderAvailableRoles}
        </AddRolesModalStyles.Wrapper>
      </Modal>
    </>
  );
};

export { AddRolesModal };
