import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { UsersTable } from 'components/organisms/UsersTable';

import { routePaths } from 'router/routePaths';

export const Users = () => {
  const { t } = useTranslation();

  return (
    <ScreenWrapper
      title={t('Users:Users')}
      action={
        <Button as={Link} to={routePaths.quickInviteUser}>
          {t('Users:Quick Invite User')}
        </Button>
      }
    >
      <UsersTable />
    </ScreenWrapper>
  );
};
