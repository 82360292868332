import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table, TableLoading } from 'components/organisms/Table';
import { columns } from 'components/organisms/UsersTable/UsersTable.coldef';

import Api from 'lib/api/api';
import { usersTableParser } from 'lib/parsers';
import { IUsersResponse } from 'lib/types';

const SubUsersTable = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();

  const { data: users, error } = useSwr<IUsersResponse>(
    Api.getSubUsers(+id!, page)
  );

  if (error) return <LoadFailed />;
  if (!users) return <TableLoading />;
  if (!users.users.length) return <TableNoData />;

  return (
    <Table
      columnDefinition={columns}
      data={usersTableParser(users)}
      paginator={{ ...users.paginator, setPage }}
    />
  );
};

export { SubUsersTable };
