import { useAtom } from 'jotai';
import React, { FC } from 'react';
import { List, QuestionCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LogoLight from 'assets/logo_light2.png';

import { FlexSpaceBetween, Logo } from 'components/Layout/ScreenLayout';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { DropDownItem } from 'components/molecules/DropDownMenu/DropDownMenu.styles';
import { menuOpenStateAtom } from 'components/organisms/MainMenu/MainMenu.atoms';

import { routePaths } from 'router/routePaths';

import { ITopMenu } from './TopMenu.interface';
import {
  TopMenuLeft,
  TopMenuRight,
  TopMenuWrapper,
  TopNavButton,
} from './TopMenu.styles';

const TopMenu: FC<ITopMenu> = ({ hideButtons }) => {
  const [, setMenuOpen] = useAtom(menuOpenStateAtom);
  const { t } = useTranslation();
  return (
    <TopMenuWrapper>
      <FlexSpaceBetween fixedHeight>
        <TopMenuLeft>
          {!hideButtons ? (
            <TopNavButton
              variant="nude"
              rounded
              leftIcon={<List size="2.4rem" />}
              onClick={() => setMenuOpen((p) => !p)}
            >
              open menu
            </TopNavButton>
          ) : null}
          <Link to={routePaths.dashboard}>
            <Logo src={LogoLight}></Logo>
          </Link>
        </TopMenuLeft>
        {!hideButtons ? (
          <TopMenuRight>
            {/*<TopNavButton*/}
            {/*  variant="nude"*/}
            {/*  rounded*/}
            {/*  leftIcon={<Search size="2.4rem" />}*/}
            {/*>*/}
            {/*  open menu*/}
            {/*</TopNavButton>*/}
            {/*<TopNavButton*/}
            {/*  variant="nude"*/}
            {/*  rounded*/}
            {/*  leftIcon={<Bell size="2.4rem" />}*/}
            {/*>*/}
            {/*  open menu*/}
            {/*</TopNavButton>*/}
            <DropDownMenu
              button={
                <TopNavButton
                  variant="nude"
                  rounded
                  leftIcon={<QuestionCircle size="2.4rem" />}
                >
                  open menu
                </TopNavButton>
              }
            >
              <DropDownItem
                href="https://resiliant.freshdesk.com/support/tickets/new"
                target="_blank"
                rel="noopener, noreferrer"
              >
                {t('MainMenu:Create a Support Ticket')}
              </DropDownItem>
              <DropDownItem
                href="https://app.gitbook.com/o/oUDVx1GU4mYYevsQgvIn/s/VKNdymZMwtSuLb7Sc6Tk/"
                target="_blank"
                rel="noopener, noreferrer"
              >
                {t('MainMenu:Documentation')}
              </DropDownItem>
              <DropDownItem
                href="https://resiliant.com/faq"
                target="_blank"
                rel="noopener, noreferrer"
              >
                {t('MainMenu:FAQ')}
              </DropDownItem>
            </DropDownMenu>
          </TopMenuRight>
        ) : null}
      </FlexSpaceBetween>
    </TopMenuWrapper>
  );
};

export { TopMenu };
