import React, { PropsWithChildren, useEffect, useState } from 'react';

import { FullScreenLoader } from 'components/atoms/FullScreenLoader';

import api from 'lib/api';
import { useAuth } from 'lib/auth';

export const AccessTokenProvider = ({ children }: PropsWithChildren<any>) => {
  const [isTokenReady, setIsTokenReady] = useState(false);
  const { isAuthenticated, getAccessToken } = useAuth();

  useEffect(() => {
    let mounted = true;
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      await api.setAccessTokenGetter(getAccessToken);
      if (mounted) setIsTokenReady(true);
    })();

    return () => {
      mounted = false;
    };
  }, [getAccessToken, isAuthenticated]);

  if (isAuthenticated && !isTokenReady) {
    return <FullScreenLoader data-testid="loading-token" />;
  }

  return children;
};
