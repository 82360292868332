import axios from 'axios';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonFeedback,
  InlineFeedback,
  Radio,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';
import { CheckIdModalStyles } from 'components/organisms/CheckIdModal/CheckIdModal.styles';

import Api from 'lib/api';

import { useUser } from 'providers/UserProvider';

import { ICheckIdModal } from './CheckIdModal.interface';

const FAIL_AFTER = 120000;

const CheckIdModal: FC<ICheckIdModal> = ({
  opened,
  onClose,
  userId,
  email,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [onCancel, setOnCancel] = useState<any>();
  const [txId, setTxId] = useState('');
  const { user } = useUser();

  const { email: adminEmail, api_token } = user || {};

  const getFeedback = useMemo(() => {
    if (loading) {
      return {
        heading: t('Users:Checking the User ID.') + ' Transaction id: ' + txId,
        variant: 'warning' as const,
      };
    }
    if (success) {
      return {
        heading: t('Users:User ID has been successfully verified'),
        variant: 'success' as const,
      };
    }
    if (error) {
      return {
        heading: errorMessage,
        variant: 'error' as const,
      };
    }
    return {
      heading: t('Users:Check the User ID'),
      variant: 'info' as const,
    };
  }, [loading, success, error, errorMessage, t, txId]);

  const handleErrorRemove = useCallback(() => {
    setError(false);
    setErrorMessage('');
  }, []);

  const onCheckId = useCallback(async () => {
    setLoading(true);
    const cancelToken = axios.CancelToken.source();

    try {
      await new Promise(async (resolve, reject) => {
        setOnCancel(() => () => {
          cancelToken.cancel();
          handleErrorRemove();
          reject('');
        });
        if (!adminEmail || !api_token) {
          createFeedback({
            variant: 'error',
            heading: 'Admin cannot perform this action.',
          });
          reject('');
        }
        const reqData = {
          email,
          admin_email: adminEmail!,
          token: api_token!,
        };
        try {
          const res = await Api.checkUserId(userId, reqData, {
            cancelToken: cancelToken.token,
          });
          const { TransactionId } = res.data;

          setTxId(TransactionId);

          if (res.status === 400) {
            throw new Error('not_found');
          }

          let currentTime = 0;
          const poll = setInterval(async () => {
            setOnCancel(() => () => {
              clearInterval(poll);
              handleErrorRemove();
              cancelToken.cancel();
              reject('');
            });
            try {
              if (currentTime >= FAIL_AFTER) {
                clearInterval(poll);
                throw new Error('timeout');
              }
              const res = await Api.getCheckIdStatus(TransactionId, {
                cancelToken: cancelToken.token,
              });
              currentTime += 6000;
              if (res.data.isAuthenticated === true) {
                clearInterval(poll);
                resolve(true);
              }
            } catch (e: any) {
              console.log(e);
              clearInterval(poll);
              setError(true);
              reject(e);
            }
          }, 3000);
        } catch (e: any) {
          if (e.message === 'not_found') {
            setErrorMessage('User not recognized.');
            setError(true);
          }
          reject(e);
          return;
        }
      });
      setSuccess(true);
    } catch (err: any) {
      if (typeof err === 'string') {
        return;
      }
      setError(true);
      setErrorMessage('Our system was not able to verify this user’s identity');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }, [email, handleErrorRemove, adminEmail, api_token, userId]);

  return (
    <Modal
      maxWidth={555}
      opened={opened}
      onClose={onClose}
      position="centered"
      title={t('Users:Check ID')}
    >
      <CheckIdModalStyles.Wrapper>
        <InlineFeedback {...getFeedback} css={{ mb: '$6' }} />
        <Text bold>Method:</Text>
        <CheckIdModalStyles.Radios>
          <Radio label="Face" defaultChecked name="method" id="face" />
          <Radio label="Voice" name="method" id="voice" />
        </CheckIdModalStyles.Radios>
        <ButtonFeedback onClick={onCheckId} isLoading={loading}>
          {t('Users:Check ID')}
        </ButtonFeedback>
        {loading && (
          <Button
            variant="secondary"
            onClick={onCancel}
            fullWidth
            css={{ mt: '$4' }}
          >
            Cancel
          </Button>
        )}
      </CheckIdModalStyles.Wrapper>
    </Modal>
  );
};

export { CheckIdModal };
