import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { ButtonFeedback, Text } from '@resiliantinc/design-system';

import { IUserMultiSelect } from 'components/molecules/UserSearchInput/UserSearchInput.interface';
import { UserSearchInputStyles } from 'components/molecules/UserSearchInput/UserSearchInput.styles';
import { AddToGroupModalStyles } from 'components/organisms/AddToGroupModal/AddToGroupModal.styles';

import Api from 'lib/api';
import { IAdministratorShort, IUserShort } from 'lib/types';

export const useUserSelect = (
  apiToUse: IUserMultiSelect['apiToUse'] = Api.searchUsers,
  keyToUse: IUserMultiSelect['keyToUse'] = 'users',
  handleAdd?: (id: number) => () => void
) => {
  const [active, setActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [buttonLoading, setButtonLoading] = useState<number | null>(null);
  const { data: fetchedUsers } = useSwr<any>(
    searchTerm && apiToUse(searchTerm)
  );

  const { t } = useTranslation();

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, 500);

  const renderFetchedUsers = useCallback(
    (handleSelect: (user: IUserShort) => () => void) => {
      if (!searchTerm) return null;

      if (!fetchedUsers) {
        return (
          <UserSearchInputStyles.Result>
            <Text>{t('Users:Loading...')}</Text>
          </UserSearchInputStyles.Result>
        );
      }

      if (!fetchedUsers[keyToUse].length) {
        return (
          <UserSearchInputStyles.Result>
            <Text>{t('Users:No results found')}</Text>
          </UserSearchInputStyles.Result>
        );
      }

      const users = fetchedUsers[keyToUse];
      return users?.map((user: any) => (
        <UserSearchInputStyles.Result onClick={handleSelect(user)}>
          <Text>
            {user.name}, {user.email}
          </Text>
        </UserSearchInputStyles.Result>
      ));
    },
    [fetchedUsers, searchTerm, t, keyToUse]
  );

  const renderAsTable = useMemo(() => {
    const users = fetchedUsers?.[keyToUse];

    if (!users?.length && !searchTerm) {
      return null;
    }
    if (!users?.length && searchTerm) {
      return (
        <Text
          bold
          css={{
            mb: '$1',
            pl: '$3',
          }}
        >
          {t('Users:No results found')}
        </Text>
      );
    }
    const rendered = users.map((user: IAdministratorShort | IUserShort) => {
      const handleClick = (id: number) => async () => {
        setButtonLoading(id);
        await handleAdd?.(id)();
        setButtonLoading(null);
      };
      return (
        <AddToGroupModalStyles.User key={user.id}>
          <Text
            css={{
              flex: 0.6,
            }}
          >
            {user.name}
          </Text>
          <Text
            css={{
              flex: 1,
            }}
          >
            {user.email}
          </Text>
          <ButtonFeedback
            leftIcon={<Plus />}
            onClick={handleClick?.(user.id)}
            isLoading={buttonLoading === user.id}
          >
            {t('Users:Add')}
          </ButtonFeedback>
        </AddToGroupModalStyles.User>
      );
    });

    return (
      <>
        <Text
          bold
          css={{
            mb: '$1',
            pl: '$3',
          }}
        >
          {t('Users:Results')}
        </Text>
        {rendered}
      </>
    );
  }, [fetchedUsers, handleAdd, keyToUse, t, searchTerm, buttonLoading]);

  return {
    active,
    setActive,
    searchTerm,
    handleChange,
    renderFetchedUsers,
    users: fetchedUsers?.[keyToUse],
    renderAsTable,
  };
};
