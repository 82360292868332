import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { UserAvatar } from 'components/organisms/UserAvatar';
import { UserDetailsForm } from 'components/organisms/UserDetailsForm';

export const Account = () => {
  return (
    <ScreenWrapper>
      <UserAvatar />
      <UserDetailsForm />
    </ScreenWrapper>
  );
};
