import { styled } from '@resiliantinc/design-system';

export const FormSection = styled('div', {
  mt: '$6',
});

export const TnCWrapper = styled('div', {
  display: 'flex',
  my: '$6',
});

export const WrapperWithBg = styled('div', {
  bc: '$slate50',
  px: '$4',
  mt: '$2',
  borderRadius: '$sm',
  border: '1px solid $slate100',
});
