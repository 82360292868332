import { t } from 'i18next';
import React, { FC, useCallback, useMemo } from 'react';

import { Input } from '@resiliantinc/design-system';

import { ClickAwayListener } from 'components/atoms/ClickAwayListener';
import { useUserSelect } from 'components/molecules/UserSearchInput/UserSearchInput.hooks';
import { IUserMultiSelect } from 'components/molecules/UserSearchInput/UserSearchInput.interface';
import { UserSearchInputStyles } from 'components/molecules/UserSearchInput/UserSearchInput.styles';
import { Table } from 'components/organisms/Table';

import Api from 'lib/api';
import { IUserShort } from 'lib/types';

import { columns } from './UserMultiSelect.coldef';

export const UserMultiSelect: FC<IUserMultiSelect> = ({
  users,
  setUsers,
  apiToUse = Api.searchUsers,
  keyToUse = 'users',
}) => {
  const { active, setActive, searchTerm, handleChange, renderFetchedUsers } =
    useUserSelect(apiToUse, keyToUse);

  const handleSelect = useCallback(
    (user: IUserShort) => () => {
      if (users.find((u) => u.id === user.id)) return;
      setUsers((prev: IUserShort[]) => [...prev, user]);
    },
    [setUsers, users]
  );

  const handleRemoveUser = useCallback(
    (id: number) => {
      setUsers((prev: IUserShort[]) => prev.filter((user) => user.id !== id));
    },
    [setUsers]
  );

  const renderSelectedUsers = useMemo(() => {
    if (!users.length) return null;

    return (
      <Table
        columnDefinition={columns}
        data={users}
        rowAction={handleRemoveUser}
      />
    );
  }, [users, handleRemoveUser]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setActive(false)}>
        <UserSearchInputStyles.Wrapper onFocus={() => setActive(true)}>
          <UserSearchInputStyles.InputWrapper>
            <Input
              type="search"
              name="user-search"
              placeholder={t('Users:Search')}
              onChange={handleChange}
            />

            {active && searchTerm && (
              <UserSearchInputStyles.Results>
                {renderFetchedUsers(handleSelect)}
              </UserSearchInputStyles.Results>
            )}
          </UserSearchInputStyles.InputWrapper>
        </UserSearchInputStyles.Wrapper>
      </ClickAwayListener>
      <UserSearchInputStyles.Selection>
        {renderSelectedUsers}
      </UserSearchInputStyles.Selection>
    </>
  );
};
