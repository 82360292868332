import { motion } from 'framer-motion';

import { keyframes, styled } from '@resiliantinc/design-system';

export const MENUS_COLLAPSED_SIZE = '7rem';
export const MENUS_COLLAPSED_WIDTH = '10rem';

const moveDown = keyframes({
  from: { transform: 'translateY(0)', height: 'auto' },
  to: { transform: 'translateY(100%)', height: 0 },
});

const moveUp = keyframes({
  from: { transform: 'translateY(100%)', height: 0 },
  to: { transform: 'translateY(0)', height: 'auto' },
});

export const NavBackdrop = styled(motion.div, {
  transformOrigin: 'left',
  width: '100vw',
  height: `calc(100vh - ${MENUS_COLLAPSED_SIZE})`,
  bottom: 0,
  right: 0,
  zIndex: '$1',
  bc: '$slate900',
  opacity: 0.2,
  order: 1,
});

export const Nav = styled('nav', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  zIndex: '$2',
  order: 1,
  bc: '$slate800',
  transition: 'width 0.3s ease-in-out',
  animation: `${moveUp} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) normal both`,
  height: `calc(100vh - ${MENUS_COLLAPSED_SIZE})`,
  overflowY: 'auto',
  '@bp2': {
    width: MENUS_COLLAPSED_WIDTH,
    order: 0,
    minWidth: MENUS_COLLAPSED_WIDTH,
    animation: 'none',
  },
  variants: {
    open: {
      true: {
        width: '26.2rem',
      },
    },
    visible: {
      false: {
        animation: `${moveDown} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) normal both`,
        animationDirection: 'normal',
        '@bp2': {
          display: 'flex',
          animation: 'none',
        },
      },
    },
  },
});
