import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { ClickAwayListener } from 'components/atoms/ClickAwayListener';
import { useUserSelect } from 'components/molecules/UserSearchInput/UserSearchInput.hooks';

import { IUserShort } from 'lib/types';

import { IUserSearchInput } from './UserSearchInput.interface';
import { UserSearchInputStyles } from './UserSearchInput.styles';

const UserSearchInput: FC<IUserSearchInput> = ({ setUser, user }) => {
  const { t } = useTranslation();

  const { active, setActive, searchTerm, handleChange, renderFetchedUsers } =
    useUserSelect();

  const handleSelect = useCallback(
    (user: IUserShort) => () => {
      setUser(user);
      setActive(false);
    },
    [setUser, setActive]
  );

  const deleteSelectedUser = useCallback(() => {
    setUser(undefined);
  }, [setUser]);

  const renderSelectedUser = useMemo(() => {
    if (!user) return null;

    return <Chip title={user.name} onDelete={deleteSelectedUser} />;
  }, [user, deleteSelectedUser]);

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <UserSearchInputStyles.Wrapper onFocus={() => setActive(true)}>
        <UserSearchInputStyles.InputWrapper>
          {!user && (
            <Input
              type="search"
              name="user-search"
              placeholder={t('Users:Search')}
              onChange={handleChange}
            />
          )}
          {active && searchTerm && (
            <UserSearchInputStyles.Results>
              {renderFetchedUsers(handleSelect)}
            </UserSearchInputStyles.Results>
          )}
        </UserSearchInputStyles.InputWrapper>
        {user && (
          <UserSearchInputStyles.Selection>
            {renderSelectedUser}
          </UserSearchInputStyles.Selection>
        )}
      </UserSearchInputStyles.Wrapper>
    </ClickAwayListener>
  );
};

export { UserSearchInput };
