import { useAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ButtonFeedback,
  Input,
  Title,
  createFeedback,
} from '@resiliantinc/design-system';

import { AddRolesModal } from 'components/molecules/AddRolesModal';
import { UserSearchInput } from 'components/molecules/UserSearchInput';
import { createAdminSuccessAtom } from 'components/organisms/CreateAdministratorForm/CreateAdministratorForm.atoms';
import { CreateAdministratorFormStyles } from 'components/organisms/CreateAdministratorForm/CreateAdministratorForm.styles';

import Api, { processApiError } from 'lib/api';
import { IRoleShort, IUserShort } from 'lib/types';

import { routePaths } from 'router/routePaths';

import './CreateAdministratorForm.styles';

interface ICreateAdministratorForm {
  firstName: string;
  lastName: string;
  email: string;
}

const CreateAdministratorForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, reset } =
    useForm<ICreateAdministratorForm>();

  const [user, setUser] = useState<IUserShort>();
  const [roles, setRoles] = useState<IRoleShort[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [, setGlobalSuccess] = useAtom(createAdminSuccessAtom);
  const onSubmit = async (data: ICreateAdministratorForm) => {
    const { firstName, lastName, email } = data;
    try {
      setLoading(true);
      await Api.createAdministrator({
        firstName,
        lastName,
        administratorEmail: email,
        associatedUserEmail: user?.email,
        userId: user?.id,
        roles: roles.map((role) => role.id),
      });
      setSuccess(true);
      setGlobalSuccess(true);
      reset();
      setUser(undefined);
      setRoles([]);
      createFeedback({
        variant: 'success',
        heading: t('Administrators:Administrator created successfully'),
      });

      navigate(routePaths.administrators);
    } catch (e) {
      const messages = processApiError(e);
      createFeedback({
        variant: 'error',
        heading: 'Error: ' + messages.join(', '),
      });
    } finally {
      setLoading(false);
    }
  };

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');

  const isFormValid = useMemo(
    () => firstName && lastName && email && user,
    [firstName, lastName, email, user]
  );

  return (
    <CreateAdministratorFormStyles.Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('firstName')}
        label={t('Administrators:First Name')}
        dense
        required
      />
      <Input
        {...register('lastName')}
        label={t('Administrators:Last Name')}
        dense
        required
      />
      <Input
        {...register('email')}
        label={t('Administrators:Email')}
        dense
        required
      />
      <AddRolesModal setRoles={setRoles} roles={roles} />
      <CreateAdministratorFormStyles.AssociatedUser>
        <Title
          css={{
            mb: '$6',
          }}
        >
          {t('Users:Associated IdNFT User')}
        </Title>
        <UserSearchInput setUser={setUser} user={user} />
      </CreateAdministratorFormStyles.AssociatedUser>
      <CreateAdministratorFormStyles.SubmitButton>
        <ButtonFeedback
          isLoading={loading}
          isSuccess={success}
          disabled={!isFormValid}
        >
          {t('Administrators:Create Administrator')}
        </ButtonFeedback>
      </CreateAdministratorFormStyles.SubmitButton>
    </CreateAdministratorFormStyles.Form>
  );
};

export { CreateAdministratorForm };
