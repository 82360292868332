import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { QuickInviteUserForm } from 'components/organisms/QuickInviteUserForm';

export const QuickInviteUser = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper title={t('Users:Quick User Invite')}>
      <QuickInviteUserForm />
    </ScreenWrapper>
  );
};
