import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { OrgBrandingForm } from 'components/organisms/OrgBrandingForm';

export const OrgBranding = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper
      title={t('Organizations:Manage Branding')}
      subtitle={t(
        'Organizations:Manage colors, logo and tags for your Organization'
      )}
    >
      <OrgBrandingForm />
    </ScreenWrapper>
  );
};
