import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { createFeedback } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { columns } from 'components/organisms/GroupUsersTable/GropUsersTable.coldef';
import { Table, TableLoading } from 'components/organisms/Table';

import { processApiError } from 'lib/api';
import Api from 'lib/api';
import { usersTableParser } from 'lib/parsers';
import { IGroupDetailsResponse, IUsersResponse } from 'lib/types';

import { groupNameAtom } from 'router/dynamicBreadcrumbs/BreadcrumbDynamicGroupName';

export const useUsers = (id: string) => {
  const [page, setPage] = useState(1);
  const {
    data: users,
    error,
    mutate,
  } = useSwr<IUsersResponse>(id && Api.getGroupUsers(+id, page));

  return {
    users,
    error,
    mutate,
    setPage,
  };
};

const GroupUsersTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { users, error, setPage, mutate } = useUsers(id!);
  const { data } = useSwr<IGroupDetailsResponse>(
    id && Api.getGroupDetails(+id)
  );

  const [, setGroupName] = useAtom(groupNameAtom);
  useEffect(() => {
    if (data) {
      setGroupName(data.group.name);
    }
  }, [data, setGroupName]);

  const rowAction = useCallback(
    async (userId: number) => {
      try {
        await Api.removeUserFromGroup(+id!, +userId);
        await mutate();
        createFeedback({
          variant: 'success',
          heading: t('Users:User removed successfully'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      }
    },
    [id, mutate, t]
  );

  if (error) return <LoadFailed />;
  if (!users) return <TableLoading />;
  if (!users.users.length) return <TableNoData />;

  const { group } = data || {};

  const {
    allows_deleting_users,
    displays_user_email,
    displays_user_first_name,
    displays_user_last_name,
  } = group || {};

  return (
    <Table
      columnDefinition={columns(
        !displays_user_email,
        !allows_deleting_users,
        !displays_user_first_name,
        !displays_user_last_name
      )}
      data={usersTableParser(users)}
      paginator={{ ...users.paginator, setPage }}
      rowAction={rowAction}
    />
  );
};

export { GroupUsersTable };
