import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useAuth } from 'lib/auth';

import { routePaths } from 'router/routePaths';

export const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffectOnce(() => {
    (async () => {
      await logout();
      navigate(routePaths.login);
    })();
  });

  return null;
};
