import { capitalize } from 'lodash';

import { IOrganizationsResponse } from 'lib/types';

export interface IOrganizationParsed {
  id: number;
  name: string;
  userCount: number;
  administratorCount: number;
  status: string;
}
export const organizationsTableParser = (
  data: IOrganizationsResponse
): IOrganizationParsed[] => {
  const { organizations, organizationsStats } = data;
  return organizations.map((organization) => {
    const { id, name, status } = organization;
    const { userCount, administratorCount } = organizationsStats[id];
    return {
      id,
      name,
      userCount,
      administratorCount,
      status: capitalize(status),
    };
  });
};
