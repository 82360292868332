import { ArrowLeft } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, RouteObject } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Button } from '@resiliantinc/design-system';

import { screenMaxWidthCentered } from 'components/Layout/ScreenLayout';

import { appRoutes } from 'router/Router';

import { Breadcrumb, BreadcrumbGoBack } from './BreadcrumbNavigation.styles';
import { convertBreadcrumbToNavigationItem } from './BreadcrumbNavigation.utils';
import {
  BreadcrumbNavigationItem,
  INavigationItem,
} from './BreadcrumbNavigationItem';

export const BreadcrumbNavigation = ({
  routes = appRoutes,
  showOnMobile = true,
}: {
  routes?: RouteObject[];
  showOnMobile?: boolean;
}) => {
  const { t } = useTranslation();

  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: ['/'],
    disableDefaults: true,
  });

  const breadcrumbsNavigation: INavigationItem[] = breadcrumbs.map(
    convertBreadcrumbToNavigationItem
  );

  const firstNavigation =
    breadcrumbsNavigation[breadcrumbsNavigation.length - 1];

  const BreadcrumbChild = (
    <>
      {!!firstNavigation && !!firstNavigation.path && (
        <BreadcrumbGoBack>
          <Button
            variant="secondary"
            rounded
            leftIcon={<ArrowLeft />}
            as={Link}
            to={firstNavigation.path}
          >
            {t('BreadcrumbNavigation:back')}
          </Button>
        </BreadcrumbGoBack>
      )}
      {breadcrumbsNavigation.map((item) => (
        <BreadcrumbNavigationItem key={item.key} navigation={item} />
      ))}
    </>
  );

  const BreadcrumbEmpty = <BreadcrumbGoBack />;

  return (
    <Breadcrumb
      className={screenMaxWidthCentered()}
      showOnMobile={showOnMobile}
    >
      {!breadcrumbsNavigation?.length ? BreadcrumbEmpty : BreadcrumbChild}
    </Breadcrumb>
  );
};
