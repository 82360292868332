import { CloudUpload } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { Text, theme } from '@resiliantinc/design-system';

export const UploadButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <CloudUpload size="4.2rem" color={theme.colors.sky400.value} />
      <Text bold css={{ cursor: 'pointer' }}>
        <span style={{ textDecoration: 'underline' }}>
          {t('Dropzone:Click to upload')}
        </span>
        <span>{t('Dropzone: or drag and drop CSV file')}</span>
      </Text>
    </>
  );
};
