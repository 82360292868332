import { IBaseApiResponse } from 'lib/types/base';

export interface IActivityProperty {
  success: boolean;
  detailMessages: string[];
  organization_id: number;
}
export enum subjectTypeEnum {
  organization = 'organization',
  admin = 'admin',
  group = 'Group', //why in the world is this capitalized? 🙄
  user = 'user',
  userCap = 'User', //why in the world is this capitalized? 🙄
}
export interface IActivityItem {
  batch_uuid: string;
  causer_id: number;
  causer_type: string;
  created_at: string;
  description: string;
  event: string;
  id: number;
  log_name: string;
  properties: IActivityProperty;
  subject_id: number;
  subject_type: string;
  updated_at: string;
}

export interface IAdditionalDetails {
  subject: string;
  causer: string;
}

export interface IActivityApiResponse extends IBaseApiResponse {
  activity: IActivityItem[];
}

export interface IActivityDetailApiResponse extends IBaseApiResponse {
  activity: IActivityItem;
  additional_details: IAdditionalDetails;
}
