import React, { FC, PropsWithChildren } from 'react';

import { Title } from '@resiliantinc/design-system';

import { IChartWrapper } from './ChartWrapper.interface';
import { ChartWrapperInner } from './ChartWrapper.styles';

const ChartWrapper: FC<PropsWithChildren<IChartWrapper>> = ({
  title,
  children,
}) => {
  return (
    <ChartWrapperInner>
      <Title as="h4" css={{ mb: '$6', fontWeight: 600, fontSize: '$5' }}>
        {title}
      </Title>
      {children}
    </ChartWrapperInner>
  );
};

export { ChartWrapper };
