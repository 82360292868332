import { CheckCircle } from 'react-bootstrap-icons';

import { Text, Title } from '@resiliantinc/design-system';

import { PublicLayout } from 'components/Layout';
import {
  CenteredContentWrapper,
  FlexCenterColumn,
} from 'components/Layout/ScreenLayout';

export const SignupSuccess = () => {
  return (
    <PublicLayout>
      <CenteredContentWrapper>
        <FlexCenterColumn>
          <CheckCircle size="10rem" />
          <Title css={{ mt: '$4' }}>Thanks for signing up!</Title>
          <Text css={{ mt: '$1' }}>
            We'll reach out to you soon to confirm all the details.
          </Text>
        </FlexCenterColumn>
      </CenteredContentWrapper>
    </PublicLayout>
  );
};
