import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { Switch, Text } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';

import Api from 'lib/api';
import { IRoleResponse } from 'lib/types';

import { CreateGroupFormsStyles } from '../CreateGroupForms/CreateGroupForms.styles';

const ViewRoleDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: currentRoleData, error } = useSwr<IRoleResponse>(
    id && Api.getRoleDetails(+id)
  );

  const renderSwitches = useMemo(() => {
    return currentRoleData?.permissions.map((permission) => {
      return <Switch key={permission.id} label={permission.name} checked />;
    });
  }, [currentRoleData]);

  if (error) return <LoadFailed />;
  if (!currentRoleData) return <ScreenLoader />;

  return (
    <>
      <CreateGroupFormsStyles.SectionHalfWidth>
        <CreateGroupFormsStyles.GridWrapper>
          <Text>{t('ManageRoles:Role Name')}:</Text>
          <Text>{currentRoleData?.role.name}</Text>

          <Text>{t('ManageRoles:Description')}:</Text>
          <Text>{currentRoleData?.role.description}</Text>
        </CreateGroupFormsStyles.GridWrapper>
      </CreateGroupFormsStyles.SectionHalfWidth>
      <CreateGroupFormsStyles.SectionConfirmation>
        <CreateGroupFormsStyles.SectionTitle>
          {t('ManageRoles:Role Permissions')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.SwitchesSummary>
          {renderSwitches}
        </CreateGroupFormsStyles.SwitchesSummary>
      </CreateGroupFormsStyles.SectionConfirmation>
    </>
  );
};

export { ViewRoleDetails };
