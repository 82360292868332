import { addDays } from 'date-fns';
import { atom, useAtom } from 'jotai';
import React, { FC, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Button } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { ClickAwayListener } from 'components/atoms/ClickAwayListener';

import { formatDate } from 'lib/dates';

import { IStupidlyOvercomplicatedDateSelect } from './StupidlyOvercomplicatedDateSelect.interface';
import { StupidlyOvercomplicatedDateSelectStyles } from './StupidlyOvercomplicatedDateSelect.styles';

enum modesEnum {
  all = 'all',
  date = 'date',
  date_range = 'date_range',
  month_range = 'month_range',
  year_range = 'year_range',
  predefined_range = 'predefined_range',
}

const optionsLiteral = {
  [modesEnum.all]: 'All',
  [modesEnum.date]: 'Date',
  [modesEnum.date_range]: 'Date Range',
  [modesEnum.month_range]: 'Month Range',
  [modesEnum.year_range]: 'Year Range',
  [modesEnum.predefined_range]: 'Predefined Ranges',
};

enum rangeOptionsEnum {
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  last_90_days = 'last_90_days',
}

const rangeOptionsLiteral = {
  [rangeOptionsEnum.last_7_days]: 'Last 7 Days',
  [rangeOptionsEnum.last_30_days]: 'Last 30 Days',
  [rangeOptionsEnum.last_90_days]: 'Last 90 Days',
};

export const startDateAtom = atom<Date | null>(new Date());
export const endDateAtom = atom<Date | null>(new Date());
export const singleDateAtom = atom<Date | null>(new Date());
export const selectModeAtom = atom(modesEnum.all);

export const useDateRange = () => {
  const [selectMode] = useAtom(selectModeAtom);

  const [startDate] = useAtom(startDateAtom);
  const [endDate] = useAtom(endDateAtom);
  const [singleDate] = useAtom(singleDateAtom);

  const tmplt = 'YYYY-MM-DD';

  if (selectMode === modesEnum.all) {
    return '';
  }

  return selectMode === modesEnum.date
    ? `start_date=${formatDate(singleDate!, tmplt)}&end_date=${formatDate(
        singleDate!,
        tmplt
      )}`
    : `start_date=${formatDate(startDate!, tmplt)}&end_date=${formatDate(
        endDate!,
        tmplt
      )}`;
};

const StupidlyOvercomplicatedDateSelect: FC<
  IStupidlyOvercomplicatedDateSelect
> = () => {
  const [open, setOpen] = useState(false);
  const [selectMode, setSelectMode] = useAtom(selectModeAtom);

  const [startDate, setStartDate] = useAtom(startDateAtom);
  const [endDate, setEndDate] = useAtom(endDateAtom);
  const [singleDate, setSingleDate] = useAtom(singleDateAtom);

  const onSelectDate = useCallback(
    (date: Date) => {
      setSingleDate(date);
      setOpen(false);
    },
    [setSingleDate]
  );

  const onSelectDateRange = useCallback(
    (date: [Date | null, Date | null]) => {
      setStartDate(date[0]);
      setEndDate(date[1]);
      if (date[0] && date[1]) {
        setOpen(false);
      }
    },
    [setStartDate, setEndDate]
  );

  const getPickerProps = useMemo(() => {
    switch (selectMode) {
      case modesEnum.all:
      case modesEnum.predefined_range:
        return null;
      case modesEnum.date:
        return {
          selected: singleDate,
          onChange: onSelectDate,
        };
      case modesEnum.date_range:
        return {
          startDate,
          endDate,
          onChange: onSelectDateRange,
          selectsRange: true,
        };
      case modesEnum.month_range:
        return {
          startDate,
          endDate,
          onChange: onSelectDateRange,
          selectsRange: true,
          showMonthYearPicker: true,
          dateFormat: 'MM/yyyy',
        };
      case modesEnum.year_range:
        return {
          startDate,
          endDate,
          onChange: onSelectDateRange,
          selectsRange: true,
          showYearPicker: true,
          dateFormat: 'yyyy',
        };
    }
  }, [
    selectMode,
    startDate,
    endDate,
    onSelectDate,
    onSelectDateRange,
    singleDate,
  ]);

  const renderOptions = useMemo(() => {
    return Object.keys(modesEnum).map((mode) => {
      return (
        <StupidlyOvercomplicatedDateSelectStyles.Option
          key={mode}
          onClick={() => setSelectMode(mode as modesEnum)}
          active={mode === selectMode}
        >
          {optionsLiteral[mode as modesEnum]}
        </StupidlyOvercomplicatedDateSelectStyles.Option>
      );
    });
  }, [selectMode, setSelectMode]);

  const renderLabel = useMemo(() => {
    switch (selectMode) {
      case modesEnum.all:
        return 'Everything';
      case modesEnum.date:
        return formatDate(singleDate!);
      default:
        return `${formatDate(startDate!)} - ${formatDate(endDate!)}`;
    }
  }, [selectMode, startDate, endDate, singleDate]);

  const getRangeDates = useCallback((range: rangeOptionsEnum) => {
    const now = new Date();
    switch (range) {
      case rangeOptionsEnum.last_7_days:
        return [addDays(now, -7), now];
      case rangeOptionsEnum.last_30_days:
        return [addDays(now, -30), now];
      case rangeOptionsEnum.last_90_days:
        return [addDays(now, -90), now];
    }
  }, []);

  const renderPredefinedRanges = useMemo(() => {
    return Object.keys(rangeOptionsEnum).map((range) => {
      return (
        <Chip
          key={range}
          title={rangeOptionsLiteral[range as rangeOptionsEnum]}
          onClick={() => {
            setSelectMode(modesEnum.date_range);
            onSelectDateRange(
              getRangeDates(range as rangeOptionsEnum) as [
                Date | null,
                Date | null
              ]
            );
          }}
        />
      );
    });
  }, [getRangeDates, onSelectDateRange, setSelectMode]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <StupidlyOvercomplicatedDateSelectStyles.Wrapper>
        <Button onClick={() => setOpen(true)}>Showing: {renderLabel}</Button>
        {open && (
          <StupidlyOvercomplicatedDateSelectStyles.PickerWrapper
            showsAll={selectMode === modesEnum.all}
          >
            <StupidlyOvercomplicatedDateSelectStyles.OptionsContainer>
              {renderOptions}
            </StupidlyOvercomplicatedDateSelectStyles.OptionsContainer>
            <StupidlyOvercomplicatedDateSelectStyles.StyledDatePicker>
              {getPickerProps ? (
                <DatePicker inline {...getPickerProps} />
              ) : null}
              {selectMode === modesEnum.predefined_range && (
                <StupidlyOvercomplicatedDateSelectStyles.PredefinedRangesWrapper>
                  {renderPredefinedRanges}
                </StupidlyOvercomplicatedDateSelectStyles.PredefinedRangesWrapper>
              )}
            </StupidlyOvercomplicatedDateSelectStyles.StyledDatePicker>
          </StupidlyOvercomplicatedDateSelectStyles.PickerWrapper>
        )}
      </StupidlyOvercomplicatedDateSelectStyles.Wrapper>
    </ClickAwayListener>
  );
};

export { StupidlyOvercomplicatedDateSelect };
