import axios from 'axios';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonFeedback,
  InlineFeedback,
  Switch,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';

import Api from 'lib/api';

import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

import { IGatherInfoModal } from './GatherInfoModal.interface';
import { GatherInfoModalStyles } from './GatherInfoModal.styles';

const FAIL_AFTER = 120000;

const GatherInfoModal: FC<IGatherInfoModal> = ({
  opened,
  onClose,
  userId,
  email,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [txId, setTxId] = useState('');

  const [shouldCollectPhoto, setShouldCollectPhoto] = useState(true);

  const [onCancel, setOnCancel] = useState<any>();

  const { user, additionalDetails } = useUser();
  const { email: adminEmail, api_token } = user || {};
  const { organization_webhook_gather_info } = additionalDetails || {};

  const handleErrorRemove = useCallback(() => {
    setError(false);
    setErrorMessage('');
  }, []);

  const onGatherInfo = useCallback(async () => {
    setLoading(true);
    const cancelToken = axios.CancelToken.source();

    try {
      await new Promise(async (resolve, reject) => {
        setOnCancel(() => () => {
          cancelToken.cancel();
          handleErrorRemove();
          reject('');
        });
        if (!adminEmail || !api_token) {
          createFeedback({
            variant: 'error',
            heading: 'Admin cannot perform this action.',
          });
          reject('');
        }
        const reqData = {
          email,
          admin_email: adminEmail!,
          token: api_token!,
          ticketName: shouldCollectPhoto
            ? 'IDRequestPersonalDataAndPhoto'
            : 'Ticket Name',
          ticketTitle: shouldCollectPhoto
            ? 'IDRequestPersonalDataAndPhoto'
            : 'Ticket Title',
          ticketDetails: shouldCollectPhoto
            ? 'IDRequestPersonalDataAndPhoto'
            : 'Ticket Details',
          callback: organization_webhook_gather_info,
        };
        try {
          const res = await Api.gatherUserInfo(userId, reqData, {
            cancelToken: cancelToken.token,
          });
          const { TransactionId } = res.data;

          setTxId(TransactionId);

          if (res.status === 400) {
            throw new Error('not_found');
          }

          let currentTime = 0;
          const poll = setInterval(async () => {
            setOnCancel(() => () => {
              clearInterval(poll);
              handleErrorRemove();
              cancelToken.cancel();
              reject('');
            });
            try {
              if (currentTime >= FAIL_AFTER) {
                clearInterval(poll);
                throw new Error('timeout');
              }
              const res = await Api.getCheckIdStatus(TransactionId, {
                cancelToken: cancelToken.token,
              });
              currentTime += 6000;
              if (res.data.isAuthenticated === true) {
                clearInterval(poll);
                resolve(true);
              }
            } catch (e: any) {
              console.log(e);
              clearInterval(poll);
              setError(true);
              reject(e);
            }
          }, 3000);
        } catch (e: any) {
          if (e.message === 'not_found') {
            setErrorMessage('User not recognized.');
            setError(true);
          }
          reject(e);
          return;
        }
      });
      setSuccess(true);
    } catch (err: any) {
      if (typeof err === 'string') {
        return;
      }
      setError(true);
      setErrorMessage(
        "Our system was not able to gather this user's information. Please try again later."
      );
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }, [
    email,
    handleErrorRemove,
    adminEmail,
    api_token,
    userId,
    shouldCollectPhoto,
    organization_webhook_gather_info,
  ]);

  const getFeedback = useMemo(() => {
    if (loading) {
      return {
        heading: t('Users:Collecting User Info.') + ' Transaction id: ' + txId,
        variant: 'warning' as const,
      };
    }
    if (success) {
      return {
        heading: t('Users:User info has been successfully collected.'),
        variant: 'success' as const,
      };
    }
    if (error) {
      return {
        heading: errorMessage,
        variant: 'error' as const,
      };
    }
    return {
      heading: t('Users:Collect information from the user'),
      variant: 'info' as const,
      expandable: true,
      defaultExpanded: true,
      children: (
        <ul>
          <li>{t('Users:First and Last name')}</li>
          <li>{t('Users:Date of Birth')}</li>
          <li>{t('Users:Customer Location')}</li>
          {shouldCollectPhoto && <li>{t('Users:Photo')}</li>}
        </ul>
      ),
    };
  }, [loading, success, error, errorMessage, t, txId, shouldCollectPhoto]);

  const getContent = useMemo(() => {
    if (!!organization_webhook_gather_info) {
      return (
        <>
          {' '}
          <InlineFeedback {...getFeedback} />
          <Box
            css={{
              mt: '$4',
            }}
          >
            <Switch
              name="requestId"
              label={t('Users:Request ID Document Photo')}
              id="requestId"
              defaultChecked={shouldCollectPhoto}
              onChange={() => setShouldCollectPhoto(!shouldCollectPhoto)}
            />
          </Box>
          <ButtonFeedback
            onClick={onGatherInfo}
            isLoading={loading}
            css={{
              mt: '$4',
            }}
          >
            {t('Users:Collect User Info')}
          </ButtonFeedback>
          {loading && (
            <Button
              variant="secondary"
              onClick={onCancel}
              fullWidth
              css={{ mt: '$4' }}
            >
              Cancel
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        <Text bold>
          {t('Users:Set your webhook URL in order to use this feature')}
        </Text>
        <Button as={Link} to={routePaths.webhooks} fullWidth css={{ mt: '$4' }}>
          {t('Users:Set Webhook URL')}
        </Button>
      </>
    );
  }, [
    loading,
    onGatherInfo,
    onCancel,
    t,
    organization_webhook_gather_info,
    getFeedback,
    shouldCollectPhoto,
  ]);

  return (
    <Modal
      maxWidth={555}
      opened={opened}
      onClose={onClose}
      position="centered"
      title={t('Users:Collect User Info')}
    >
      <GatherInfoModalStyles.Wrapper>
        {getContent}
      </GatherInfoModalStyles.Wrapper>
    </Modal>
  );
};

export { GatherInfoModal };
