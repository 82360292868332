import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useSwr from 'swr';

import { theme } from '@resiliantinc/design-system';

import { ChartWrapper } from 'components/atoms/ChartWrapper';
import { ChartLoading } from 'components/atoms/ChartWrapper/ChartLoading';
import { ChartNoData } from 'components/atoms/ChartWrapper/ChartNoData';
import { useDateRange } from 'components/organisms/StupidlyOvercomplicatedDateSelect';

import Api from 'lib/api';
import { CHART_DEFAULTS } from 'lib/charts';
import { IChartApiResponse } from 'lib/types';

const UserEnrolledChart = () => {
  const { t } = useTranslation();

  const dateRange = useDateRange();

  const { data } = useSwr<IChartApiResponse>(
    Api.getLineChartData('userLinkedToOrg', dateRange)
  );

  const formatTooltip = useCallback(
    (value: number) => [t('Dashboard:users'), value],
    [t]
  );

  const getChart = useMemo(() => {
    if (!data) return <ChartLoading />;
    if (!data.data.length) return <ChartNoData />;
    return (
      <AreaChart data={data?.data} margin={CHART_DEFAULTS.margin}>
        <CartesianGrid strokeDasharray={CHART_DEFAULTS.strokeDasharray} />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip
          formatter={formatTooltip as any}
          separator={CHART_DEFAULTS.tooltip.separator}
        />
        <Area
          dataKey="number"
          stroke={theme.colors.sky600.value}
          strokeWidth={CHART_DEFAULTS.strokeWidth}
          fill={theme.colors.sky600.value}
          fillOpacity={CHART_DEFAULTS.fillOpacity}
        />
      </AreaChart>
    );
  }, [data, formatTooltip]);

  return (
    <ChartWrapper title={t('Dashboard:User Enrolled')}>
      <ResponsiveContainer height={300}>{getChart}</ResponsiveContainer>
    </ChartWrapper>
  );
};

export { UserEnrolledChart };
