import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    keySeparator: ':',
    ns: [
      // Screens
      'Dashboard',
      'Signup',
      'MainMenu',
      'Groups',
      'Organizations',
      'Breadcrumb',
      'Users',
      'Administrators',
      'Roles',
      'Dropzone',
      'ManageRoles',
      'Account',
      'Optout',
      'VerifyEmailChange',
      'ConfirmAdminAccount',
      'Activity',
      'Developer',
    ],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
