import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { ChipHolder } from 'components/atoms/ChipHolder';

import { fromNow } from 'lib/dates';
import { IAdministratorParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IAdministratorParsed>();
export const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('lastSeen', {
    header: 'Last Seen',
    cell: (value) => {
      const lastSeen = value.getValue();
      return fromNow(lastSeen) || 'Never';
    },
  }),
  columnHelper.accessor('groups', {
    header: 'Groups',
    cell: (value) => {
      const groups = value.getValue();
      const chips = groups?.map((group) => (
        <Chip
          title={group.name}
          link={generatePath(routePaths.groupDetails, { id: group.id })}
        />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),
  columnHelper.accessor('roles', {
    header: 'Roles',
    cell: (value) => {
      const roles = value.getValue();
      const chips = roles?.map((role) => (
        <Chip
          title={role.name}
          link={generatePath(routePaths.viewRole, { id: role.id })}
        />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.administratorDetails, {
          id: value.getValue(),
        })}
      >
        View
      </Button>
    ),
  }),
];

export const simpleColumns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.userDetails, { id: value.getValue() })}
      >
        View
      </Button>
    ),
  }),
];
