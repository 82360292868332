import { css, styled } from '@resiliantinc/design-system';

import { MENUS_COLLAPSED_SIZE } from 'components/organisms/MainMenu/Nav.styled';

export const screenMaxWidthCentered = css({
  maxWidth: '170rem',
  margin: '0 auto',
});

export const FullWidthHeightScroll = styled('div', {
  inset: 0,
  position: 'absolute',
  overflow: 'auto',
});
export const PublicLayoutWrapper = styled('div', {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const PublicLayoutWrapperInner = styled('div', {
  mb: 'auto',
});

export const LayoutWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
});

export const LayoutWrapperInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  '@bp2': {
    flexDirection: 'row',
  },
});

export const HeaderFooterWrapperOuter = styled('div', {
  width: '100%',
  bc: '$slate900',
  borderBottom: '1rem solid $accent',
  color: '$white',
  variants: {
    noBorder: {
      true: {
        border: 'none',
      },
    },
  },
});

export const HeaderFooterWrapperInner = styled('div', {
  maxWidth: 1340,
  w: '100%',
  m: 'auto',
  p: '2rem',
});

export const FlexSpaceBetween = styled('div', {
  width: '100%',
  display: 'flex',
  ai: 'center',
  jc: 'space-between',
  height: '100%',
  variants: {
    fixedHeight: {
      true: {
        height: MENUS_COLLAPSED_SIZE,
      },
    },
  },
});

export const Logo = styled('img', {
  height: '2.4rem',
  display: 'block',
  pl: '2rem',
});

export const CenteredContentWrapper = styled('div', {
  maxWidth: '55rem',
  width: '100%',
  m: 'auto',
  my: '$8',
  px: '$4',
});

export const FlexCenterColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  jc: 'center',
  ai: 'center',
  height: '100%',
});
