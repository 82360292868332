import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';

import { ClickAwayListener } from 'components/atoms/ClickAwayListener';

import { IMiniSelect } from './MiniSelect.interface';
import {
  OptionsDropdown,
  SelectOption,
  SelectWrapper,
  SelectedOption,
} from './MiniSelect.styles';

const MiniSelect: FC<IMiniSelect> = ({ options, defaultOption, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [open, setOpen] = useState(false);

  const onOptionClick = useCallback(
    (value: number) => () => {
      setSelectedOption(value);
      onChange(value);
      setOpen(false);
    },
    [onChange]
  );

  const renderOptions = useMemo(() => {
    return options.map((option) => {
      return (
        <SelectOption onClick={onOptionClick(option)}>{option}</SelectOption>
      );
    });
  }, [options, onOptionClick]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectWrapper>
        <SelectedOption onClick={() => setOpen(!open)}>
          {selectedOption}
          <ChevronDown size="1.4rem" />
        </SelectedOption>

        <AnimatePresence>
          {open && (
            <OptionsDropdown
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              {renderOptions}
            </OptionsDropdown>
          )}
        </AnimatePresence>
      </SelectWrapper>
    </ClickAwayListener>
  );
};

export { MiniSelect };
