import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSwr from 'swr';

import {
  Button,
  ButtonFeedback,
  Input,
  Switch,
  createFeedback,
} from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';

import Api, { processApiError } from 'lib/api';
import { IPermissionsResponse } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import { routePaths } from 'router/routePaths';

interface ICreateRoleForm {
  name: string;
  description: string;
  permissions: {
    [key: string]: boolean;
  };
}
const CreateRoleForm = () => {
  const { t } = useTranslation();

  const { data: permissionsData, error: permissionsError } =
    useSwr<IPermissionsResponse>(Api.getPermissions);

  const { permissions: currentAdminPermissions } = useUser();

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<ICreateRoleForm>();

  const navigate = useNavigate();

  const renderSwitches = useMemo(() => {
    return permissionsData?.permissions
      .filter((permission) => currentAdminPermissions?.includes(permission.key))
      .map((permission) => {
        return (
          <Switch
            key={permission.id}
            label={permission.name}
            {...register(`permissions.${permission.id}`)}
          />
        );
      });
  }, [permissionsData, register, currentAdminPermissions]);

  const onSubmit = useCallback(
    async (data: ICreateRoleForm) => {
      const { name, description, permissions } = data;
      setLoading(true);
      try {
        const dataToSend = {
          name,
          description,
          permissions: Object.keys(permissions)
            .filter((key) => permissions[key])
            .map((key) => +key),
        };
        await Api.createRole(dataToSend);
        createFeedback({
          variant: 'success',
          heading: t('ManageRoles:Role created successfully'),
        });
        navigate(routePaths.manageRoles);
      } catch (error) {
        const messages = processApiError(error);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [navigate, t]
  );

  if (permissionsError) return <LoadFailed />;
  if (!permissionsData) return <ScreenLoader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CreateGroupFormsStyles.SectionHalfWidth>
        <Input
          label={t('ManageRoles:Role Name:')}
          {...register('name')}
          placeholder={t('ManageRoles:Role Administrator')}
        />
        <Input
          {...register('description')}
          as="textarea"
          rows={10}
          css={{ resize: 'vertical' }}
          label={t('ManageRoles:Description')}
          placeholder={t('ManageRoles:Change roles')}
          required
        />
      </CreateGroupFormsStyles.SectionHalfWidth>
      <CreateGroupFormsStyles.SectionConfirmation>
        <CreateGroupFormsStyles.SectionTitle>
          {t('ManageRoles:Role Permissions')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.SwitchesSectionWrapper>
          {renderSwitches}
        </CreateGroupFormsStyles.SwitchesSectionWrapper>
      </CreateGroupFormsStyles.SectionConfirmation>
      <CreateGroupFormsStyles.NextButton>
        <Button type="button" onClick={() => navigate(-1)} variant="outlined">
          {t('ManageRoles:Cancel')}
        </Button>
        <ButtonFeedback isLoading={loading}>
          {t('ManageRoles:Create Role')}
        </ButtonFeedback>
      </CreateGroupFormsStyles.NextButton>
    </form>
  );
};

export { CreateRoleForm };
