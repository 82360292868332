export const CHART_DEFAULTS = {
  margin: {
    left: -30,
  },
  strokeDasharray: '3 3',
  tooltip: {
    separator: ' ',
  },
  strokeWidth: 4,
  fillOpacity: 0.2,
};
