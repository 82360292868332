import { FC } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { CreateGroupForms } from 'components/organisms/CreateGroupForms';

import { ProtectedRoute } from 'lib/ProtectedRoute';

import { BaseRouterProvider } from 'providers/BaseRouterProvider';
import { GuestRoutesProvider } from 'providers/GuestRoutesProvider';

import { breadcrumbs } from 'router/breadcrumbs';

import { Account } from 'screens/Account';
import { ActivityDetails } from 'screens/ActivityDetails';
import { AdministratorDetails } from 'screens/AdministratorDetails';
import { Administrators } from 'screens/Administrators';
import { AnimatedOutletScreen } from 'screens/AnimatedOutletScreen';
import { ApiKeys } from 'screens/ApiKeys';
import { BulkInviteUsers } from 'screens/BulkInviteUsers';
import { ConfirmAdminAccount } from 'screens/ConfirmAdminAccount';
import { CreateAdministrator } from 'screens/CreateAdministrators';
import { CreateGroup } from 'screens/CreateGroup';
import { CreateRole } from 'screens/CreateRole';
import { Dashboard } from 'screens/Dashboard';
import { EditRole } from 'screens/EditRole';
import { GroupAdministrators } from 'screens/GroupAdministrators';
import { GroupDetails } from 'screens/GroupDetails';
import { GroupUsers } from 'screens/GroupUsers';
import { Groups } from 'screens/Groups';
import { Login } from 'screens/Login';
import { Logout } from 'screens/Logout';
import { ManageRoles } from 'screens/ManageRoles';
import { MyOrg } from 'screens/MyOrg';
import { Optout } from 'screens/Optout';
import { OrgBranding } from 'screens/OrgBranding';
import { OrgUsers } from 'screens/OrgUsers';
import { OrganizationDetails } from 'screens/OrganizationDetails';
import { Organizations } from 'screens/Organizations';
import { QuickInviteUser } from 'screens/QuickInviteUser';
import { Signup } from 'screens/Signup';
import { SignupSuccess } from 'screens/SignupSuccess';
import { SubAdmins } from 'screens/SubAdmins';
import { SubUsers } from 'screens/SubUsers';
import { UserAcceptInvite } from 'screens/UserAcceptInvite';
import { UserDetails } from 'screens/UserDetails';
import { Users } from 'screens/Users';
import { UsersStatus } from 'screens/UsersStatus';
import { VerifyEmailChange } from 'screens/VerifyEmailChange';
import { ViewPlan } from 'screens/ViewPlan';
import { ViewRole } from 'screens/ViewRole';
import { Webhooks } from 'screens/Webhooks';

import { routePaths } from './routePaths';

interface IRouteObject extends RouteObject {
  breadcrumb?: FC;
  children?: IRouteObject[];
}

export const appRoutes: IRouteObject[] = [
  {
    path: '*',
    element: <Navigate to={routePaths.base} replace />,
  },

  // Guest routes (unprotected)
  {
    path: routePaths.base,
    element: (
      <GuestRoutesProvider>
        <Outlet />
      </GuestRoutesProvider>
    ),
    children: [
      {
        path: routePaths.login,
        element: <Login />,
      },
      {
        path: routePaths.signup,
        element: <Signup />,
      },
      {
        path: routePaths.signupSuccess,
        element: <SignupSuccess />,
      },
      {
        path: routePaths.optout,
        element: <Optout />,
      },
      {
        path: routePaths.verifyEmailChange,
        element: <VerifyEmailChange />,
      },
      {
        path: routePaths.confirmAdminAccount,
        element: <ConfirmAdminAccount />,
      },
      {
        path: routePaths.userAcceptInvite,
        element: <UserAcceptInvite />,
      },
    ],
  },

  // Protected routes
  {
    path: routePaths.base,
    element: <ProtectedRoute component={BaseRouterProvider} />,
    children: [
      {
        index: true,
        element: <Navigate to={routePaths.dashboard} />,
      },
      {
        path: routePaths.dashboard,
        element: <Dashboard />,
      },
      {
        path: routePaths.activityDetails,
        element: <ActivityDetails />,
      },
      {
        path: routePaths.developer,
        element: <AnimatedOutletScreen />,
        children: [
          {
            path: routePaths.developer,
            element: <ApiKeys />,
          },
          {
            path: routePaths.webhooks,
            element: <Webhooks />,
          },
        ],
      },
      {
        path: routePaths.account,
        element: <AnimatedOutletScreen />,
        children: [
          {
            path: routePaths.account,
            element: <Account />,
          },
          {
            path: routePaths.viewPlan,
            element: <ViewPlan />,
          },
          {
            path: routePaths.logout,
            element: <Logout />,
          },
          {
            path: routePaths.orgBranding,
            element: <OrgBranding />,
          },
          {
            path: routePaths.myOrganization,
            element: <MyOrg />,
          },
        ],
      },
      {
        path: routePaths.groups,
        element: <AnimatedOutletScreen />,
        breadcrumb: breadcrumbs[routePaths.groups],

        children: [
          {
            path: routePaths.groups,
            element: <Groups />,
            breadcrumb: breadcrumbs[routePaths.groups],
          },
          {
            path: routePaths.groupDetails,
            breadcrumb: breadcrumbs[routePaths.groupDetails],
            element: <GroupDetails />,
          },
          {
            path: routePaths.groupUsers,
            breadcrumb: breadcrumbs[routePaths.groupUsers],
            element: <GroupUsers />,
          },
          {
            path: routePaths.groupAdmins,
            breadcrumb: breadcrumbs[routePaths.groupAdmins],
            element: <GroupAdministrators />,
          },
          {
            path: routePaths.createGroup,
            breadcrumb: breadcrumbs[routePaths.createGroup],
            element: <CreateGroup />,
            children: [
              {
                path: routePaths.createGroup,
                element: <Navigate to={routePaths.createGroupGeneral} />,
              },
              {
                path: routePaths.createGroupGeneral,
                breadcrumb: breadcrumbs[routePaths.createGroup],
                element: <CreateGroupForms.General />,
              },
              {
                path: routePaths.createGroupUsers,
                breadcrumb: breadcrumbs[routePaths.createGroup],
                element: <CreateGroupForms.Users />,
              },
              {
                path: routePaths.createGroupAdmins,
                breadcrumb: breadcrumbs[routePaths.createGroup],
                element: <CreateGroupForms.Admins />,
              },
              {
                path: routePaths.createGroupConfirmation,
                breadcrumb: breadcrumbs[routePaths.createGroup],
                element: <CreateGroupForms.Confirmation />,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.organizations,
        element: <AnimatedOutletScreen />,
        breadcrumb: breadcrumbs[routePaths.organizations],
        children: [
          {
            path: routePaths.organizations,
            element: <Organizations />,
            breadcrumb: breadcrumbs[routePaths.organizations],
          },
          {
            path: routePaths.organizationDetails,
            element: <OrganizationDetails />,
            breadcrumb: breadcrumbs[routePaths.organizationDetails],
          },
          {
            path: routePaths.orgUsers,
            element: <OrgUsers />,
            breadcrumb: breadcrumbs[routePaths.orgUsers],
          },
        ],
      },
      {
        path: routePaths.users,
        element: <AnimatedOutletScreen />,
        breadcrumb: breadcrumbs[routePaths.users],
        children: [
          {
            path: routePaths.users,
            element: <Users />,
            breadcrumb: breadcrumbs[routePaths.users],
          },
          {
            path: routePaths.userDetails,
            element: <UserDetails />,
            breadcrumb: breadcrumbs[routePaths.userDetails],
          },
          {
            path: routePaths.quickInviteUser,
            element: <QuickInviteUser />,
            breadcrumb: breadcrumbs[routePaths.quickInviteUser],
          },
          {
            path: routePaths.bulkInviteUsers,
            element: <BulkInviteUsers />,
            breadcrumb: breadcrumbs[routePaths.bulkInviteUsers],
          },
          {
            path: routePaths.usersStatus,
            element: <UsersStatus />,
          },
        ],
      },
      {
        path: routePaths.administrators,
        element: <AnimatedOutletScreen />,
        breadcrumb: breadcrumbs[routePaths.administrators],
        children: [
          {
            path: routePaths.administrators,
            element: <Administrators />,
            breadcrumb: breadcrumbs[routePaths.administrators],
          },
          {
            path: routePaths.administratorDetails,
            element: <AdministratorDetails />,
            breadcrumb: breadcrumbs[routePaths.administratorDetails],
          },
          {
            path: routePaths.createAdministrator,
            element: <CreateAdministrator />,
            breadcrumb: breadcrumbs[routePaths.createAdministrator],
          },
          {
            path: routePaths.subUsers,
            element: <SubUsers />,
            breadcrumb: breadcrumbs[routePaths.subUsers],
          },
          {
            path: routePaths.subAdministrators,
            element: <SubAdmins />,
            breadcrumb: breadcrumbs[routePaths.subAdministrators],
          },
        ],
      },
      {
        path: routePaths.manageRoles,
        element: <AnimatedOutletScreen />,
        breadcrumb: breadcrumbs[routePaths.manageRoles],
        children: [
          {
            path: routePaths.manageRoles,
            element: <ManageRoles />,
            breadcrumb: breadcrumbs[routePaths.manageRoles],
          },
          {
            path: routePaths.viewRole,
            element: <ViewRole />,
            breadcrumb: breadcrumbs[routePaths.viewRole],
          },
          {
            path: routePaths.editRole,
            element: <EditRole />,
            breadcrumb: breadcrumbs[routePaths.editRole],
          },
          {
            path: routePaths.createRole,
            element: <CreateRole />,
            breadcrumb: breadcrumbs[routePaths.createRole],
          },
        ],
      },
    ],
  },
];
