import Cookies from 'js-cookie';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonFeedback,
  InlineFeedback,
  Input,
  Title,
} from '@resiliantinc/design-system';

import { CenteredContentWrapper } from 'components/Layout/ScreenLayout';

import api from 'lib/api';

import { routePaths } from 'router/routePaths';

const FAIL_AFTER = 120000;

const LoginFrom = () => {
  const { register, handleSubmit, formState } = useForm<{
    email: string;
    password: string;
  }>({
    shouldUseNativeValidation: true,
  });

  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleErrorRemove = useCallback(() => {
    setError(false);
    setErrorMessage('');
    setSuccess(false);
  }, []);

  const onSubmit = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      handleErrorRemove();
      try {
        const {
          data: { Token: token },
        } = (await api.login(email, password)) as any;

        await new Promise((resolve, reject) => {
          let currentTime = 0;
          const poll = setInterval(async () => {
            try {
              if (currentTime >= FAIL_AFTER) {
                clearInterval(poll);
                throw new Error('timeout');
              }
              const res = await api.getTFAStatus(email, token);
              currentTime += 3000;
              if (res.data.identification === true) {
                clearInterval(poll);
                resolve(true);
              }
            } catch (e: any) {
              clearInterval(poll);
              setError(true);
              setErrorMessage(
                e.message === 'timeout'
                  ? 'Time to verify your identity has expired, please try again.'
                  : ''
              );
              reject(e);
            }
          }, 3000);
        });

        const {
          data: { access_token },
        } = await api.getOAuthToken(email, password);

        Cookies.set('access-token', access_token);
        setSuccess(true);
        setTimeout(() => navigate(routePaths.dashboard), 2000);
      } catch (e) {
        setError(true);
      }
    },

    [navigate, handleErrorRemove]
  );

  return (
    <CenteredContentWrapper>
      <Title css={{ pb: '$4' }}>Sign In</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="Email" {...register('email')} type="email" required />
        <Input
          label="Password"
          {...register('password')}
          type="password"
          required
        />
        {error ? (
          <Box
            css={{
              mb: '$4',
            }}
          >
            <InlineFeedback
              heading={errorMessage || 'Something went wrong with your login'}
              variant="error"
            />
          </Box>
        ) : null}
        <ButtonFeedback
          fullWidth
          isLoading={formState.isSubmitting}
          isSuccess={!error && success}
        >
          Sign In
        </ButtonFeedback>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '1.6rem',
          }}
        >
          <Button as={RouterLink} to="/signup" variant="nude" fullWidth>
            Sign up instead
          </Button>
        </div>
      </form>
    </CenteredContentWrapper>
  );
};

export { LoginFrom };
