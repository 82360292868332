import { styled } from '@resiliantinc/design-system';

export const GroupPropertiesModalStyles = {
  Content: styled('div', {
    px: '$5',
    pt: '$2',
    pb: '$6',
  }),
  SwitchWrapper: styled('div', {
    py: '$6',
    '&:not(:last-child)': {
      borderBottom: '1px solid $border',
    },
  }),
  DeleteButton: styled('div', {
    mt: '$9',
  }),
};
