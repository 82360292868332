import { createContext } from 'react';

import { IUserContext } from './UserContext.interface';

export const UserContext = createContext<IUserContext>({
  isAuthenticated: false,
  user: undefined,
  stats: undefined,
  permissions: undefined,
  additionalDetails: undefined,
  updateUser: async () => {
    //noop
  },
});
