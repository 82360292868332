import { IGroupsResponse } from 'lib/types';

export interface IGroupParsed {
  id: number;
  name: string;
  owner: string;
  userCount: number;
  administratorCount: number;
}

export const groupsTableParser = (data: IGroupsResponse): IGroupParsed[] => {
  const { groups, groupsStats } = data;
  return groups.map((group) => {
    const { id, name } = group;
    const { userCount, administratorCount, ownerName } = groupsStats[id];
    return {
      id,
      name,
      owner: ownerName,
      userCount,
      administratorCount,
    };
  });
};
