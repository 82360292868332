import { PropsWithChildren, useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import useSwr from 'swr';

import { FullScreenLoader } from 'components/atoms/FullScreenLoader';

import api from 'lib/api';
import { useAuth } from 'lib/auth';
import { IAdministrator, IAdministratorDetailsResponse } from 'lib/types';

import { UserContext } from './UserContext';

export const UserProvider = ({ children }: PropsWithChildren<any>) => {
  const { isAuthenticated } = useAuth();
  const { data: response, mutate } = useSwr<IAdministratorDetailsResponse>(
    isAuthenticated && api.getMe
  );

  const updateUser = useCallback(
    async (data: Partial<IAdministrator>) => {
      await api.updateMe(data);
      await mutate();
    },
    [mutate]
  );

  const value = useMemo(
    () => ({
      user: response?.administrator,
      permissions: response?.permissions,
      stats: response?.administratorStats,
      additionalDetails: response?.additionalDetails,
      isAuthenticated,
      updateUser,
    }),
    [response, isAuthenticated, updateUser]
  );

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (isAuthenticated && !response) {
    return <FullScreenLoader data-testid="loading" />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
