import { styled } from '@resiliantinc/design-system';

export const QuickInviteUserFormStyles = {
  Form: styled('form', {
    maxWidth: '50rem',
  }),
  SubmitButton: styled('div', {
    mt: '$6',
  }),
};
