import { FC, PropsWithChildren } from 'react';

import { MainMenu } from 'components/organisms/MainMenu';
import { TopMenu } from 'components/organisms/TopMenu';

import { useMainMenuConfig } from 'lib/MainMenuConfig';

import { LayoutContent } from './LayoutContent.styled';
import { LayoutWrapper, LayoutWrapperInner } from './ScreenLayout';

export const SidebarLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const mainMenuConfig = useMainMenuConfig();
  return (
    <LayoutWrapper>
      <TopMenu />
      <LayoutWrapperInner>
        <MainMenu config={mainMenuConfig} />
        <LayoutContent>{children}</LayoutContent>
      </LayoutWrapperInner>
    </LayoutWrapper>
  );
};
