import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, createFeedback } from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';
import { useUserSelect } from 'components/molecules/UserSearchInput/UserSearchInput.hooks';
import { IAddToGroupModal } from 'components/organisms/AddToGroupModal/AddToGroupModal.interface';
import { AddToGroupModalStyles } from 'components/organisms/AddToGroupModal/AddToGroupModal.styles';
import { useAdministrators } from 'components/organisms/GroupAdministratorsTable';

import { processApiError } from 'lib/api';
import Api from 'lib/api';

export const AddAdminToGroupModal: FC<IAddToGroupModal> = ({
  groupId,
  opened,
  onClose,
}) => {
  const { t } = useTranslation();

  const { mutate } = useAdministrators(groupId);

  const handleAddAdmin = useCallback(
    (adminId: number) => async () => {
      try {
        await Api.addAdminToGroup(groupId, adminId);
        await mutate();
        createFeedback({
          variant: 'success',
          heading: t('Users:Administrator added successfully'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      }
    },
    [groupId, mutate, t]
  );

  const { handleChange, renderAsTable } = useUserSelect(
    Api.searchAdministrators,
    'administrators',
    handleAddAdmin
  );

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="centered"
      maxWidth={700}
      title={t('Users: Add Administrator')}
    >
      <AddToGroupModalStyles.Content>
        <Input
          name="admin-search"
          type="search"
          onChange={handleChange}
          label={t('Users:Search for an Administrator')}
        />

        {renderAsTable}
      </AddToGroupModalStyles.Content>
    </Modal>
  );
};
