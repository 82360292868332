import { styled } from '@resiliantinc/design-system';

export const MenuItemText = styled('span', {
  fontSize: '$1',
  lineHeight: '1.6rem',
  mt: '$2',
  variants: {
    expanded: {
      true: {
        mt: 0,
        ml: '1.2rem',
        whiteSpace: 'nowrap',
      },
    },
  },
});
