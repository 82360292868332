import React, { FC, useMemo } from 'react';
import { Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import useSwr from 'swr';

import { Text } from '@resiliantinc/design-system';

import { ChartWrapper } from 'components/atoms/ChartWrapper';
import { ChartLoading } from 'components/atoms/ChartWrapper/ChartLoading';
import { ChartNoData } from 'components/atoms/ChartWrapper/ChartNoData';
import {
  ColorIndicator,
  LegendWrapper,
  labelClassName,
  labelNumberClassName,
} from 'components/molecules/DoughnutChart/DoughnutChart.styles';
import { useDateRange } from 'components/organisms/StupidlyOvercomplicatedDateSelect';

import Api from 'lib/api';
import { IChartApiResponse } from 'lib/types';

import { IDoughnutChart } from './DoughnutChart.interface';

const actuallyHasData = (data: any) => {
  return data?.some((item: any) => item.value > 0);
};

const DoughnutChart: FC<IDoughnutChart> = ({ label, apiType }) => {
  const renderLegend = (props: any) => {
    const { payload } = props;

    return payload.map((entry: any, index: number) => (
      <LegendWrapper key={`item-${index}`}>
        <ColorIndicator css={{ bc: entry.color }} />
        <Text css={{ fontSize: '$2', textTransform: 'capitalize' }}>
          {entry.value}
        </Text>
      </LegendWrapper>
    ));
  };

  const dateRange = useDateRange();

  const { data } = useSwr<IChartApiResponse>(
    Api.getDonutChartData(apiType, dateRange)
  );

  const getLabelNumber = useMemo(() => {
    if (data) {
      return data.data.reduce((acc: number, curr: any) => acc + curr.value, 0);
    }
    return 0;
  }, [data]);

  const getChart = useMemo(() => {
    if (!data) return <ChartLoading />;
    if (!actuallyHasData(data.data)) return <ChartNoData />;
    return (
      <PieChart>
        <Pie
          data={data?.data}
          dataKey="value"
          innerRadius={50}
          outerRadius={90}
          alignmentBaseline="before-edge"
        >
          <Label
            value={label}
            position="centerBottom"
            className={labelClassName()}
            dy={-5}
          />
          <Label
            value={getLabelNumber}
            position="centerTop"
            className={labelNumberClassName()}
            dy={5}
          />
        </Pie>
        <Legend
          content={renderLegend}
          layout="vertical"
          align="right"
          wrapperStyle={{ top: 10 }}
        />
      </PieChart>
    );
  }, [data, getLabelNumber, label]);

  return (
    <ChartWrapper title={label}>
      <ResponsiveContainer height={200}>{getChart}</ResponsiveContainer>
    </ChartWrapper>
  );
};

export { DoughnutChart };
