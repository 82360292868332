import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback } from 'react';
import { Portal } from 'react-portal';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { config } from '@resiliantinc/design-system';

import { ScrollStickyHeader } from 'components/atoms/ScrollStickyHeader';

import {
  IBaseModal,
  IModal,
  IPageModal,
  IPageModalWrapper,
} from './Modal.interface';
import {
  Backdrop,
  Content,
  ContentWrapper,
  Wrapper,
  animationVariantProps,
  backdropAnimationProps,
} from './Modal.styles';

const noop = () => {
  // noop
};

const BaseModal = ({
  onClose,
  children,
  title,
  position,
  maxWidth,
  headerExtraContent,
  header,
  allowClose = true,
  ...props
}: IBaseModal) => {
  const isDesktop = useMedia(config.media.bp2);
  const animProps =
    (animationVariantProps as any)[position] || animationVariantProps.default;
  const { desktop, mobile } = animProps;
  const contentAnimation = isDesktop ? desktop : mobile;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Portal>
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: document.body,
          allowOutsideClick: true,
        }}
      >
        <Wrapper>
          <Backdrop
            onClick={allowClose ? handleClose : noop}
            {...backdropAnimationProps}
          />
          <Content
            {...contentAnimation}
            position={position}
            css={{ '@bp2': { maxWidth } }}
            {...props}
          >
            <ContentWrapper>
              {header ? (
                header
              ) : (
                <ScrollStickyHeader
                  onClose={handleClose}
                  allowClose={allowClose}
                  title={title}
                  headerExtraContent={headerExtraContent}
                />
              )}
              {children}
            </ContentWrapper>
          </Content>
        </Wrapper>
      </FocusTrap>
    </Portal>
  );
};

export const Modal = ({ opened, ...props }: IModal) => (
  <AnimatePresence>{opened && <BaseModal {...props} />}</AnimatePresence>
);

export const PageModal = ({ children, goBackURL, ...props }: IPageModal) => {
  const navigate = useNavigate();

  const { onClose } = props;

  const handleClose = useCallback(() => {
    if (!goBackURL) {
      onClose?.();
      return;
    }
    navigate(goBackURL);
  }, [goBackURL, navigate, onClose]);

  return (
    <BaseModal {...props} onClose={handleClose}>
      {children}
    </BaseModal>
  );
};

export const PageModalWrapper = ({ children }: IPageModalWrapper) => (
  <AnimatePresence>{children}</AnimatePresence>
);
