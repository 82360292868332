import { styled } from '@resiliantinc/design-system';

export const CreateAdministratorFormStyles = {
  Form: styled('form', {
    maxWidth: '50rem',
  }),
  AssociatedUser: styled('div', {
    pt: '$6',
    borderTop: '1px solid $border',
    borderBottom: '1px solid $border',
  }),
  SubmitButton: styled('div', {
    mt: '$6',
  }),
};
