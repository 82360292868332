import { styled } from '@resiliantinc/design-system';

export const StepperStyles = {
  Wrapper: styled('div', {
    display: 'grid',
    gap: '$6',
  }),
  Item: styled('div', {
    all: 'unset',
    cursor: 'pointer',
    color: '$general500',
    fontWeight: '$bold',
    borderBottom: '4px solid $border',
    pb: '$1',
    pr: '$9',
    fontSize: '$2',

    [`&.active`]: {
      color: '$general300',
      borderColor: '$general100',
    },
    variants: {
      isLast: {
        true: {
          [`&.active`]: {
            color: '$lime600',
            borderColor: '$general400',
          },
        },
      },
    },
  }),
};
