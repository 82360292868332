import Skeleton from 'react-loading-skeleton';

import { Box } from '@resiliantinc/design-system';

export const TableLoading = () => {
  return (
    <Box>
      <Skeleton height={30} />
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton height={30} />
    </Box>
  );
};
