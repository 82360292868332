import { NavLink } from 'react-router-dom';

import { styled } from '@resiliantinc/design-system';

import { MENUS_COLLAPSED_SIZE } from './Nav.styled';

const baseStyles = {
  display: 'flex',
  textDecoration: 'none',
  ai: 'center',
  flexDirection: 'column',
  jc: 'center',
  color: '$white',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  height: MENUS_COLLAPSED_SIZE,
  [`&.active`]: {
    bc: '$primary',
  },
  [`&:hover:not(.active)`]: {
    color: '$primary',
  },
  '@bp2': {
    width: '100%',
  },
  variants: {
    isSubItem: {
      true: {
        height: '3.8rem',
        pl: '5.4rem',
      },
    },
    expanded: {
      true: {
        flexDirection: 'row',
        px: '2.8rem',
        jc: 'flex-start',
      },
    },
  },
};

export const MenuItemNavLink = styled(NavLink, baseStyles);

export const MenuItemNoLink = styled('div', baseStyles);

export const IconWrapper = styled('div', {
  flexShrink: 0,
  display: 'flex',
  ai: 'center',
});
