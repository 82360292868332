import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table, TableLoading } from 'components/organisms/Table';
import { columns } from 'components/organisms/UsersTable/UsersTable.coldef';

import Api from 'lib/api';
import { usersTableParser } from 'lib/parsers';
import { IUsersResponse } from 'lib/types';

export const OrgUsers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [page, setPage] = useState(1);

  const { data: users, error } = useSwr<IUsersResponse>(
    id && Api.getOrganizationUsers(+id, page)
  );

  const getTable = useMemo(() => {
    if (error) return <LoadFailed />;
    if (!users) return <TableLoading />;
    if (!users.users.length) return <TableNoData />;
    return (
      <Table
        columnDefinition={columns}
        data={usersTableParser(users)}
        paginator={{ ...users.paginator, setPage }}
      />
    );
  }, [users, error]);
  return (
    <ScreenWrapper
      title={t('Organizations:Organization Users')}
      includeBreadcrumb
    >
      {getTable}
    </ScreenWrapper>
  );
};
