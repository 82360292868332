import { atom, useAtomValue } from 'jotai';

export const groupNameAtom = atom('');
interface IBreadcrumbDynamicGroupName {
  label: string;
}
export const BreadcrumbDynamicGroupName = ({
  label,
}: IBreadcrumbDynamicGroupName) => {
  return (
    <>
      {useAtomValue(groupNameAtom)} {label}
    </>
  );
};
