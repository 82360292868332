import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createGroupFormsState } from 'components/organisms/CreateGroupForms/state.atom';

import { routePaths } from 'router/routePaths';

export enum propertiesEnum {
  displaysUserFirstName = 'displaysUserFirstName',
  displaysUserLastName = 'displaysUserLastName',
  displaysUserEmail = 'displaysUserEmail',
  allowsExportingUsers = 'allowsExportingUsers',
  allowsDeletingUsers = 'allowsDeletingUsers',
}

export const propertiesLiteral: { [key: string]: string } = {
  [propertiesEnum.displaysUserFirstName]: 'Displays user first name',
  [propertiesEnum.displaysUserLastName]: 'Displays user last name',
  [propertiesEnum.displaysUserEmail]: 'Displays user email',
  [propertiesEnum.allowsExportingUsers]: 'Allows exporting users',
  [propertiesEnum.allowsDeletingUsers]: 'Allows deleting users',
};

export const useBackToStart = () => {
  const [formState] = useAtom(createGroupFormsState);
  const navigate = useNavigate();

  useEffect(() => {
    const requiredFields = ['name', 'description'] as const;
    if (!requiredFields.every((field) => formState[field])) {
      navigate(routePaths.createGroupGeneral);
    }
  }, [formState, navigate]);
};
