import { motion } from 'framer-motion';

import { styled } from '@resiliantinc/design-system';

export const HeaderLiteral = styled('h3', {
  fontSize: '2.4rem',
  lineHeight: '3.2rem',
  gridRowStart: 2,
  gridColumn: '1 / span 3',
  pr: '$2',
});

export const Header = styled(motion.div, {
  zIndex: '$appOnTop',
  px: '$4',
  py: '$4',
  background: '$white',
  display: 'flex',
  alignContent: 'center',
  placeItems: 'center stretch',
  justifyContent: 'space-between',
  alignItems: 'center',
  justifyItems: 'stretch',
  gridTemplateColumns: '4rem 1fr auto',
  variants: {
    sticky: {
      true: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        px: '$2 !important',
        py: '$2',
        boxShadow:
          '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
        [`${HeaderLiteral}`]: {
          mt: 0,
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          ml: '$4',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          gridRowStart: 1,
          gridColumn: '2',
        },
      },
    },
  },
});

export const CloseWrapper = styled('div', {});

export const ExtraContentWrapper = styled('div', {
  gridColumnStart: 3,
});

export const stickyHeaderAnimationProps = {
  initial: { y: '-100%' },
  animate: { y: 0 },
  exit: { y: '-100%', top: '-100%' },
  transition: { duration: 0.2, ease: 'easeInOut' },
};
