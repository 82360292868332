import { createColumnHelper } from '@tanstack/react-table';
import { Eye, Trash } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Box, Button } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { ChipHolder } from 'components/atoms/ChipHolder';
import { WithConfirmation } from 'components/molecules/WithConfirmation';
import { statusTooltipLiteral } from 'components/organisms/UsersTable/UsersTable.coldef';

import { IUserParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IUserParsed>();

export const columns = (
  hideEmail?: boolean,
  hideDelete?: boolean,
  hideFirstName?: boolean,
  hideLastName?: boolean
) => [
  columnHelper.accessor('id', {
    header: 'User ID',
  }),
  columnHelper.accessor('firstName', {
    header: 'First name',
    cell: (value) => {
      const firstName = value.getValue();
      return hideFirstName ? <span>*****</span> : <span>{firstName}</span>;
    },
  }),
  columnHelper.accessor('lastName', {
    header: 'Last name',
    cell: (value) => {
      const lastName = value.getValue();
      return hideLastName ? <span>*****</span> : <span>{lastName}</span>;
    },
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    cell: (value) => {
      const email = value.getValue();
      return hideEmail ? <span>*****</span> : <span>{email}</span>;
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (value) => {
      const statuses = value.getValue();
      const chips = statuses.map((status) => (
        <Chip title={status} tooltip={statusTooltipLiteral[status]} />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),

  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => {
      // @ts-ignore
      const rowAction = (id: number) => () => value.rowAction(id);
      return (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Button
            variant="outlined"
            leftIcon={<Eye size="1.6rem" />}
            as={Link}
            to={generatePath(routePaths.userDetails, { id: value.getValue() })}
            css={{ marginRight: '1rem' }}
          >
            View
          </Button>
          {!hideDelete && (
            <WithConfirmation
              onConfirm={rowAction(value.getValue())}
              modalTitle="Are you sure you'd like to remove this user?"
              modalDescription="You can add them back in later"
            >
              <Button
                variant="outlined"
                leftIcon={<Trash size="1.6rem" />}
                danger
              >
                Remove
              </Button>
            </WithConfirmation>
          )}
        </Box>
      );
    },
  }),
];
