import { styled } from '@resiliantinc/design-system';

export const StupidlyOvercomplicatedDateSelectStyles = {
  Wrapper: styled('div', {
    position: 'relative',
  }),
  PickerWrapper: styled('div', {
    position: 'absolute',
    top: 'calc(100% + $4)',
    right: 0,
    bc: '$white',
    border: '1px solid $border',
    zIndex: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minWidth: '30rem',
    variants: {
      showsAll: {
        true: {
          display: 'block',
        },
      },
    },
  }),
  PredefinedRangesWrapper: styled('div', {
    p: '$4',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '$2',
    '& > div': {
      whiteSpace: 'nowrap',
    },
  }),
  StyledDatePicker: styled('div', {
    '& .react-datepicker': {
      minWidth: '30rem',
      border: 'none',
      p: '$4',
    },
    '& .react-datepicker__month-container': {
      width: '100%',
    },
    '& .react-datepicker__year-wrapper': {
      width: '100%',
      maxWidth: 'unset',
      justifyContent: 'center',
    },
    '& .react-datepicker__header': {
      backgroundColor: '$white',
      border: 'none',
    },
    '& .react-datepicker__current-month, & .react-datepicker-year-header': {
      fontSize: '$4',
      fontWeight: '500',
      mt: '-1rem',
      mb: '$2',
    },
    '& .react-datepicker__week': {
      display: 'flex',
      mb: '$2',
    },
    '& .react-datepicker__navigation': {
      top: '1.2rem',
    },
    '& .react-datepicker__day-names': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid $border',
      m: 0,
    },
    '& .react-datepicker__day-name': {
      width: '3.6rem',
      height: '3.6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: 0,
    },
    '& .react-datepicker__day': {
      width: '3.6rem',
      height: '3.6rem',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: 0,

      '&.react-datepicker__day--range-start': {
        borderRadius: 0,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },
      '&.react-datepicker__day--range-end': {
        borderRadius: 0,
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },
      '&.react-datepicker__day--selecting-range-start': {
        borderRadius: 0,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },
      '&.react-datepicker__day--selecting-range-end': {
        borderRadius: 0,
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },

      '&.react-datepicker__day--in-range:not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start)':
        {
          borderRadius: 0,
        },
      '&.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--selecting-range-start)':
        {
          borderRadius: 0,
        },

      '&:hover:not(.react-datepicker__day--in-selecting-range)': {
        backgroundColor: '$white',
        color: '$black',
        borderRadius: '50%',
        border: '1px solid $primary',
      },
      '&.react-datepicker__day--selected': {
        backgroundColor: '$primary',
        '&:hover': {
          backgroundColor: '$primary',
          color: '$white',
        },
      },
    },
  }),
  OptionsContainer: styled('div', {
    borderRight: '1px solid $border',
  }),
  Option: styled('div', {
    whiteSpace: 'nowrap',
    p: '$3 $4',
    cursor: 'pointer',
    variants: {
      active: {
        true: {
          bc: '$primary',
          color: '$white',
        },
      },
    },
  }),
};
