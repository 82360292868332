import Skeleton from 'react-loading-skeleton';

import { Box } from '@resiliantinc/design-system';

export const ChartLoading = () => {
  return (
    <Box>
      <Skeleton height={30} />
      <Skeleton height={200} />
    </Box>
  );
};
