import { styled } from '@resiliantinc/design-system';

export const OrgBrandingFormStyles = {
  Wrapper: styled('div', {
    p: '$8',
    border: '1px solid $border',
    borderRadius: '$sm',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gap: '$8',
  }),
  Section: styled('div', {
    '&:not(:last-child)': {
      pr: '$8',
      borderRight: '1px solid $border',
    },
  }),
  SectionTitle: styled('h3', {
    fontSize: '$4',
    mb: '$6',
  }),
  ColorInput: styled('div', {
    display: 'flex',
    gap: '$4',
    mt: '$1',
    '& input': {
      border: 'none',
      outline: 'none',
    },
  }),
  Logo: styled('img', {
    maxWidth: '100%',
  }),
};
