import { useUser } from 'providers/UserProvider';

import { OrganizationDetails } from 'screens/OrganizationDetails';

export const MyOrg = () => {
  const { user } = useUser();
  const { organization_id } = user || {};

  return (
    <OrganizationDetails
      orgId={organization_id}
      showApproveReject={false}
      includeBreadcrumb={false}
    />
  );
};
