import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ButtonFeedback,
  Switch,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';
import {
  propertiesLiteral,
  useBackToStart,
} from 'components/organisms/CreateGroupForms/CreateGroupForms.utils';
import {
  createGroupFormsState,
  defaultFormState,
} from 'components/organisms/CreateGroupForms/state.atom';

import Api from 'lib/api';
import { ICreateGroupRequest } from 'lib/types';

import { routePaths } from 'router/routePaths';

const Confirmation = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useAtom(createGroupFormsState);
  const [loading, setLoading] = useState(false);
  useBackToStart();
  const navigate = useNavigate();

  const renderSwitches = useMemo(() => {
    return Object.entries(formState.properties)
      .filter(([, value]) => value)
      .map(([key]) => {
        return (
          <Switch
            key={key}
            label={t(`Groups:${propertiesLiteral[key]}`)}
            checked
            readOnly
          />
        );
      });
  }, [t, formState.properties]);

  const onCreateGroup = useCallback(async () => {
    setLoading(true);
    const { name, description, properties, users, administrators } = formState;
    const data: ICreateGroupRequest = {
      name,
      description,
      properties: Object.entries(properties)
        .filter(([, value]) => value)
        .map(([key, value]) => {
          return {
            [key]: value,
          };
        }),
      users: users.map((user) => user.id),
      administrators: administrators.map((user) => user.id),
    };
    try {
      await Api.createGroup(data);
      createFeedback({
        variant: 'success',
        heading: t('Groups:Group created successfully'),
      });
      setFormState(defaultFormState);
      navigate(routePaths.groups);
    } catch (e) {
      createFeedback({
        variant: 'error',
        heading: t('Groups:Error creating group'),
      });
    } finally {
      setLoading(false);
    }
  }, [formState, t, navigate, setFormState]);

  return (
    <>
      <CreateGroupFormsStyles.SectionHalfWidth>
        <CreateGroupFormsStyles.SectionTitle>
          {t('Groups:General Information')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.GridWrapper>
          <Text>{t('Groups:Group Name')}:</Text>
          <Text>{formState.name}</Text>

          <Text>{t('Groups:Group Description')}:</Text>
          <Text>{formState.description}</Text>
        </CreateGroupFormsStyles.GridWrapper>
      </CreateGroupFormsStyles.SectionHalfWidth>
      <CreateGroupFormsStyles.SectionConfirmation>
        <CreateGroupFormsStyles.SectionTitle>
          {t('Groups:Properties')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.SwitchesSummary>
          {renderSwitches}
        </CreateGroupFormsStyles.SwitchesSummary>
      </CreateGroupFormsStyles.SectionConfirmation>
      <CreateGroupFormsStyles.NextButton>
        <ButtonFeedback isLoading={loading} onClick={onCreateGroup}>
          {t('Groups:Create Group')}
        </ButtonFeedback>
      </CreateGroupFormsStyles.NextButton>
    </>
  );
};

export { Confirmation };
