import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSwr from 'swr';

import {
  Button,
  Input,
  Switch,
  createFeedback,
} from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';

import Api, { processApiError } from 'lib/api';
import { IPermissionsResponse, IRoleResponse } from 'lib/types';

import { useUser } from 'providers/UserProvider';

const EditRoleForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: currentRoleData, error } = useSwr<IRoleResponse>(
    id && Api.getRoleDetails(+id)
  );

  const { permissions: currentAdminPermissions } = useUser();

  const { data: permissionsData, error: permissionsError } =
    useSwr<IPermissionsResponse>(Api.getPermissions);

  const navigate = useNavigate();

  const onCheckboxChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;

      if (checked) {
        try {
          await Api.addPermissionToRole(+id!, +name);
          createFeedback({
            variant: 'success',
            heading: t('ManageRoles:Permission added to role'),
          });
        } catch (e) {
          const messages = processApiError(e);
          createFeedback({
            variant: 'error',
            heading: 'Error: ' + messages.join(', '),
          });
        }

        return;
      }

      try {
        await Api.removePermissionFromRole(+id!, +name);
        createFeedback({
          variant: 'success',
          heading: t('ManageRoles:Permission removed from role'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      }
    },
    [id, t]
  );

  const renderSwitches = useMemo(() => {
    return permissionsData?.permissions
      .filter((permission) => currentAdminPermissions?.includes(permission.key))
      .map((permission) => {
        return (
          <Switch
            key={permission.id}
            label={permission.name}
            onChange={onCheckboxChange}
            name={permission.id.toString()}
            defaultChecked={currentRoleData?.permissions.some(
              (rolePermission) => rolePermission.id === permission.id
            )}
          />
        );
      });
  }, [
    permissionsData,
    currentRoleData,
    onCheckboxChange,
    currentAdminPermissions,
  ]);

  if (error || permissionsError) return <LoadFailed />;
  if (!currentRoleData) return <ScreenLoader />;

  return (
    <>
      <CreateGroupFormsStyles.SectionHalfWidth>
        <CreateGroupFormsStyles.SectionTitle>
          {t('ManageRoles:Editing role:')} {currentRoleData.role.name}
        </CreateGroupFormsStyles.SectionTitle>
        <Input
          label={t('ManageRoles:Role Name:')}
          name="name"
          defaultValue={currentRoleData.role.name}
          placeholder={t('ManageRoles:Role Administrator')}
          readOnly
        />
        <Input
          as="textarea"
          name="description"
          rows={10}
          css={{ resize: 'vertical' }}
          label={t('ManageRoles:Description')}
          placeholder={t('ManageRoles:Change roles')}
          required
          defaultValue={currentRoleData.role.description}
          readOnly
        />
      </CreateGroupFormsStyles.SectionHalfWidth>
      <CreateGroupFormsStyles.SectionConfirmation>
        <CreateGroupFormsStyles.SectionTitle>
          {t('ManageRoles:Role Permissions')}
        </CreateGroupFormsStyles.SectionTitle>
        <CreateGroupFormsStyles.SwitchesSectionWrapper>
          {renderSwitches}
        </CreateGroupFormsStyles.SwitchesSectionWrapper>
      </CreateGroupFormsStyles.SectionConfirmation>
      <CreateGroupFormsStyles.NextButton>
        <Button type="button" onClick={() => navigate(-1)} variant="outlined">
          {t('ManageRoles:Cancel')}
        </Button>
      </CreateGroupFormsStyles.NextButton>
    </>
  );
};

export { EditRoleForm };
