import { styled } from '@resiliantinc/design-system';

export const BulkInviteUsersFormStyles = {
  Users: styled('div', {
    mt: '$6',
    mb: '$8',
  }),
  User: styled('div', {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 1fr',
    p: '$2',
    borderBottom: '1px solid $slate50',
  }),
  Chips: styled('div', {
    display: 'flex',
    alignItems: 'center',
  }),
  Button: styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
  }),
};
