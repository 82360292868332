import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { OrganizationsTable } from 'components/organisms/OrganizationsTable';

export const Organizations = () => {
  const { t } = useTranslation();

  return (
    <ScreenWrapper title={t('Organizations:Organizations')}>
      <OrganizationsTable />
    </ScreenWrapper>
  );
};
