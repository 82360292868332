import { BreadcrumbData } from 'use-react-router-breadcrumbs';

import { INavigationItem } from './BreadcrumbNavigationItem';

export const convertBreadcrumbToNavigationItem = (
  breadcrumb: BreadcrumbData
): INavigationItem => ({
  key: breadcrumb.key,
  path:
    breadcrumb.match.pathname !== breadcrumb.location.pathname
      ? breadcrumb.match.pathname
      : undefined,
  label: breadcrumb.breadcrumb,
});
