import { useCallback, useState } from 'react';
import { Accept, FileRejection } from 'react-dropzone';

import { createFeedback } from '@resiliantinc/design-system';

import {
  getBytesMaxSize,
  useGetRejectedErrorLiterals,
  useUnknownErrorLiteral,
} from 'components/molecules/Dropzone/Dropzone.utils';

interface IUseUploadZone {
  accept: Accept;
  maxSizeMb: number;
  onDropAccepted: (files: File[]) => void;
  onDropRejected?: (files: FileRejection[]) => void;
}

export const useDropzoneUtils = ({
  accept,
  maxSizeMb,
  onDropRejected,
  onDropAccepted,
}: IUseUploadZone) => {
  const [rejectedErrors, setRejectedErrors] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const maxSizeBytes = getBytesMaxSize(maxSizeMb);
  const unknownErrorLiteral = useUnknownErrorLiteral();

  const rejectedErrorLiterals = useGetRejectedErrorLiterals({
    accept,
    maxSizeMb,
  });

  const handleRemoveError = useCallback(() => {
    setErrors([]);
    setRejectedErrors([]);
  }, []);

  const onFileError = useCallback(() => {
    setErrors([unknownErrorLiteral]);
  }, [unknownErrorLiteral]);

  const onDropFilesAccepted = useCallback(
    async (files: File[]) => {
      if (errors.length) {
        handleRemoveError();
      }

      onDropAccepted(files);
    },
    [errors.length, handleRemoveError, onDropAccepted]
  );

  const onDropFilesRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const errors = filesRejected.map((file) => {
        const [firstError] = file.errors;
        const { code } = firstError;
        return rejectedErrorLiterals[code] || 'Unknown error';
      });

      setRejectedErrors(errors);
      onDropRejected?.(filesRejected);
      createFeedback({
        variant: 'error',
        heading: 'Error',
        defaultExpanded: true,
        children: Array.from(new Set(errors)).map((error) => error + '. '),
      });
    },
    [rejectedErrorLiterals, onDropRejected]
  );

  return {
    maxSizeBytes,
    errors,
    onDropFilesAccepted,
    onDropFilesRejected,
    onFileError,
    handleRemoveError,
    rejectedErrors,
  };
};
