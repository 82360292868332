import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ButtonFeedback,
  Input,
  Title,
  createFeedback,
} from '@resiliantinc/design-system';

import { processApiError } from 'lib/api';

import { useUser } from 'providers/UserProvider';

import { UserDetailsFormStyles } from './UserDetailsForm.styles';

const UserDetailsForm = () => {
  const { t } = useTranslation();
  const { user, updateUser } = useUser();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        await updateUser(data);
        createFeedback({
          variant: 'success',
          heading: t('Account:Profile updated successfully'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [t, updateUser]
  );

  return (
    <UserDetailsFormStyles.Form onSubmit={handleSubmit(onSubmit)}>
      <Title as="h4" css={{ mb: '$6' }}>
        {t('Account:Account Details')}
      </Title>
      <Input
        label={t('Account:Name')}
        {...register('name')}
        dense
        defaultValue={user?.name}
      />
      <Input
        label={t('Account:Email')}
        {...register('email')}
        defaultValue={user?.email}
      />
      <ButtonFeedback isLoading={loading}>
        {t('Account:Update Profile')}
      </ButtonFeedback>
    </UserDetailsFormStyles.Form>
  );
};

export { UserDetailsForm };
