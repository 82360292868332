import { useAtom } from 'jotai';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { UserMultiSelect } from 'components/molecules/UserSearchInput';
import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';
import { useBackToStart } from 'components/organisms/CreateGroupForms/CreateGroupForms.utils';
import { createGroupFormsState } from 'components/organisms/CreateGroupForms/state.atom';

import { routePaths } from 'router/routePaths';

const Users = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useAtom(createGroupFormsState);
  const [users, setUsers] = useState(formState.users);
  useBackToStart();

  const { handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = () => {
    setFormState((prev: any) => ({
      ...prev,
      users,
    }));
    navigate(routePaths.createGroupAdmins);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserMultiSelect setUsers={setUsers} users={users} />
      <CreateGroupFormsStyles.NextButton>
        <Button>{t('Groups:Add Administrators')}</Button>
      </CreateGroupFormsStyles.NextButton>
    </form>
  );
};

export { Users };
