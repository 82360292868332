import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { Chip } from 'components/atoms/Chip';
import { ChipHolder } from 'components/atoms/ChipHolder';

import { IUserParsed } from 'lib/parsers';
import { userStatusEnum } from 'lib/types';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IUserParsed>();

export const statusTooltipLiteral = {
  [userStatusEnum.id_onboarded]:
    'User has created an IDNFT token for identification which is now stored on the ResiliantID blockchain',
  [userStatusEnum.linked]:
    'User has consented to be linked to your organization',
  [userStatusEnum.registered]: 'User has registered an account in our system',
  [userStatusEnum.email_confirmed]: 'User has confirmed their email address',
};

export const columns = [
  columnHelper.accessor('id', {
    header: 'User ID',
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
  }),
  columnHelper.accessor('lastName', {
    header: 'Last Name',
  }),
  // columnHelper.accessor('lastSeen', {
  //   header: 'Last Seen',
  // }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (value) => {
      const statuses = value.getValue();
      const chips = statuses.map((status) => (
        <Chip title={status} tooltip={statusTooltipLiteral[status]} />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),
  columnHelper.accessor('groups', {
    header: 'Groups',
    cell: (value) => {
      const groups = value.getValue();
      const chips = groups.map((group) => (
        <Chip
          title={group.name}
          link={generatePath(routePaths.groupDetails, { id: group.id })}
        />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.userDetails, { id: value.getValue() })}
      >
        View
      </Button>
    ),
  }),
];

export const simpleColumns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => (
      <Button
        variant="outlined"
        leftIcon={<Eye size="1.6rem" />}
        as={Link}
        to={generatePath(routePaths.userDetails, { id: value.getValue() })}
      >
        View
      </Button>
    ),
  }),
];
