import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { ViewRoleDetails } from 'components/organisms/ViewRoleDetails';

import { routePaths } from 'router/routePaths';

export const ViewRole = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <ScreenWrapper
      title={t('ManageRoles:View Role')}
      includeBreadcrumb
      action={
        <Button as={Link} to={generatePath(routePaths.editRole, { id })}>
          {t('ManageRoles:Edit Role')}
        </Button>
      }
    >
      <ViewRoleDetails />
    </ScreenWrapper>
  );
};
