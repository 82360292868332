import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { PlanCard } from 'components/molecules/PlanCard';

import Api from 'lib/api';
import { IOrganizationDetailsResponse } from 'lib/types';

import { useUser } from 'providers/UserProvider';

export const ViewPlan = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { organization_id } = user || {};
  const { data } = useSwr<IOrganizationDetailsResponse>(
    Api.getOrganizationDetails(organization_id!)
  );
  if (!data) return <ScreenLoader />;

  const { plan_name, plan_max_users, plan_price_per_user } = data.organization;

  return (
    <ScreenWrapper title={t('Account:Plan')} subtitle={t('View your plan')}>
      <PlanCard
        plan_name={plan_name}
        plan_max_users={plan_max_users}
        plan_price_per_user={plan_price_per_user}
      />
    </ScreenWrapper>
  );
};
