import { useCallback, useState } from 'react';
import {
  EnvelopeFill,
  ExclamationCircleFill,
  GeoAltFill,
  Globe2,
  TelephoneFill,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import useSwr from 'swr';

import {
  Box,
  Button,
  ButtonFeedback,
  Link as DSLink,
  Grid,
  InlineFeedback,
  Text,
  createFeedback,
  theme,
} from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { WithConfirmation } from 'components/molecules/WithConfirmation';
import { ActivityTable } from 'components/organisms/ActivityTable';

import Api, { processApiError } from 'lib/api';
import { IOrganizationDetailsResponse } from 'lib/types';

import { routePaths } from 'router/routePaths';

import {
  ApproveHeadline,
  BoxTitle,
  FlexBox,
  InlineFeedbackHeaderContainer,
  InlineFeedbackHeading,
  InlineFeedbackIconContainer,
  InlineFeedbackIconWithTitle,
  WrapperBox,
} from './OrganizationDetails.styles';

interface IOrganizationDetailsProps {
  orgId?: number;
  showApproveReject?: boolean;
  includeBreadcrumb?: boolean;
}

export const OrganizationDetails = ({
  orgId,
  showApproveReject = true,
  includeBreadcrumb = true,
}: IOrganizationDetailsProps) => {
  const { id = orgId } = useParams();
  const { t } = useTranslation();
  const { data, error, mutate } = useSwr<IOrganizationDetailsResponse>(
    Api.getOrganizationDetails(+id!)
  );
  const [approveLoading, setApproveLoading] = useState(false);

  const onApproveClick = useCallback(async () => {
    setApproveLoading(true);
    try {
      await Api.approveOrganization(+id!);
      createFeedback({
        variant: 'success',
        heading: t('Organizations:Organization approved successfully'),
      });
      await mutate();
    } catch (e) {
      const messages = processApiError(e);
      createFeedback({
        variant: 'error',
        heading: 'Error: ' + messages.join(', '),
      });
    } finally {
      setApproveLoading(false);
    }
  }, [id, t, mutate]);

  const onRejectClick = useCallback(async () => {
    await Api.rejectOrganization(+id!);
    createFeedback({
      variant: 'success',
      heading: t('Organizations:Organization rejected successfully'),
    });
  }, [id, t]);

  if (error) return <LoadFailed />;
  if (!data) return <ScreenLoader />;

  const {
    organization: {
      name,
      website,
      address_1,
      address_2,
      org_contact_first_name,
      org_contact_last_name,
      org_contact_email,
      org_contact_phone,
      primary_admin_first_name,
      primary_admin_last_name,
      primary_admin_email,
      primary_admin_phone,
      plan_max_users,
      status,
    },
    organizationStats: {
      userCount,
      administratorCount,
      groupCount,
      // apiCallsCurrentMonth,
    },
  } = data;

  return (
    <ScreenWrapper title={name} includeBreadcrumb={includeBreadcrumb}>
      {showApproveReject && status === 'rejected' && (
        <InlineFeedback
          variant={'error'}
          heading={t('Organizations:This organization has been rejected')}
          css={{
            mb: '$6',
          }}
        />
      )}
      {status !== 'approved' && status !== 'rejected' && showApproveReject ? (
        <ApproveHeadline
          css={{
            mb: '$6',
          }}
        >
          <InlineFeedbackHeaderContainer>
            <InlineFeedbackIconWithTitle>
              <InlineFeedbackIconContainer>
                <ExclamationCircleFill
                  size="1.6rem"
                  color={theme.colors.yellow500.value}
                  data-testid="warning-icon"
                />
              </InlineFeedbackIconContainer>
              <InlineFeedbackHeading>
                {t('Organizations:This organization is pending review')}
              </InlineFeedbackHeading>
            </InlineFeedbackIconWithTitle>
            <InlineFeedbackIconWithTitle>
              <WithConfirmation
                onConfirm={onRejectClick}
                modalTitle={t(
                  'Organizations:Are you sure you want to reject this organization?'
                )}
                modalDescription=""
                primaryActionText={t('Organizations:Reject')}
              >
                <Button variant="outlined" css={{ mr: '$3' }}>
                  {t('Organizations:Reject')}
                </Button>
              </WithConfirmation>
              <ButtonFeedback
                isLoading={approveLoading}
                onClick={onApproveClick}
              >
                {t('Organizations:Approve')}
              </ButtonFeedback>
            </InlineFeedbackIconWithTitle>
          </InlineFeedbackHeaderContainer>
        </ApproveHeadline>
      ) : null}
      <Grid
        columns={4}
        gap={6}
        css={{
          mb: '$6',
        }}
      >
        <WrapperBox color="yellow">
          <BoxTitle>{t('Organizations:Organization Details')}</BoxTitle>
          <FlexBox>
            <Globe2 size="2.4rem" />
            <DSLink
              href={website}
              css={{
                ml: '$2',
              }}
            >
              {website}
            </DSLink>
          </FlexBox>
          <FlexBox css={{ alignItems: 'flex-start' }}>
            <GeoAltFill size="2.4rem" />
            <Box>
              <Text
                css={{
                  ml: '$2',
                }}
              >
                {address_1}
              </Text>
              <Text
                css={{
                  ml: '$2',
                }}
              >
                {address_2}
              </Text>
            </Box>
          </FlexBox>
        </WrapperBox>

        <WrapperBox color="sky">
          <BoxTitle>{t('Organizations:Usage')}</BoxTitle>
          <FlexBox>
            <Text>{t('Organizations:Users:')}</Text>
            <DSLink
              as={Link}
              to={generatePath(routePaths.orgUsers, { id })}
              css={{
                ml: '$2',
              }}
            >
              {userCount || 0} / {plan_max_users}
            </DSLink>
          </FlexBox>
          <FlexBox css={{ alignItems: 'flex-start' }}>
            <Text>{t('Organizations:Administrators:')}</Text>
            <DSLink
              as={Link}
              to={routePaths.administrators}
              css={{
                ml: '$2',
              }}
            >
              {administratorCount || 0}
            </DSLink>
          </FlexBox>
          <FlexBox>
            <Text>{t('Organizations:Groups:')}</Text>
            <DSLink
              as={Link}
              to={routePaths.groups}
              css={{
                ml: '$2',
              }}
            >
              {groupCount || 0}
            </DSLink>
          </FlexBox>
          {/*<FlexBox>*/}
          {/*  <Text>{t('Organizations:API Calls (current month):')}</Text>*/}
          {/*  <DSLink*/}
          {/*    as={Link}*/}
          {/*    to={routePaths.groups}*/}
          {/*    css={{*/}
          {/*      ml: '$2',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {apiCallsCurrentMonth || 0}*/}
          {/*  </DSLink>*/}
          {/*</FlexBox>*/}
        </WrapperBox>

        <WrapperBox color="lime">
          <BoxTitle>{t('Organizations:Organization Contact')}</BoxTitle>
          <FlexBox>
            <Text>
              {org_contact_first_name} {org_contact_last_name}
            </Text>
          </FlexBox>
          <FlexBox>
            <EnvelopeFill size="2.4rem" />
            <DSLink
              href={`mailto:${org_contact_email}`}
              css={{
                ml: '$2',
              }}
            >
              {org_contact_email}
            </DSLink>
          </FlexBox>
          <FlexBox>
            <TelephoneFill size="2.4rem" />
            <DSLink
              href={`tel:${org_contact_phone}`}
              css={{
                ml: '$2',
              }}
            >
              {org_contact_phone}
            </DSLink>
          </FlexBox>
        </WrapperBox>

        <WrapperBox color="purple">
          <BoxTitle>{t('Organizations:Primary Administrator')}</BoxTitle>
          <FlexBox>
            <Text>
              {primary_admin_first_name} {primary_admin_last_name}
            </Text>
          </FlexBox>
          <FlexBox>
            <EnvelopeFill size="2.4rem" />
            <DSLink
              href={`mailto:${primary_admin_email}`}
              css={{
                ml: '$2',
              }}
            >
              {primary_admin_email}
            </DSLink>
          </FlexBox>
          <FlexBox>
            <TelephoneFill size="2.4rem" />
            <DSLink
              href={`tel:${primary_admin_phone}`}
              css={{
                ml: '$2',
              }}
            >
              {primary_admin_phone}
            </DSLink>
          </FlexBox>
        </WrapperBox>
      </Grid>

      <ActivityTable apiCall={Api.getOrganizationActivity(+id!)} />
    </ScreenWrapper>
  );
};
