import { createColumnHelper } from '@tanstack/react-table';
import { Trash } from 'react-bootstrap-icons';
import { Link, generatePath } from 'react-router-dom';

import { Box, Button } from '@resiliantinc/design-system';

import { WithConfirmation } from 'components/molecules/WithConfirmation';

import { IRoleParsed } from 'lib/parsers';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IRoleParsed>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Role',
  }),
  columnHelper.accessor('permissions_num', {
    header: 'Number of Permissions',
  }),
  columnHelper.accessor('id', {
    header: () => null,
    cell: (value) => {
      // @ts-ignore
      const rowAction = (id: number) => () => value.rowAction(id);
      return (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            jc: 'flex-end',
            gap: '$2',
          }}
        >
          <Button
            variant="outlined"
            as={Link}
            to={generatePath(routePaths.editRole, {
              id: value.getValue(),
            })}
          >
            Edit Role
          </Button>

          {/*@ts-ignore*/}
          {value.rowAction && (
            <WithConfirmation
              onConfirm={rowAction(value.getValue())}
              modalTitle="Are you sure you'd like to delete this role?"
              modalDescription="You can create it again later"
            >
              <Button
                variant="outlined"
                rounded
                leftIcon={<Trash size="1.6rem" />}
              >
                Remove
              </Button>
            </WithConfirmation>
          )}
        </Box>
      );
    },
  }),
];
