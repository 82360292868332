import { IAdministratorsResponse, IGroupShort, IRoleShort } from 'lib/types';

export interface IAdministratorParsed {
  id: number;
  name: string;
  lastSeen: string;
  groups: IGroupShort[];
  roles: IRoleShort[];
  email: string;
}

export const administratorsTableParser = (
  data: IAdministratorsResponse
): IAdministratorParsed[] => {
  const { administrators, administratorsStats } = data;
  return administrators.map((administrator) => {
    const { id, name, last_seen_at, email } = administrator;
    const { groups, roles } = administratorsStats?.[id] || {};
    return {
      id,
      name,
      lastSeen: last_seen_at,
      groups,
      roles,
      email,
    };
  });
};
