import { IRegistrationForm } from 'components/organisms/SignupForm';

import { CLIENT_ID, CLIENT_SECRET, GRANT_SCOPE, GRANT_TYPE } from 'lib/auth';
import config from 'lib/config';
import { ICreateGroupRequest, IUser } from 'lib/types';

import { ApiClient } from './api-client';

class Api extends ApiClient {
  getMe() {
    return `/api/administrator/me`;
  }
  updateMe(data: Partial<IUser>) {
    return this.http.post(`/api/me`, data);
  }
  requestSignup(data: IRegistrationForm) {
    return this.http.post('/api/organization/create', data);
  }
  login(email: string, password: string) {
    return this.http.post('/api/admin/login', { email, password });
  }
  getTFAStatus(email: string, token: string) {
    return this.http.post(`/api/admin/checktwofactor`, {
      email,
      token,
    });
  }

  checkUserId(
    id: number,
    data: {
      email: string;
      admin_email: string;
      token: string;
    },
    config?: any
  ) {
    return this.http.post(`/api/user/${id}/checkId`, data, config);
  }

  getCheckIdStatus(txId: string, config?: any) {
    return this.http.get(`/api/checkId/status/${txId}`, config);
  }

  gatherUserInfo(id: number, data: any, config?: any) {
    return this.http.post(
      `/api/user/${id}/checkIdGatherInfo`,
      {
        ticketExpires: '20231111000000',
        callback: `${config.API_BASE_URL}/api/checkId/webhook`,
        ...data,
      },
      config
    );
  }

  updateUser(id: number, data: any) {
    return this.http.post(`/api/user/${id}/update`, data);
  }

  deleteUser(id: number) {
    return this.http.delete(`/api/user/${id}`);
  }

  getOAuthToken(email: string, password: string) {
    return this.http.post('/oauth/token', {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: GRANT_TYPE,
      scope: GRANT_SCOPE,
      username: email,
      password: password,
    });
  }
  signup(email: string, password: string) {
    return this.http.post('/api/admin/register', {
      email,
      password,
      name: 'Anton Lukianchenko',
      organizationId: 24,
    });
  }

  getOrganizations(page: number) {
    return `/api/organizations?page=${page}`;
  }
  getOrganizationDetails(id: number) {
    return `/api/organization/${id}`;
  }

  updateOrganization(id: number, data: any) {
    return this.http.post(`/api/organization/${id}/update`, data);
  }

  uploadOrganizationLogo(id: number, data: any) {
    return this.http.post(`/api/organization/${id}/brandimage`, data);
  }

  getOrganizationLogo(id: number) {
    return this.http.get(`/api/organization/${id}/brandimage`);
  }

  getOrganizationUsers(id: number, page?: number) {
    return `/api/organization/${id}/users?page=${page}`;
  }

  getOrganizationActivity(id: number) {
    return `/api/activity/${id}/all`;
  }

  approveOrganization(id: number) {
    return this.http.post(`/api/organization/${id}/approve`);
  }

  rejectOrganization(id: number) {
    return this.http.post(`/api/organization/${id}/reject`);
  }

  getGroups(page?: number) {
    return `/api/groups?page=${page}`;
  }
  getGroupDetails(id: number) {
    return `/api/group/${id}`;
  }

  getGroupUsers(id: number, page?: number) {
    return `/api/group/${id}/users?page=${page}`;
  }

  addUserToGroup(groupId: string, userId: number) {
    return this.http.post(`/api/group/${groupId}/addUsers`, {
      userIds: [userId],
    });
  }

  removeUserFromGroup(groupId: number, userId: number) {
    return this.http.post(`/api/group/${groupId}/removeUsers`, {
      userIds: [userId],
    });
  }

  addAdminToGroup(groupId: string, administratorId: number) {
    return this.http.post(`/api/group/${groupId}/addAdministrators`, {
      administratorIds: [administratorId],
    });
  }

  updateGroup(id: number, data: any) {
    return this.http.post(`/api/group/${id}/update`, data);
  }
  deleteGroup(id: number) {
    return this.http.delete(`/api/group/${id}`);
  }
  getGroupAdministrators(id: number, page?: number) {
    return `/api/group/${id}/administrators?page=${page}`;
  }

  createGroup(data: ICreateGroupRequest) {
    return this.http.post(`/api/group/create`, data);
  }

  getGroupActivity(id: number, organizationId: number) {
    return `/api/activity/${organizationId}/group/${id}`;
  }
  getUsers(page: number) {
    return `/api/users?page=${page}`;
  }

  searchUsers(query: string) {
    return `/api/users/search?term=${query}`;
  }
  getUserDetails(id: number) {
    return `/api/user/${id}`;
  }

  quickInviteUser(data: any) {
    return this.http.post(`/api/invite`, data);
  }

  bulkInviteUsers(data: any) {
    return this.http.post(`/api/invite/bulk`, { invites: data });
  }

  getInvites() {
    return `/api/invites`;
  }

  getUserActivity(id: number, organizationId: number) {
    return `/api/activity/${organizationId}/user/${id}`;
  }
  getAdministrators(page: number) {
    return `/api/administrators?page=${page}`;
  }
  getAdministratorDetails(id: number) {
    return `/api/administrator/${id}`;
  }
  getSubAdmins(id: number, page?: number) {
    return `/api/administrator/${id}/subadmins?page=${page}`;
  }
  getSubUsers(id: number, page?: number) {
    return `/api/administrator/${id}/users?page=${page}`;
  }

  deleteAdministrator(id: number) {
    return this.http.delete(`/api/administrator/${id}`);
  }
  updateAdministrator(id: number, data: any) {
    return this.http.post(`/api/administrator/${id}/edit`, data);
  }
  searchAdministrators(query: string) {
    return `/api/administrators/search?term=${query}`;
  }
  getAdministratorActivity(id: number, organizationId: number) {
    return `/api/activity/${organizationId}/admin/${id}`;
  }

  getActivityDetails(id: number) {
    return `/api/activity/${id}/detail`;
  }

  addRoleToAdministrator(id: number, roleId: number) {
    return this.http.post(`/api/administrator/${id}/addRole/${roleId}`, {});
  }

  removeRoleFromAdministrator(id: number, roleId: number) {
    return this.http.post(`/api/administrator/${id}/removeRole/${roleId}`, {});
  }

  getPermissions() {
    return '/api/permissions';
  }

  getRoles(page?: number) {
    return `/api/roles${page ? `?page=${page}` : ''}`;
  }

  getRoleDetails(id: number) {
    return `/api/role/${id}`;
  }

  editRole(id: number, data: any) {
    return this.http.post(`/api/role/${id}/edit`, data);
  }

  deleteRole(id: number) {
    return this.http.delete(`/api/role/${id}`);
  }

  createRole(data: any) {
    return this.http.post(`/api/roles/create`, data);
  }

  addPermissionToRole(id: number, permissionId: number) {
    const data = new FormData();
    data.append('permissions', permissionId.toString());
    return this.http.post(`/api/role/${id}/addPermissions`, data);
  }

  removePermissionFromRole(id: number, permissionId: number) {
    return this.http.post(`/api/role/${id}/removePermission`, {
      permissionId,
    });
  }

  createApiKey(adminId: number) {
    return this.http.post(`/api/administrator/${adminId}/createAPIKey`);
  }

  createAdministrator(data: any) {
    return this.http.post(`/api/administrators/create`, data);
  }

  getLineChartData(type: string, range?: string) {
    return `/api/dashboard/lineChartData?type=${type}${
      range ? `&${range}` : ''
    }`;
  }

  getDonutChartData(type: string, range?: string) {
    return `/api/dashboard/donutChartData?type=${type}${
      range ? `&${range}` : ''
    }`;
  }

  checkOptout(token: string) {
    return this.http.post(`/api/optout/${token}`);
  }
  verifyEmailChange(token: string) {
    return this.http.post(`/api/verifyEmailToken/${token}`);
  }
  confirmAdminAccount(token: string) {
    return this.http.post(`/api/confirmAdminAccount/${token}`);
  }
  userAcceptInvite(token: string) {
    return this.http.post(`/api/userAcceptInvite/${token}`);
  }
}

export default new Api({
  baseURL: config.API_BASE_URL,
});
