import { AnimatePresence } from 'framer-motion';
import { useAtom } from 'jotai';
import { kebabCase } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IMainMenuConfigItem,
  IMainMenuConfigSubItem,
} from 'lib/MainMenuConfig';

import { useUser } from 'providers/UserProvider';

import { menuOpenStateAtom } from './MainMenu.atoms';
import { IMainMenu } from './MainMenu.interface';
import { Menu } from './Menu.styled';
import { MenuItem } from './MenuItem.styled';
import { IconWrapper, MenuItemNavLink } from './MenuItemNavLink.styled';
import { MenuItemText } from './MenuItemText.styled';
import { Nav, NavBackdrop } from './Nav.styled';

const MainMenu: FC<IMainMenu> = ({ config, ...props }) => {
  const [menuOpen, setMenuOpen] = useAtom(menuOpenStateAtom);
  const { permissions } = useUser();
  const navigate = useNavigate();
  const onItemClick = useCallback(
    (path: string) => (e: any) => {
      e.preventDefault();
      navigate(path);
      setMenuOpen(false);
    },
    [navigate, setMenuOpen]
  );

  const renderMenu = useMemo(
    () =>
      (
        config: IMainMenuConfigItem[] | IMainMenuConfigSubItem[],
        hasSubItems?: boolean
      ) => {
        return config.map((item) => {
          if (
            item.requiredPermission &&
            !permissions?.includes(item.requiredPermission)
          ) {
            return null;
          }

          const { text, icon, link, expandedText, subItems } =
            item as typeof hasSubItems extends true
              ? IMainMenuConfigSubItem
              : IMainMenuConfigItem;

          return (
            <MenuItem key={kebabCase(text)}>
              <MenuItemNavLink
                onClick={onItemClick(link!)}
                to={link!}
                expanded={menuOpen}
                isSubItem={hasSubItems}
                end
              >
                <>
                  <IconWrapper>{icon ? icon : null}</IconWrapper>
                  <MenuItemText expanded={menuOpen}>
                    {menuOpen ? expandedText : text}
                  </MenuItemText>
                </>
              </MenuItemNavLink>
              {subItems && menuOpen ? renderMenu(subItems, true) : null}
            </MenuItem>
          );
        });
      },
    [menuOpen, onItemClick, permissions]
  );

  const backDropAnimated = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 0.2,
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <>
      <AnimatePresence>
        {menuOpen ? (
          <NavBackdrop
            {...backDropAnimated}
            onClick={() => setMenuOpen(false)}
          />
        ) : null}
      </AnimatePresence>

      <Nav {...props} open={menuOpen}>
        <Menu>{renderMenu(config)}</Menu>
      </Nav>
    </>
  );
};

export { MainMenu };
