import { styled } from '@resiliantinc/design-system';

export const UserAvatarStyles = {
  Container: styled('div', {
    display: 'flex',
    gap: '$6',
    pb: '4.8rem',
    borderBottom: '1px solid $border',
  }),
  Image: styled('div', {
    width: '12rem',
    height: '12rem',
    borderRadius: '50%',
    backgroundColor: '$sky50',
    fontSize: '$6',
    color: '$sky900',
    fontWeight: '$bolder',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  DetailsContainer: styled('div', {}),
  SlateText: styled('div', {
    color: '$general500',
    fontWeight: '$bolder',
    fontSize: '$3',
    mt: '$2',
  }),
};
