import { debounce } from 'lodash';
import { ChangeEvent, useState } from 'react';

export const useSearchHelper = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, 500);

  return {
    searchTerm,
    handleSearch,
  };
};
