import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ButtonFeedback,
  InlineFeedback,
  Input,
  Title,
  createFeedback,
} from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { BlueCard } from 'components/atoms/BlueCard';

import Api, { processApiError } from 'lib/api';

import { useUser } from 'providers/UserProvider';

import { WebhookStyles } from 'screens/Webhooks/Webhook.styles';

const gatherInfoPayloadExample = `{
  "Status": "OK",
  "TransactionId": "cbc7-3667-e9d4-939b",
  "email": "eturner@resiliant.com",
  "geolocation": "26.1085662841797,-80.3640965913481"
}`;

const checkIdPayloadExample = `{
    "Status": "OK",
    "TransactionId": "cbc7-3667-e9d4-939b",
    "email": "eturner@resiliant.com"
}`;

export const Webhooks = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const { user, additionalDetails } = useUser();
  const { organization_id } = user || {};
  const { organization_webhook_gather_info, organization_webhook_check_id } =
    additionalDetails || {};

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);
        await Api.updateOrganization(+organization_id!, data);

        createFeedback({
          variant: 'success',
          heading: t('Developer:Webhook URL saved'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [t, organization_id]
  );

  return (
    <ScreenWrapper title={t('Developer:Webhooks')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BlueCard>
          <Title as="h4">{t('Developer:Collect User Info')}</Title>
          <InlineFeedback
            css={{
              mt: '$2',
              mb: '$6',
            }}
            heading={t(
              'Developer:Enter the URL of the server you want to notify after user info has been gathered.'
            )}
            variant="info"
          />
          <WebhookStyles.InputWithButton>
            <Input
              {...register('webhook_gather_info')}
              label={t('Developer:Webhook URL')}
              placeholder="https://example.com/webhook/info"
              wrapperCss={{
                p: 0,
                m: 0,
                width: '100%',
              }}
              defaultValue={organization_webhook_gather_info}
              required
            />
            <ButtonFeedback
              isLoading={loading}
              css={{
                minWidth: '16rem',
              }}
            >
              {t('Developer:Save')}
            </ButtonFeedback>
          </WebhookStyles.InputWithButton>
          <WebhookStyles.Payload>
            <Title as="h5">{t('Developer:Payload example:')}</Title>
            <WebhookStyles.PayloadExample>
              {gatherInfoPayloadExample}
            </WebhookStyles.PayloadExample>
          </WebhookStyles.Payload>
        </BlueCard>
      </form>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BlueCard
          css={{
            mt: '$6',
          }}
        >
          <Title as="h4">{t('Developer:Check ID Webhook')}</Title>
          <InlineFeedback
            css={{
              mt: '$2',
              mb: '$6',
            }}
            heading={t(
              'Developer:Enter the URL of the server you want to notify after user ID has been checked.'
            )}
            variant="info"
          />
          <WebhookStyles.InputWithButton>
            <Input
              {...register('webhook_check_id')}
              label={t('Developer:Webhook URL')}
              placeholder="https://example.com/webhook/id"
              defaultValue={organization_webhook_check_id}
              wrapperCss={{
                p: 0,
                m: 0,
                width: '100%',
              }}
              required
            />
            <ButtonFeedback
              isLoading={loading}
              css={{
                minWidth: '16rem',
              }}
            >
              {t('Developer:Save')}
            </ButtonFeedback>
          </WebhookStyles.InputWithButton>
          <WebhookStyles.Payload>
            <Title as="h5">{t('Developer:Payload example:')}</Title>
            <WebhookStyles.PayloadExample>
              {checkIdPayloadExample}
            </WebhookStyles.PayloadExample>
          </WebhookStyles.Payload>
        </BlueCard>
      </form>
    </ScreenWrapper>
  );
};
