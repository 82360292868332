import { useMemo, useState } from 'react';
import { CheckCircle, XCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { Text, Title } from '@resiliantinc/design-system';

import { PublicLayout } from 'components/Layout';
import {
  CenteredContentWrapper,
  FlexCenterColumn,
} from 'components/Layout/ScreenLayout';
import { FullScreenLoader } from 'components/atoms/FullScreenLoader';

import Api from 'lib/api';
import { useQuery } from 'lib/hooks';

export const UserAcceptInvite = () => {
  const { t } = useTranslation();
  const { token } = useQuery<{ token: string }>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const getContent = useMemo(() => {
    if (success) {
      return (
        <>
          <CheckCircle size="10rem" />
          <Title css={{ mt: '$4', textAlign: 'center' }}>
            {t('ConfirmAdminAccount:Thanks for accepting your invitation!')}
          </Title>
          <Text css={{ mt: '$1', textAlign: 'center' }}>
            {t('ConfirmAdminAccount:No further action is required.')}
          </Text>
        </>
      );
    }
    if (error) {
      return (
        <>
          <XCircle size="10rem" />
          <Title css={{ mt: '$4', textAlign: 'center' }}>
            {t('ConfirmAdminAccount:Sorry, looks like your token is invalid.')}
          </Title>
          <Text css={{ mt: '$1', textAlign: 'center' }}>
            {t(
              'ConfirmAdminAccount:Please try again later or contact support.'
            )}
          </Text>
        </>
      );
    }
  }, [success, error, t]);

  useEffectOnce(() => {
    (async () => {
      setLoading(true);
      try {
        await Api.userAcceptInvite(token);
        setSuccess(true);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  });

  if (loading) return <FullScreenLoader />;

  return (
    <PublicLayout>
      <CenteredContentWrapper>
        <FlexCenterColumn>{getContent}</FlexCenterColumn>
      </CenteredContentWrapper>
    </PublicLayout>
  );
};
