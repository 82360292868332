import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import {
  ButtonFeedback,
  Input,
  Text,
  createFeedback,
} from '@resiliantinc/design-system';

import { ScreenLoader } from 'components/atoms/ScreenLoader';

import Api, { processApiError } from 'lib/api';
import config from 'lib/config';
import { IOrganizationDetailsResponse } from 'lib/types';

import { useUser } from 'providers/UserProvider';

import { OrgBrandingFormStyles } from './OrgBrandingForm.styles';

const MAX_LOGO_SIZE = 500000; // 500kb
const OrgBrandingForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { user } = useUser();
  const { organization_id } = user || {};

  const [updatedLogo, setUpdatedLogo] = useState<string | null>(null);

  const { data, mutate } = useSwr<IOrganizationDetailsResponse>(
    Api.getOrganizationDetails(organization_id!)
  );

  const { organization } = data || {};

  const { name, brand_tagline, brand_primary_color, brand_secondary_color } =
    organization || {};

  const defaultValues = useMemo(() => {
    return {
      name,
      brand_tagline,
      brand_primary_color,
      brand_secondary_color,
    };
  }, [name, brand_tagline, brand_primary_color, brand_secondary_color]);

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues,
  });

  const primaryWatched = watch('brand_primary_color');
  const secondaryWatched = watch('brand_secondary_color');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);
        await Api.updateOrganization(organization_id!, data);
        createFeedback({
          variant: 'success',
          heading: t('Organizations:Organization updated successfully'),
        });
        await mutate();
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [organization_id, t, mutate]
  );

  const uploadLogo = useCallback(
    async (e: any) => {
      const file = e.target.files[0];
      if (!file) return;
      if (file.size > MAX_LOGO_SIZE) {
        return createFeedback({
          variant: 'error',
          heading: t('Organizations:Logo size is too large. Max size is 2MB'),
        });
      }
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        await Api.uploadOrganizationLogo(organization_id!, formData);
        createFeedback({
          variant: 'success',
          heading: t('Organizations:Logo uploaded successfully'),
        });
        setUpdatedLogo(URL.createObjectURL(file));
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: messages.join(', '),
        });
      } finally {
        setLoading(false);
      }
    },
    [organization_id, t]
  );

  if (!data) return <ScreenLoader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OrgBrandingFormStyles.Wrapper>
        <OrgBrandingFormStyles.Section>
          <OrgBrandingFormStyles.SectionTitle>
            {t('Organizations:Name and Tagline')}
          </OrgBrandingFormStyles.SectionTitle>
          <Input
            {...register('name')}
            label={t('Organizations:Organization Name')}
            dense
          />
          <Input
            {...register('brand_tagline')}
            label={t('Organizations:Organization Tagline')}
            dense
          />
        </OrgBrandingFormStyles.Section>
        <OrgBrandingFormStyles.Section>
          <OrgBrandingFormStyles.SectionTitle>
            {t('Organizations:Brand Colors')}
          </OrgBrandingFormStyles.SectionTitle>

          <Text>{t('Organizations:Primary Color')}</Text>
          <OrgBrandingFormStyles.ColorInput
            css={{
              '& input': {
                bc: primaryWatched,
              },
            }}
          >
            <Text bold>{primaryWatched}</Text>
            <Input {...register('brand_primary_color')} type="color" />
          </OrgBrandingFormStyles.ColorInput>

          <Text>{t('Organizations:Secondary Color')}</Text>
          <OrgBrandingFormStyles.ColorInput
            css={{
              '& input': {
                bc: secondaryWatched,
              },
            }}
          >
            <Text bold>{secondaryWatched}</Text>

            <Input {...register('brand_secondary_color')} type="color" />
          </OrgBrandingFormStyles.ColorInput>
        </OrgBrandingFormStyles.Section>
        <OrgBrandingFormStyles.Section>
          <OrgBrandingFormStyles.SectionTitle>
            {t('Organizations:Logo Image')}
          </OrgBrandingFormStyles.SectionTitle>
          <Input
            name={'logo'}
            type={'file'}
            onChange={uploadLogo}
            accept="image/png, image/jpeg"
          />
          <OrgBrandingFormStyles.Logo
            src={
              updatedLogo ||
              `${config.API_BASE_URL}/api/organization/${organization_id}/brandimage`
            }
            alt="Org logo"
          />
        </OrgBrandingFormStyles.Section>
      </OrgBrandingFormStyles.Wrapper>
      <ButtonFeedback
        isLoading={loading}
        css={{
          mt: '$8',
        }}
      >
        {t('Organizations:Save Changes')}
      </ButtonFeedback>
    </form>
  );
};

export { OrgBrandingForm };
