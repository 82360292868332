import { motion } from 'framer-motion';

import { styled } from '@resiliantinc/design-system';

export const SelectWrapper = styled('div', {
  position: 'relative',
});
export const OptionsDropdown = styled(motion.div, {
  position: 'absolute',
  top: '2.4rem',
  left: '-1.2rem',
  bc: '#F4F4F4',
  overflow: 'hidden',

  boxShadow: '$sm',
});
export const SelectOption = styled('div', {
  px: '$3',
  py: '$1',
  cursor: 'pointer',
  '&:hover': {
    bc: '$slate50',
  },
});

export const SelectedOption = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  cursor: 'pointer',
});
