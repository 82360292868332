import { Text } from '@resiliantinc/design-system';

export const ChartNoData = () => {
  return (
    <Text
      bold
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      Waiting for data
    </Text>
  );
};
