import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { nanoid } from 'nanoid';
import React, { FC, PropsWithChildren } from 'react';

import { TablePagination } from 'components/molecules/TablePagination';
import {
  DataCell,
  HeaderCell,
  TableBody,
  TableFooter,
  TableHeader,
  TableRow,
  TableStyled,
  TableWrapper,
} from 'components/organisms/Table/Table.styles';

import { ITable } from './Table.interface';
import './Table.styles';

const Table: FC<PropsWithChildren<ITable>> = ({
  columnDefinition,
  data,
  paginator,
  hideFooter,
  rowAction,
}) => {
  const table = useReactTable({
    data,
    columns: columnDefinition,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableWrapper className="p-2">
      <TableStyled>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={nanoid(12)}>
              {headerGroup.headers.map((header) => (
                <HeaderCell key={nanoid(12)}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </HeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={nanoid(12)}>
              {row.getVisibleCells().map((cell, index) => (
                <DataCell
                  key={nanoid(12)}
                  alignRight={row.getVisibleCells().length - 1 === index}
                >
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    rowAction,
                  })}
                </DataCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>
      {!hideFooter && paginator?.hasMorePages && (
        <TableFooter>
          {paginator && <TablePagination {...paginator} />}
        </TableFooter>
      )}
    </TableWrapper>
  );
};

export { Table };
