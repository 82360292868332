import React, { useCallback, useEffect, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import useSwr from 'swr';

import {
  Button,
  Link as DSLink,
  Text,
  Title,
  createFeedback,
} from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { LoadFailed } from 'components/atoms/LoadFailed';
import { ScreenLoader } from 'components/atoms/ScreenLoader';
import { AddRolesModal } from 'components/molecules/AddRolesModal';
import { InlineEdit } from 'components/molecules/InlineEdit';
import { WithConfirmation } from 'components/molecules/WithConfirmation';
import { ActivityTable } from 'components/organisms/ActivityTable';

import Api from 'lib/api';
import { IAdministratorDetailsResponse, IRoleShort } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { AdministratorDetailsStyles } from './AdministratorDetails.styles';

export const AdministratorDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, error, mutate } = useSwr<IAdministratorDetailsResponse>(
    id && Api.getAdministratorDetails(+id)
  );

  const onSaveChanges = useCallback(
    async (data: any) => {
      await Api.updateAdministrator(+id!, data);
      await mutate();
      createFeedback({
        variant: 'success',
        heading: t('Administrators:Administrator updated successfully'),
      });
    },
    [id, mutate, t]
  );

  const onDeleteAdministrator = useCallback(async () => {
    await Api.deleteAdministrator(+id!);
    navigate(routePaths.administrators);
    createFeedback({
      variant: 'success',
      heading: t('Administrators:Administrator deleted successfully'),
    });
  }, [id, navigate, t]);

  const { data: subAdmins } = useSwr(id && Api.getSubAdmins(+id));
  const { data: subUsers } = useSwr(id && Api.getSubUsers(+id));

  const [newRoles, setNewRoles] = useState<IRoleShort[]>([]);

  useEffect(() => {
    if (data) {
      setNewRoles(data?.administratorStats?.roles);
    }
  }, [data]);

  if (error) return <LoadFailed />;
  if (!data) return <ScreenLoader />;

  const { name } = data.administrator;

  const { id: adminId, email } = data.administrator;
  const { groups } = data.administratorStats;

  return (
    <ScreenWrapper
      title={
        <InlineEdit
          initialValue={{ name }}
          onSaveChanges={onSaveChanges}
          InitialComponent={Title}
          initialComponentProps={{ as: 'h3' }}
        />
      }
      includeBreadcrumb
      titleComponent="div"
      action={
        <WithConfirmation
          onConfirm={onDeleteAdministrator}
          modalTitle={t(
            'Administrators:Are you sure you want to delete this administrator?'
          )}
          modalDescription={t('Administrators:This action cannot be undone')}
        >
          <Button variant="nude" danger leftIcon={<Trash />}>
            {t('Administrators:Delete Administrator')}
          </Button>
        </WithConfirmation>
      }
    >
      <AdministratorDetailsStyles.TopLineWrapper>
        <AdministratorDetailsStyles.TopLineItem>
          <AdministratorDetailsStyles.TopLineItemLabel>
            {t('Administrators:Administrator ID:')}
          </AdministratorDetailsStyles.TopLineItemLabel>
          <Text bold>{adminId}</Text>

          <AdministratorDetailsStyles.TopLineItemLabel withTopMargin>
            {t('Administrators:Contact:')}
          </AdministratorDetailsStyles.TopLineItemLabel>
          <DSLink href={`mailto:${email}`}>{email}</DSLink>
        </AdministratorDetailsStyles.TopLineItem>

        <AdministratorDetailsStyles.TopLineItem>
          <AdministratorDetailsStyles.TopLineItemLabel>
            {t('Administrators:Total users:')}
            <Link to={generatePath(routePaths.subUsers, { id })}>
              {subUsers?.paginator?.totalItemCount}
            </Link>
          </AdministratorDetailsStyles.TopLineItemLabel>

          <AdministratorDetailsStyles.TopLineItemLabel withTopMargin>
            {t('Administrators:Total Sub-Administrators:')}
            <Link to={generatePath(routePaths.subAdministrators, { id })}>
              {subAdmins?.paginator?.totalItemCount}
            </Link>
          </AdministratorDetailsStyles.TopLineItemLabel>

          <AdministratorDetailsStyles.TopLineItemLabel withTopMargin>
            {t('Administrators:Groups:')}
            <Link to={routePaths.groups}>{groups?.length}</Link>
          </AdministratorDetailsStyles.TopLineItemLabel>
        </AdministratorDetailsStyles.TopLineItem>

        <AdministratorDetailsStyles.TopLineItem>
          <AdministratorDetailsStyles.TopLineItemLabel>
            {t('Administrators:Roles:')}
          </AdministratorDetailsStyles.TopLineItemLabel>

          <AddRolesModal
            setRoles={setNewRoles}
            roles={newRoles}
            api={{
              mutate,
              adminId: +id!,
            }}
          />
        </AdministratorDetailsStyles.TopLineItem>
      </AdministratorDetailsStyles.TopLineWrapper>

      <ActivityTable
        apiCall={Api.getAdministratorActivity(
          +id!,
          data?.administrator.organization_id!
        )}
      />
    </ScreenWrapper>
  );
};
