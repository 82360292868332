import { AnimatePresence } from 'framer-motion';
import React, { FC, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { IChip } from './Chip.interface';
import { ChipWrapper, DeleteIcon, Tooltip } from './Chip.styles';

const Chip: FC<IChip> = ({ title, link, onDelete, tooltip, onClick }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  return (
    <ChipWrapper
      as={link ? Link : 'div'}
      to={link}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onClick={onClick}
    >
      {title}
      {onDelete && (
        <DeleteIcon onClick={onDelete}>
          <X size="2.4rem" />
        </DeleteIcon>
      )}
      <AnimatePresence>
        {tooltip && isTooltipVisible && (
          <Tooltip
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {tooltip}
          </Tooltip>
        )}
      </AnimatePresence>
    </ChipWrapper>
  );
};

export { Chip };
