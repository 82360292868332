import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, createFeedback } from '@resiliantinc/design-system';

import { Modal } from 'components/atoms/Modal';
import { useUserSelect } from 'components/molecules/UserSearchInput/UserSearchInput.hooks';
import { useUsers } from 'components/organisms/GroupUsersTable';

import Api, { processApiError } from 'lib/api';

import { IAddToGroupModal } from './AddToGroupModal.interface';
import { AddToGroupModalStyles } from './AddToGroupModal.styles';
import './AddToGroupModal.styles';

const AddUserToGroupModal: FC<IAddToGroupModal> = ({
  groupId,
  opened,
  onClose,
}) => {
  const { t } = useTranslation();

  const { mutate } = useUsers(groupId);

  const handleAddUser = useCallback(
    (userId: number) => async () => {
      try {
        await Api.addUserToGroup(groupId, userId);
        await mutate();
        createFeedback({
          variant: 'success',
          heading: t('Users:User added successfully'),
        });
      } catch (e) {
        const messages = processApiError(e);
        createFeedback({
          variant: 'error',
          heading: 'Error: ' + messages.join(', '),
        });
      }
    },
    [groupId, mutate, t]
  );

  const { handleChange, renderAsTable } = useUserSelect(
    Api.searchUsers,
    'users',
    handleAddUser
  );

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      position="centered"
      maxWidth={700}
      title={t('Users: Add user')}
    >
      <AddToGroupModalStyles.Content>
        <Input
          name="user-search"
          type="search"
          onChange={handleChange}
          label={t('Users:Search for a User')}
        />

        {renderAsTable}
      </AddToGroupModalStyles.Content>
    </Modal>
  );
};

export { AddUserToGroupModal };
