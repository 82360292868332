import { Link } from 'react-router-dom';

import { styled } from '@resiliantinc/design-system';

export const BreadcrumbGoBack = styled('div', {
  mr: '$4',
  width: '4rem',
  height: '4rem',
});

export const BreadcrumbItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$slate500',
  '&:after': {
    content: '/',
    display: 'inline-block',
    mx: '$2',
  },
});

export const BreadcrumbItemLink = styled(Link, {
  color: '$sky600',
  textDecoration: 'none',
  maxWidth: 140,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const Breadcrumb = styled('div', {
  display: 'none',
  py: '$4',
  alignItems: 'center',
  width: '100%',
  [`${BreadcrumbItem}:last-child`]: {
    '&:after': {
      display: 'none',
    },
  },
  '@bp2': {
    display: 'flex',
  },
  variants: {
    showOnMobile: {
      false: {
        display: 'none',
      },
      true: {
        display: 'flex',
      },
    },
  },
});
