import React, { ReactNode } from 'react';
import {
  BarChart,
  Building,
  CodeSlash,
  Gear,
  Key,
  People,
  PersonVideo2,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import { routePaths } from 'router/routePaths';

export interface IMainMenuConfigItem {
  text: string;
  expandedText: string;
  icon: ReactNode;
  link?: string;
  onClick?: () => void;
  subItems?: IMainMenuConfigSubItem[];
  requiredPermission?: string;
}

export interface IMainMenuConfigSubItem
  extends Omit<IMainMenuConfigItem, 'icon' | 'text'> {}

export const useMainMenuConfig = (): IMainMenuConfigItem[] => {
  const { t } = useTranslation();

  return [
    {
      text: t('MainMenu:Dashboard'),
      expandedText: t('MainMenu:Dashboard'),
      icon: <BarChart size="2.4rem" />,
      link: routePaths.dashboard,
    },
    {
      text: t('MainMenu:Groups'),
      expandedText: t('MainMenu:Manage Groups'),
      icon: <People size="2.4rem" />,
      link: routePaths.groups,
      subItems: [
        {
          expandedText: t('MainMenu:View Groups'),
          link: routePaths.groups,
        },
        {
          expandedText: t('MainMenu:Create Group'),
          link: routePaths.createGroup,
        },
      ],
    },
    {
      text: t('MainMenu:Admins'),
      expandedText: t('MainMenu:Manage Administrators'),
      icon: <Key size="2.4rem" />,
      link: routePaths.administrators,
      subItems: [
        {
          expandedText: t('MainMenu:View Administrators'),
          link: routePaths.administrators,
        },
        {
          expandedText: t('MainMenu:Create Administrator'),
          link: routePaths.createAdministrator,
          requiredPermission: 'createAdmin',
        },
        {
          expandedText: t('MainMenu:Role Management'),
          link: routePaths.manageRoles,
        },
      ],
    },
    {
      text: t('MainMenu:Users'),
      expandedText: t('MainMenu:Manage Users'),
      icon: <PersonVideo2 size="2.4rem" />,
      link: routePaths.users,
      subItems: [
        {
          expandedText: t('MainMenu:Quick Invite Users'),
          link: routePaths.quickInviteUser,
        },
        {
          expandedText: t('MainMenu:Bulk User Import'),
          link: routePaths.bulkInviteUsers,
        },
        {
          expandedText: t('MainMenu:View All Users'),
          link: routePaths.users,
        },
        {
          expandedText: t('MainMenu:Users Status'),
          link: routePaths.usersStatus,
        },
      ],
    },
    {
      text: t('MainMenu:Organizations'),
      expandedText: t('MainMenu:Organizations'),
      icon: <Building size="2.4rem" />,
      link: routePaths.organizations,
      requiredPermission: 'viewAnyOrganization',
    },
    {
      text: t('MainMenu:Account'),
      expandedText: t('MainMenu:Account'),
      icon: <Gear size="2.4rem" />,
      link: routePaths.account,
      subItems: [
        {
          expandedText: t('MainMenu:Account Settings'),
          link: routePaths.account,
        },
        {
          expandedText: t('MainMenu:Manage Branding'),
          link: routePaths.orgBranding,
        },
        {
          expandedText: t('MainMenu:Organization'),
          link: routePaths.myOrganization,
          requiredPermission: 'viewOrganization',
        },
        {
          expandedText: t('MainMenu:View Plan'),
          link: routePaths.viewPlan,
        },
        {
          expandedText: t('MainMenu:Log Out'),
          link: routePaths.logout,
        },
      ],
    },
    {
      text: t('MainMenu:Developer'),
      expandedText: t('MainMenu:Developer'),
      icon: <CodeSlash size="2.4rem" />,
      link: routePaths.developer,
      subItems: [
        {
          expandedText: t('MainMenu:API Keys'),
          link: routePaths.developer,
        },
        {
          expandedText: t('MainMenu:Webhooks'),
          link: routePaths.webhooks,
        },
      ],
    },
  ];
};
