import { useAtom } from 'jotai';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { UserMultiSelect } from 'components/molecules/UserSearchInput';
import { CreateGroupFormsStyles } from 'components/organisms/CreateGroupForms/CreateGroupForms.styles';
import { useBackToStart } from 'components/organisms/CreateGroupForms/CreateGroupForms.utils';
import { createGroupFormsState } from 'components/organisms/CreateGroupForms/state.atom';

import Api from 'lib/api';

import { routePaths } from 'router/routePaths';

const Admins = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useAtom(createGroupFormsState);
  const [administrators, setAdministrators] = useState(
    formState.administrators
  );
  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  useBackToStart();

  const onSubmit = () => {
    setFormState((prev: any) => ({
      ...prev,
      administrators,
    }));
    navigate(routePaths.createGroupConfirmation);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserMultiSelect
        setUsers={setAdministrators}
        users={administrators}
        apiToUse={Api.searchAdministrators}
        keyToUse="administrators"
      />
      <CreateGroupFormsStyles.NextButton>
        <Button>{t('Groups:Confirm Group')}</Button>
      </CreateGroupFormsStyles.NextButton>
    </form>
  );
};

export { Admins };
