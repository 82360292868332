import axios, { AxiosInstance } from 'axios';

class ApiClient {
  instance: AxiosInstance;
  http: AxiosInstance;
  baseURL: string;
  authToken: string;

  constructor({
    baseURL,
    authToken = 'Authorization',
  }: {
    baseURL: string;
    authToken?: string;
  }) {
    this.baseURL = baseURL;
    this.instance = this.createInstance();
    this.authToken = authToken;
    this.http = this.instance;
  }

  createInstance() {
    this.instance = axios.create({
      baseURL: this.baseURL,
      // withCredentials: true,
    });

    return this.instance;
  }

  async setAccessTokenGetter(getAccessToken: Function) {
    const accessToken = await getAccessToken();
    this.instance.defaults.headers.common[
      this.authToken
    ] = `Bearer ${accessToken}`;
  }

  swrFetch(url: string) {
    return this.http.get(url).then((response) => response.data);
  }
}

export { ApiClient };
