import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { AddAdminToGroupModal } from 'components/organisms/AddToGroupModal/AddAdminToGroupModal';
import { GroupAdministratorsTable } from 'components/organisms/GroupAdministratorsTable';

export const GroupAdministrators = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [adminModalOpen, setAdminModalOpen] = useState(false);
  return (
    <ScreenWrapper
      title={t('Administrators:Group Administrators')}
      includeBreadcrumb
      action={
        <Button onClick={() => setAdminModalOpen(true)}>
          {t('Administrators:Add Administrator')}
        </Button>
      }
    >
      <GroupAdministratorsTable />
      <AddAdminToGroupModal
        groupId={id!}
        opened={adminModalOpen}
        onClose={() => setAdminModalOpen(false)}
      />
    </ScreenWrapper>
  );
};
