import { styled } from '@resiliantinc/design-system';

export const WebhookStyles = {
  InputWithButton: styled('div', {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '$2',
  }),
  Payload: styled('div', {
    mt: '$8',
  }),
  PayloadExample: styled('pre', {
    mt: '$3',
    p: '$4',
    borderRadius: '$md',
    fontFamily: '$mono !important',
    bc: '$slate50',
    width: 'fit-content',
  }),
};
