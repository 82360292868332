import { useTranslation } from 'react-i18next';

import { Box, styled } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { DoughnutChart } from 'components/molecules/DoughnutChart';
import { UserDeletedChart } from 'components/molecules/UserDeletedChart';
import { UserEnrolledChart } from 'components/molecules/UserEnrolledChart';
import { UserLoginsChart } from 'components/molecules/UserLoginsChart';
import { ActivityTable } from 'components/organisms/ActivityTable';
import { StupidlyOvercomplicatedDateSelect } from 'components/organisms/StupidlyOvercomplicatedDateSelect';

import Api from 'lib/api';

import { useUser } from 'providers/UserProvider';

const FlexBox = styled(Box, {
  mt: '$6',
  display: 'flex',
  gap: '$6',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
  },
});
export const Dashboard = () => {
  const { t } = useTranslation();
  const { user, additionalDetails } = useUser();

  const { organization_id } = user || {};
  const { organization_name } = additionalDetails || {};

  return (
    <ScreenWrapper
      title={t('Dashboard:Welcome, {{name}}', { name: user?.name })}
      action={<StupidlyOvercomplicatedDateSelect />}
      overTitle={organization_name}
    >
      <UserLoginsChart />
      <FlexBox>
        <UserEnrolledChart />
        <UserDeletedChart />
      </FlexBox>
      <FlexBox>
        <DoughnutChart
          label={t('Dashboard:Devices')}
          apiType="userDeviceType"
        />
        <DoughnutChart
          label={t('Dashboard:Authorization')}
          apiType="authorizationStatus"
        />
      </FlexBox>
      <FlexBox>
        <DoughnutChart
          label={t('Dashboard:ID Check')}
          apiType="checkIDStatus"
        />
        <DoughnutChart
          label={t('Dashboard:Onboarding')}
          apiType="onboardingStatus"
        />
      </FlexBox>
      <ActivityTable apiCall={Api.getOrganizationActivity(organization_id!)} />
    </ScreenWrapper>
  );
};

/*
<FlexBox>
  <DoughnutChart
      label={t('Dashboard:Failures')}
      labelNumber={75}
  />
  <DoughnutChart
      label={t('Dashboard:Devices')}
      labelNumber={50}
  />
  <DoughnutChart
      label={t('Dashboard:Devices')}
      labelNumber={50}
  />
  <DoughnutChart
      label={t('Dashboard:Devices')}
      labelNumber={50}
  />
</FlexBox>
 */
