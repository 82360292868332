import { createColumnHelper } from '@tanstack/react-table';
import { generatePath } from 'react-router-dom';

import { Chip } from 'components/atoms/Chip';
import { ChipHolder } from 'components/atoms/ChipHolder';

import { formatDate } from 'lib/dates';
import { IInvite } from 'lib/types';

import { routePaths } from 'router/routePaths';

const columnHelper = createColumnHelper<IInvite>();

export const columns = [
  columnHelper.accessor('id', {
    header: 'User ID',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('created_at', {
    header: 'Created At',
    cell: (value) => {
      const date = value.getValue();
      return <div>{formatDate(date, 'MMMM d YYYY hh:mm a')}</div>;
    },
  }),
  columnHelper.accessor('sent', {
    header: 'Status',
    cell: (value) => {
      const sent = value.getValue();
      return <Chip title={sent ? 'Sent' : 'Queued'} />;
    },
  }),
  columnHelper.accessor('groups', {
    header: 'Groups',
    cell: (value) => {
      const groups = value.getValue();
      const chips = groups?.map((group) => (
        <Chip
          title={group.toString()}
          link={generatePath(routePaths.groupDetails, { id: group })}
        />
      ));
      return <ChipHolder>{chips}</ChipHolder>;
    },
  }),
];
