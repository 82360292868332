import { useTranslation } from 'react-i18next';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { BulkInviteUsersForm } from 'components/organisms/BulkInviteUsersForm';

export const BulkInviteUsers = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper title={t('Users:Bulk Invite Users')}>
      <BulkInviteUsersForm />
    </ScreenWrapper>
  );
};
