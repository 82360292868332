import { useTranslation } from 'react-i18next';

import { Box } from '@resiliantinc/design-system';

import { ScreenWrapper } from 'components/Layout/ScreenWrapper';
import { UserStatusSidebar } from 'components/molecules/UserStatusSidebar';
import { UsersStatusTable } from 'components/organisms/UsersStatusTable';

export const UsersStatus = () => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper title={t('Users:Users Status')}>
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 4fr',
        }}
      >
        <UserStatusSidebar />
        <UsersStatusTable />
      </Box>
    </ScreenWrapper>
  );
};
