import { FC } from 'react';
import { Trans } from 'react-i18next';

import { BreadcrumbDynamicGroupName } from 'router/dynamicBreadcrumbs/BreadcrumbDynamicGroupName';
import { routePaths } from 'router/routePaths';

export const breadcrumbs: { [key: string]: FC } = {
  [routePaths.organizationDetails]: () => (
    <Trans i18nKey="Breadcrumb:Organization details" />
  ),
  [routePaths.organizations]: () => (
    <Trans i18nKey="Breadcrumb:Organizations" />
  ),
  [routePaths.orgUsers]: () => (
    <Trans i18nKey="Breadcrumb:Organization Users" />
  ),
  [routePaths.administrators]: () => (
    <Trans i18nKey="Breadcrumb:Administrators" />
  ),
  [routePaths.administratorDetails]: () => (
    <Trans i18nKey="Breadcrumb:Administrator Details" />
  ),
  [routePaths.createAdministrator]: () => (
    <Trans i18nKey="Breadcrumb:Create Administrator" />
  ),
  [routePaths.subAdministrators]: () => (
    <Trans i18nKey="Breadcrumb:Sub-Administrators" />
  ),
  [routePaths.subUsers]: () => <Trans i18nKey="Breadcrumb:Sub-Users" />,
  [routePaths.manageRoles]: () => <Trans i18nKey="Breadcrumb:Manage Roles" />,
  [routePaths.createRole]: () => <Trans i18nKey="Breadcrumb:Create Role" />,
  [routePaths.editRole]: () => <Trans i18nKey="Breadcrumb:Edit Role" />,
  [routePaths.viewRole]: () => <Trans i18nKey="Breadcrumb:View Role" />,
  [routePaths.groups]: () => <Trans i18nKey="Breadcrumb:Groups" />,
  [routePaths.groupDetails]: () => (
    <BreadcrumbDynamicGroupName label="Details" />
  ),
  [routePaths.createGroup]: () => <Trans i18nKey={'Breadcrumb:Create Group'} />,
  [routePaths.groupUsers]: () => <BreadcrumbDynamicGroupName label="Users" />,
  [routePaths.groupAdmins]: () => (
    <BreadcrumbDynamicGroupName label="Administrators" />
  ),
  [routePaths.users]: () => <Trans i18nKey="Breadcrumb:Users" />,
  [routePaths.userDetails]: () => <Trans i18nKey="Breadcrumb:User Details" />,
  [routePaths.quickInviteUser]: () => (
    <Trans i18nKey="Breadcrumb:Quick Invite User" />
  ),
  [routePaths.bulkInviteUsers]: () => (
    <Trans i18nKey="Breadcrumb:Bulk Invite Users" />
  ),
};
