import Skeleton from 'react-loading-skeleton';

import { Box } from '@resiliantinc/design-system';

const ScreenLoader = () => {
  return (
    <Box css={{ py: '$7', px: '$6' }}>
      <Skeleton height={60} />
      <Skeleton height={120} />
      <Skeleton height={120} />
    </Box>
  );
};

export { ScreenLoader };
