import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSwr from 'swr';

import { Input } from '@resiliantinc/design-system';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { Table, TableLoading } from 'components/organisms/Table';

import Api from 'lib/api';
import { useSearchHelper } from 'lib/hooks';
import { administratorsTableParser } from 'lib/parsers';
import { IAdministratorsResponse } from 'lib/types';

import { columns, simpleColumns } from './AdministratorsTable.coldef';

const AdministratorsTable = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { searchTerm, handleSearch } = useSearchHelper();

  const { data: administrators, error } = useSwr<IAdministratorsResponse>(
    searchTerm
      ? Api.searchAdministrators(searchTerm)
      : Api.getAdministrators(page)
  );

  const getTable = useMemo(() => {
    if (error) return <LoadFailed />;
    if (!administrators) return <TableLoading />;
    if (!administrators.administrators.length) return <TableNoData />;
    return searchTerm ? (
      <Table
        columnDefinition={simpleColumns}
        data={administrators.administrators}
      />
    ) : (
      <Table
        columnDefinition={columns}
        data={administratorsTableParser(administrators)}
        paginator={{ ...administrators.paginator, setPage }}
      />
    );
  }, [administrators, error, searchTerm]);

  return (
    <>
      <Input
        type="search"
        placeholder={t('Administrators:Search administrators...')}
        name="admin-search"
        onChange={handleSearch}
        wrapperCss={{
          maxWidth: '400px',
        }}
      />
      {getTable}
    </>
  );
};

export { AdministratorsTable };
