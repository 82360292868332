import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSwr from 'swr';

import { LoadFailed } from 'components/atoms/LoadFailed';
import { TableNoData } from 'components/atoms/TableNoData';
import { columns } from 'components/organisms/GroupAdministratorsTable/GroupAdministratorsTable.coldef';
import { Table, TableLoading } from 'components/organisms/Table';

import Api from 'lib/api';
import { administratorsTableParser } from 'lib/parsers';

import { groupNameAtom } from 'router/dynamicBreadcrumbs/BreadcrumbDynamicGroupName';

export const useAdministrators = (id: string) => {
  const [page, setPage] = useState(1);

  const {
    data: administrators,
    error,
    mutate,
  } = useSwr(id && Api.getGroupAdministrators(+id, page));

  return {
    administrators,
    error,
    setPage,
    mutate,
  };
};

const GroupAdministratorsTable = () => {
  const { id } = useParams();

  const { administrators, error, setPage } = useAdministrators(id!);

  const { data } = useSwr(id && Api.getGroupDetails(+id));

  const [, setGroupName] = useAtom(groupNameAtom);
  useEffect(() => {
    if (data) {
      setGroupName(data.group.name);
    }
  }, [data, setGroupName]);

  if (error) return <LoadFailed />;
  if (!administrators) return <TableLoading />;
  if (!administrators.administrators.length) return <TableNoData />;

  return (
    <Table
      columnDefinition={columns}
      data={administratorsTableParser(administrators)}
      paginator={{ ...administrators.paginator, setPage }}
    />
  );
};

export { GroupAdministratorsTable };
