import { generatePath } from 'react-router-dom';

import { IActivityApiResponse, subjectTypeEnum } from 'lib/types';

import { routePaths } from 'router/routePaths';

export interface IActivityItemParsed {
  id: number;
  date: string;
  summary: string;
  admin: number;
  status: string;
  causer: {
    id: number;
    openUrl: string;
    label: string;
  } | null;
  subject: {
    id: number;
    openUrl: string;
    label: string;
  } | null;
}

export const subjectTypeLabelLiteral = {
  [subjectTypeEnum.organization]: 'View Organization',
  [subjectTypeEnum.admin]: 'View Administrator',
  [subjectTypeEnum.group]: 'View Group',
  [subjectTypeEnum.user]: 'View User',
  [subjectTypeEnum.userCap]: 'View User',
};
export const subjectTypeLabelLiteralNoView = {
  [subjectTypeEnum.organization]: 'Organization',
  [subjectTypeEnum.admin]: 'Administrator',
  [subjectTypeEnum.group]: 'Group',
  [subjectTypeEnum.user]: 'User',
  [subjectTypeEnum.userCap]: 'User',
};

export const subjectLinkLiteral = {
  [subjectTypeEnum.organization]: routePaths.organizationDetails,
  [subjectTypeEnum.admin]: routePaths.administratorDetails,
  [subjectTypeEnum.group]: routePaths.groupDetails,
  [subjectTypeEnum.user]: routePaths.userDetails,
  [subjectTypeEnum.userCap]: routePaths.userDetails,
};

export const activityParser = (
  data: IActivityApiResponse
): IActivityItemParsed[] => {
  return data.activity.map((item) => {
    const {
      id,
      created_at,
      description,
      causer_id,
      causer_type,
      subject_id,
      subject_type,
      properties: { success },
    } = item;

    const subjectTypeClean = subject_type
      ?.replace('App\\', '')
      ?.replace('Models\\', '') as subjectTypeEnum;

    const causerTypeClean = causer_type
      ?.replace('App\\', '')
      ?.replace('Models\\', '') as subjectTypeEnum;

    return {
      id,
      date: created_at,
      summary: description,
      admin: causer_id,
      status: success ? 'Success' : 'Failed',
      causer: causer_id
        ? {
            id: causer_id,
            openUrl: generatePath(subjectLinkLiteral[causerTypeClean], {
              id: causer_id,
            }),
            label: subjectTypeLabelLiteral[causerTypeClean],
          }
        : null,
      subject: subject_id
        ? {
            id: subject_id,
            openUrl: generatePath(subjectLinkLiteral[subjectTypeClean], {
              id: subject_id,
            }),
            label: subjectTypeLabelLiteral[subjectTypeClean],
          }
        : null,
    };
  });
};
